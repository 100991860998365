import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CSVPopup from './CSVPopup';

import "./SearchAdd.css";

import PlusIcon from "../icons/plus-01.svg";
import FilterMenu from "../icons/filter-menu.svg";
import SearchIcon from "../icons/search-02.svg";

const SearchDetailAdd = (props) => {
  const [filterOption, setFilterOption] = useState('');
  const [isActive, setIsActive] = useState(true);
  const fiId = props.data;
  // Handle filter option change
  const handleFilterOptionChange = (event) => {
    setFilterOption(event.target.value);
  };

  // Toggle active/inactive
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform search/filter logic
    // You can use the searchText and filterOption values here
    // For example, make an API call or filter data based on the selected option
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
    
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <form className='FilterFormElement' onSubmit={handleSubmit}>
        <div className='FisearchElement'>
          <img className='icon-all filter-icon-1' src={SearchIcon} alt="search" />
          <input
            className='filterElement'
            type="text"
            placeholder="Search User"
            value={filterOption}
            onChange={handleFilterOptionChange}
          />
          <img className='icon-all filter-icon-2' src={FilterMenu} alt="search" />
        </div>
      </form>

        <Link className='FilterNewBTN' type="submit" onClick={openPopup}>
          <img className='icon-all' src={PlusIcon} alt="plus icon"/> ADD CSV
        </Link>
        {isPopupOpen && <CSVPopup Title1="Add CSV" onClose={closePopup} fi_id = {fiId} />}
    </>
  );
};

export default SearchDetailAdd;
