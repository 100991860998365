import React, { useEffect, useState } from 'react';

import axios from 'axios';

import FiStatus from "../CommonComponents/Status";
import SearchDetailAdd from "../FiManagement/SearchDetailAdd";
import FiDetailTable from "./FiDetailTable";
import Total from "../icons/transformation (3) 1.svg";
import Active from "../icons/interaction 1.svg";
import Inactive from "../icons/access-denied 1.svg";
import Planned from "../icons/internet-banking 1.svg";

const FiDetailComp = (props) => {
    const [filist, setFis] = useState([])
   const url = window.$APIURL;

    useEffect(()=>{
        fetchProducts() 
    },[])
    const fi_id = props.data;

    const fetchProducts = async () => {
        await axios.get('https://digitalxapi.cncitsolutions.in/api/FiManagement/fidetail/'+props.data).then(({data})=>{
            setFis(data);
        })
    }
  const statusData = [
    {
      icon: Total,
      title: "Fintech",
      value: "",
    },
     {
      icon: Inactive,
      title: "Allocated CSV",
      value: "50",
    },
    {
      icon: Active,
      title: "Payment CSV Files",
      value: "20",
    },
   
    {
      icon: Planned,
      title: "Updated CSV Files",
      value: "18",
    },
  ];
  return (
    <div className="mainContainer">
      <div className="mainWrapper">
        <div className="card-container">
       
          <FiStatus statusData={statusData} />
        </div>
        <div className="input-group searchFilterContainer">
          <SearchDetailAdd data={fi_id} />
        </div>
        <div className="searchResultsContainer FiSearchResults">
          <FiDetailTable data={filist}/>
        </div>
      </div>
    </div>
  );
};

export default FiDetailComp;
