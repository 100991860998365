import React from "react";

import "./LoadingPopup.css";

import LoadingSpinner from '../icons/Common/Spinner-1s-200px.svg'

const LoadingPopup = ({ show }) => {
  if (!show) return null;

  return (
    <div className="loading-popup">
      <div className="loading-popup__content">
        <img src={LoadingSpinner} alt="*" />
        <h1>Loading...</h1>
      </div>
    </div>
  );
};

export default LoadingPopup;
