import React from "react";

import SearchContent from "./SearchContent";
import FilterAddContent from "./FilterAddContent";

const SearchFilterAdd = ({
  isCancelButtonVisible,
  setCancelButtonVisible,
  isMoreButtonVisible,
  handleButtonClick,
  setSelectedChannel,
  setSearchKeyword,
  searchKeyword,
}) => {
  return (
    <>
      <SearchContent
        placeholder={"Search User"}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
      />
      <FilterAddContent
        setSelectedChannel={setSelectedChannel}
        isCancelButtonVisible={isCancelButtonVisible}
        setCancelButtonVisible={setCancelButtonVisible}
        isMoreButtonVisible={isMoreButtonVisible}
        handleButtonClick={handleButtonClick}
      />
    </>
  );
};

export default SearchFilterAdd;
