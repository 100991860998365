import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import Swal from "sweetalert2";

import "./ContentTable.css";

import SortIcon from "../icons/ConentManagement/Sort-1.svg";
import Ascending from "../icons/ConentManagement/ascending.svg";
import Descending from "../icons/ConentManagement/descending.svg";
import MoreOptions from "../icons/more-options.svg";

const ContentTable = ({
  selectedChannel,
  setMoreButtonVisible,
  isMoreButtonVisible,
  setCancelButtonVisible,
  //below 2 are the states for the displaying the data data
  contentManagementData,
  setcontentManagementData,
  setShowClientSection,
  setActiveForm,
  setSelectedUserId,
  searchKeyword,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({
    Name: null,
    Product_type: null,
    Channel: null,
    Created_by: null,
    Date_created: null,
  });

  const filteredData = contentManagementData
    .filter((item) =>
      selectedChannel ? item.Channel === selectedChannel : true
    )
    .filter(
      (item) =>
        searchKeyword === "" ||
        item.Name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item.Product_type.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item.Channel.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item.Created_by.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item.Date_created.toLowerCase().includes(searchKeyword.toLowerCase())
    );

  const pageCount = Math.ceil(filteredData.length / perPage);
  const offset = currentPage * perPage;
  const paginatedData = filteredData.slice(offset, offset + perPage);

  const handleSelectAll = () => {
    setMoreButtonVisible(!isMoreButtonVisible);
    setCancelButtonVisible(true);
    setSelectAll(!selectAll);
    setcontentManagementData((prevData) =>
      prevData.map((item) => ({
        ...item,
        selected: !selectAll,
      }))
    );
  };

  const handleSelect = (id) => {
    const newData = contentManagementData.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setcontentManagementData(newData);
    setSelectedRow(id); // Set the selected row
  };

  const handleSort = (field) => {
    let newSortOrder;
    setSortOrder((prevSortOrder) => {
      if (prevSortOrder[field] === "asc") {
        newSortOrder = "desc";
      } else if (prevSortOrder[field] === "desc") {
        newSortOrder = null;
      } else {
        newSortOrder = "asc";
      }

      return {
        ...prevSortOrder,
        [field]: newSortOrder,
      };
    });

    setcontentManagementData((prevData) => {
      const newData = [...prevData];
      const currentSortOrder = sortOrder[field];

      newData.sort((a, b) => {
        if (currentSortOrder === "asc") {
          return a[field].localeCompare(b[field]);
        } else if (currentSortOrder === "desc") {
          return b[field].localeCompare(a[field]);
        } else {
          // original order
          return a.id - b.id;
        }
      });

      return newData;
    });
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const getSortButtonIcon = (field) => {
    const currentSortOrder = sortOrder[field];
    if (currentSortOrder === "asc") {
      return <img src={SortIcon} alt="Sort" />;
    } else if (currentSortOrder === "desc") {
      return <img src={Ascending} alt="Ascending" />;
    } else {
      return <img src={Descending} alt="Descending" />;
    }
  };

  const togglePopup = (id) => {
    setPopupVisible((prevPopupVisible) => !prevPopupVisible);
    setSelectedRow(id);
  };

  const handleEdit = (id, channel) => {
    // alert(`Editing item with ID: ${id}`);
    setSelectedUserId(id);
    setShowClientSection(true);
    // convert channel to lower case string and pass into setActiveForm
    setActiveForm(channel.toLowerCase());
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this item (id: ${id})? This action cannot be undone.`
    );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Send a DELETE request to the delete endpoint
    axios
      .post(`https://digitalxapi.cncitsolutions.in/api/contentManagement/${id}/delete`, {
        isDeleted: "1",
      })
      .then(({ data }) => {
        if (data.status == true) {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
          window.location.reload(false);
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      })
      .catch(({ data }) => {
        if (data.status === 422) {
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      });
  };

  const handleDisable = (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to disable this item (id: ${id})? This action can only be undone by an ADMIN.`
    );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Send a DELETE request to the delete endpoint
    axios
      .post(`https://digitalxapi.cncitsolutions.in/api/contentManagement/${id}/disable`, {
        isDisabled: "1",
      })
      .then(({ data }) => {
        if (data.status == true) {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
          window.location.reload(false);
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      })
      .catch(({ data }) => {
        if (data.status === 422) {
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      });
  };

  const handleClone = (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to clone this item (id: ${id})?`
    );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Fetch the data of the selected row
    axios
      .post(`https://digitalxapi.cncitsolutions.in/api/contentManagement/${id}/clone`)
      .then(({ data }) => {
        if (data) {
          // Create a new row with the same data
          const clonedData = {
            ...data,
            Date_created: new Date(),
            //id: null, // Set id to null or generate a new unique id if needed
            // You may also need to modify other fields if necessary
          };

          // Send a POST request to add the cloned row to the database
          axios
            .get("https://digitalxapi.cncitsolutions.in/api/contentManagement", clonedData)
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire({
                  icon: "success",
                  text: "Row cloned and added successfully.",
                });
                window.location.reload(false);
              } else {
                Swal.fire({
                  icon: "error",
                  text: "Error cloning row 11.",
                });
              }
            })
            .catch(({ data }) => {
              if (data.status == 422) {
              } else {
                Swal.fire({
                  icon: "error",
                  text: "Error cloning row 22.",
                });
              }
            });
        } else {
          Swal.fire({
            icon: "error",
            text: "Selected row not found.",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching row data:", error);
      });
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            <th>
              Name
              <button onClick={() => handleSort("Name")}>
                {getSortButtonIcon("Name")}
              </button>
            </th>
            <th>
              Product Type
              <button onClick={() => handleSort("Product_type")}>
                {getSortButtonIcon("Product_type")}
              </button>
            </th>
            <th>
              Channel
              <button onClick={() => handleSort("Channel")}>
                {getSortButtonIcon("Channel")}
              </button>
            </th>
            <th>
              Created By
              <button onClick={() => handleSort("Created_by")}>
                {getSortButtonIcon("Created_by")}
              </button>
            </th>
            <th>
              Date Created
              <button onClick={() => handleSort("Date_created")}>
                {getSortButtonIcon("Date_created")}
              </button>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((item) => (
            <tr key={item.id}>
              <td>
                <input
                  type="checkbox"
                  checked={item.selected}
                  onChange={() => handleSelect(item.id)}
                />
              </td>
              <td>{item.Name}</td>
              <td>{item.Product_type}</td>
              <td>{item.Channel}</td>
              <td>{item.Created_by}</td>
              <td>{item.Date_created}</td>
              <td>
                <div
                  className="more-icon"
                  style={{ position: "relative" }}
                  onClick={() => togglePopup(item.id)}
                >
                  <img src={MoreOptions} alt="MoreOptions" />
                  {selectedRow === item.id && popupVisible && (
                    <div className="popup-content-1">
                      <ul>
                        <li
                          className="blue"
                          onClick={() => handleEdit(selectedRow, item.Channel)}
                        >
                          Edit
                        </li>
                        <li
                          className="blue"
                          onClick={() => handleClone(selectedRow, item.Channel)}
                        >
                          Clone
                        </li>
                        <li
                          className="red"
                          onClick={() => handleDisable(selectedRow)}
                        >
                          Disable
                        </li>
                        <li
                          className="red"
                          onClick={() => handleDelete(selectedRow)}
                        >
                          Delete
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <ReactPaginate
          previousLabel="&lt;"
          nextLabel="&gt;"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination-container"
          activeClassName="active"
        />
        <div>
          <span>Output by: </span>
          <select
            value={perPage}
            onChange={(e) => setPerPage(parseInt(e.target.value, 10))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>

      {/* <FilterAddContent isMoreButtonVisible={isMoreButtonVisible} /> */}
    </div>
  );
};

export default ContentTable;
