import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import UploadIcon from "../../../../icons/UploadIcon.svg";
import DeleteIcon from "../../../../icons/trash-02.svg";
import "../../ClientSelect.css"; //main css
import "../WASimple.css"; //remaining css 1

const TextBulkComponent = () => {
  const [text, setText] = useState("");
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [sendOption, setSendOption] = useState("sendNow");
  const [removeDuplicates, setRemoveDuplicates] = useState(false);
  const [enableLinkTracking, setEnableLinkTracking] = useState(false);
  const [sendInteractiveButton, setSendInteractiveButton] = useState(false);

  const handleTextChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= maxCharacterCount) {
      setText(inputText);
    } else {
      setText(inputText.slice(0, maxCharacterCount));
    }
  };

  const handleFileChange3 = (event) => {
    const file = event.target.files[0];
    setSelectedFile3(file);
    setSelectedFileName(file ? file.name : "");
  };

  const handleSendOptionChange = (event) => {
    setSendOption(event.target.value);
  };

  const handleRemoveDuplicatesChange = () => {
    setRemoveDuplicates((prevState) => !prevState);
  };

  const handleEnableLinkTrackingChange = () => {
    setEnableLinkTracking((prevState) => !prevState);
  };

  const handleSendInteractiveButtonChange = () => {
    setSendInteractiveButton((prevState) => !prevState);
  };

  const maxCharacterCount = 4100;
  const characterCount = text.length;
  const charactersLeft = maxCharacterCount - characterCount;

  useEffect(() => {
    const storedData = localStorage.getItem("textBulkComponentData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setText(parsedData.text);
      setSelectedFile3(parsedData.selectedFile3);
      setSelectedFileName(parsedData.selectedFileName);
      setSendOption(parsedData.sendOption);
      setRemoveDuplicates(parsedData.removeDuplicates);
      setEnableLinkTracking(parsedData.enableLinkTracking);
      setSendInteractiveButton(parsedData.sendInteractiveButton);
    }
  }, []);
  
  useEffect(() => {
    const dataToStore = {
      text,
      selectedFile3,
      selectedFileName,
      sendOption,
      removeDuplicates,
      enableLinkTracking,
      sendInteractiveButton,
    };
    localStorage.setItem("textBulkComponentData", JSON.stringify(dataToStore));
  }, [
    text,
    selectedFile3,
    selectedFileName,
    sendOption,
    removeDuplicates,
    enableLinkTracking,
    sendInteractiveButton,
  ]);

  return (
    <div className="addClient-bottom">
      <>
        <div className="addClient-field">
          <label>
            Content<span className="star-req">*</span>
          </label>
          <textarea
            onChange={handleTextChange}
            value={text}
            cols="30"
            rows="10"
            placeholder="Click to change Template"
          ></textarea>
          <div className="template-status">
            <label>
              Characters used: {characterCount}, Left: {charactersLeft}
            </label>
            <Link>Use Template</Link>
          </div>
        </div>
        <div className="addClient-field field-row">
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="sendOption5"
              value="sendNow"
              checked={sendOption === "sendNow"}
              onChange={handleSendOptionChange}
            />
            Send Now
          </label>
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="sendOption5"
              value="Schedule"
              checked={sendOption === "Schedule"}
              onChange={handleSendOptionChange}
            />
            Schedule
          </label>
        </div>
        <div className="addClient-field">
          <label>
            Upload List of phone numbers: (Get Sample : <span>MS Excel</span>,
            <span>CSV</span>, <span>Zip</span>, <span>7z </span>
            or <span>Txt</span>)
          </label>
          <div className="field-row">
            <input
              type="file"
              id="file-upload"
              accept=".xlsx, .csv, .zip, .7z, .txt"
              onChange={handleFileChange3}
              style={{ display: "none" }}
            />
            <input
              type="text"
              placeholder=" File name shown here"
              value={selectedFileName}
              readOnly
            />
            <label htmlFor="file-upload" className="file-upload-label">
              <img className="icon-width" src={UploadIcon} alt="^ Upload" />
            </label>
            <img
              className="icon-width delete-btn"
              src={DeleteIcon}
              alt="* Delete"
              onClick={() => {
                setSelectedFile3(null);
                setSelectedFileName("");
              }}
            />
          </div>
          <label>Maximum File Size : 20 MB</label>
        </div>
        <div className="addClient-field ck-box-label">
          <div>
            <input
              type="checkbox"
              name=""
              id="duplicateTxt" 
              value="checkedValue"
              checked={removeDuplicates}
              onChange={handleRemoveDuplicatesChange}
            />
              <label htmlFor="duplicateTxt">
              Remove duplicate entries from browsed file <span>(What's this)</span>
            </label>
          </div>
          <label>
            <span>
              (This will automatically remove duplicate entries from your file
              prior to being uploaded.)
            </span>
          </label>
        </div>
        <div className="addClient-field ck-box-label">
          <div>
            <input
              type="checkbox"
              name=""
              id="trackingTxtB"
              value="checkedValue"
              checked={enableLinkTracking}
              onChange={handleEnableLinkTrackingChange}
            />
            <label htmlFor="trackingTxtB">Enable link tracking</label>
          </div>
          <label>
            <span>
              ( Automatically shortens the long URL in your messages and tracks
              user-wise. Converts only the first long URL found into a short
              URL. <br /> ** Please note that the short URL will consume 28
              characters of the message length)
            </span>
          </label>
        </div>
        <div className="addClient-field ck-box-label">
          <div>
            <input
              type="checkbox"
              name=""
              id="InteractiveTxtB"
              value="checkedValue"
              checked={sendInteractiveButton}
              onChange={handleSendInteractiveButtonChange}
            />
            <label htmlFor="InteractiveTxtB">Send Interactive Button Enabled Template</label>
          </div>
          <label>
            <span>
              ( Enables you to send Interactive button Templates. It should be
              always off for non-button templates)
            </span>
          </label>
        </div>
      </>
    </div>
  );
};

export default TextBulkComponent;
