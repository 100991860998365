import React, { useState } from "react";

import SearchIcon from "../icons/search-02.svg";

import "./SearchContent.css";

const SearchContent = ({ placeholder, setSearchKeyword, searchKeyword }) => {
  // Handle filter option change
  const handleFilterOptionChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  return (
    <div className="search-Element">
      <div className="search-wrapper">
        <img className="search-icon" src={SearchIcon} alt="search" />
        <input
          className="filter-Element"
          type="text"
          placeholder={placeholder}
          value={searchKeyword}
          onChange={handleFilterOptionChange}
        />
      </div>
    </div>
  );
};

export default SearchContent;
