import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";

import "./MainTree.css";

import ContactSelection2 from "./Popup/ContactSelection2";
import AddContactSource from "./Moveables/AddContactSource";

import AlignIcon from "../icons/Campaining/align-justify.svg";
import plusIcon from "../icons/Campaining/plus-01 white.svg";
import editIcon from "../icons/Campaining/edit-02.svg";
import closeIcon from "../icons/Campaining/x-01 white.svg";
import backIcon from "../icons/Campaining/upicon black.svg";

import ApplyBTN from "../icons/Campaining/form 1.svg";
import ApplyBTNHover from "../icons/Campaining/form 1 white.svg";
import CloseBuilderBTN from "../icons/Campaining/x-01.svg";
import CloseBuilderBTNHover from "../icons/Campaining/x-01 white.svg";

import shuffleIcon from "../icons/Campaining/shuffle.svg";
import aimIcon from "../icons/Campaining/aim.svg";
import filterIcon from "../icons/Campaining/filter.svg";

const MainTree = ({
  setShowAddNewCampain,
  handleBuilderClose,

  selectedOption,
  setSelectedOption,

  popupOptions,
  setPopupOptions,

  treeData,
  setTreeData,

  selectedUserId,
  setSelectedUserId,

  fetchProducts,
}) => {
  let level = 0;
  const url = window.$APIURL;

  const [latestData, setLatestData] = useState([]);

  const [applyBtnHover, setApplyBtnHover] = useState(false);
  const [closeBtnHover, setCloseBtnHover] = useState(false);
  const [showSourceTree1, setShowSourceTree1] = useState(false);

  useEffect(() => {
    // Make an API request to the Laravel endpoint
    if (selectedUserId == "") {
      axios
        .post("https://digitalxapi.cncitsolutions.in/api/campaining/latest-id")
        .then((response) => {
          // Set the latest data in your state
          setLatestData(response.data.data.id);
        })
        .catch((error) => {
          alert("Error fetching latest data:", error);
        });
    }
  }, []);

  const navigate = useNavigate();

  const handleCloseBuilderAndRemoveRow = () => {
    if (selectedUserId == "") {
      const confirmCancel = window.confirm(
        `Are you sure you want to close the builder? This action cannot be undone. And also the previously entered data will be deleted. ${latestData}`
      );

      if (!confirmCancel) {
        return; // User canceled the delete action
      }

      axios
        .delete(
          `https://digitalxapi.cncitsolutions.in/api/campaining/builderData/${latestData}/delete`
        )
        .then((response) => {
          if (response.data.status === true) {
            // // Data successfully deleted, you can take further action if needed
            // alert(response.data.message);
            handleBuilderClose();
            setShowSourceTree1(false);
            setShowAddNewCampain(false);
            fetchProducts();
          } else {
            // Handle errors or display a message
            console.log(response.data.message);
          }
        })
        .catch((error) => {
          alert(
            "An error occurred while deleting data from DB contact Developer:",
            error
          );
        });
    } else {
      setSelectedUserId("");
      handleBuilderClose();
      setShowSourceTree1(false);
      setShowAddNewCampain(false);
    }
  };

  const handleAdd = (text) => {
    //function to add SourceTree1 component
    setShowSourceTree1(true);
  };

  // Function to add to item in the treeData array
  const addNewItem = (parentId, newItem) => {
    // Create a deep copy of the current tree data
    const updatedTreeData = JSON.parse(JSON.stringify(treeData));

    // Function to recursively find the parent and add the new item
    function recursiveAddItem(nodes) {
      for (const node of nodes) {
        if (node.id === parentId) {
          if (!node.children) {
            node.children = [];
          }
          node.children.push(newItem);
        } else if (node.children) {
          recursiveAddItem(node.children);
        }
      }
    }

    // Call the recursiveAddItem function to add the new item to the tree
    recursiveAddItem(updatedTreeData);

    // Update the state with the modified tree data
    setTreeData(updatedTreeData);
    console.log(JSON.stringify(updatedTreeData));
  };

  // Function to remove an item from the treeData array
  const removeItem = (itemId) => {
    // Create a deep copy of the current tree data
    const updatedTreeData = JSON.parse(JSON.stringify(treeData));

    // Function to recursively remove an item and its children
    function recursiveRemoveItem(nodes) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        if (node.id === itemId) {
          // Remove the item at the current index
          nodes.splice(i, 1);
          return; // Exit the loop after removal
        } else if (node.children) {
          recursiveRemoveItem(node.children);
        }
      }
    }

    // Call the recursiveRemoveItem function to remove the item and its children
    recursiveRemoveItem(updatedTreeData);

    // Update the state with the modified tree data
    setTreeData(updatedTreeData);
  };

  const handleApplyBtnHover = () => {
    setApplyBtnHover(true);
  };

  const handleApplyBtnLeave = () => {
    setApplyBtnHover(false);
  };

  const handleCloseBtnHover = () => {
    setCloseBtnHover(true);
  };

  const handleCloseBtnLeave = () => {
    setCloseBtnHover(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (selectedUserId !== "") {
      var myform = document.getElementById("builderMainForm10");
      var fd = new FormData(myform);
      // alert(latestData);
      axios
        .post(
          `https://digitalxapi.cncitsolutions.in/api/campaining/builderData10All/${selectedUserId}/update`,
          fd,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              icon: "success",
              text: data.message,
            });

            handleBuilderClose();
            setShowSourceTree1(false);
            setShowAddNewCampain(false);
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    } else {
      var myform = document.getElementById("builderMainForm10");
      var fd = new FormData(myform);
      // alert(latestData);
      axios
        .post(
          `https://digitalxapi.cncitsolutions.in/api/campaining/builderData10All/${latestData}/update`,
          fd,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              icon: "success",
              text: data.message,
            });
            handleBuilderClose();
            setShowSourceTree1(false);
            setShowAddNewCampain(false);
          } else {
               Swal.fire({
              icon: "success",
              text: data.message,
            });
            handleBuilderClose();
            setShowSourceTree1(false);
            setShowAddNewCampain(false);
          }
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    }
  };

  return (
    <>
      <form id="builderMainForm10" onSubmit={onSubmit}>
        <input
          type="hidden"
          name="builderData10All"
          id="builderData10All"
          value={JSON.stringify(treeData)}
          onChange={() => setTreeData(treeData)}
        />
      </form>
      <div className="tree mainContainer dotted-bg">
        <div className="mainWrapper scrollWrapper">
          <div className="buttons-apply-close">
            <button
              type="submit"
              form="builderMainForm10"
              className="apply-btn"
              onMouseEnter={handleApplyBtnHover}
              onMouseLeave={handleApplyBtnLeave}
            >
              <img src={applyBtnHover ? ApplyBTNHover : ApplyBTN} alt="" />{" "}
              Apply
            </button>
            <button
              onClick={handleCloseBuilderAndRemoveRow}
              type="button"
              className="close-btn"
              onMouseEnter={handleCloseBtnHover}
              onMouseLeave={handleCloseBtnLeave}
            >
              <img
                src={closeBtnHover ? CloseBuilderBTNHover : CloseBuilderBTN}
                alt=""
              />
              Close Builder
            </button>
          </div>

          {showSourceTree1 ? (
            <TreeRendering
              treeData={treeData}
              setTreeData={setTreeData}
              data={treeData}
              level={level + 1}
              addNewItem={addNewItem}
              removeItem={removeItem}
            />
          ) : (
            <AddContactSource
              setTreeData={setTreeData}
              popupOptions={popupOptions}
              setPopupOptions={setPopupOptions}
              textOnAdd={handleAdd}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              selectedUserId={selectedUserId}
            />
          )}
        </div>
      </div>
    </>
  );
};

const DecissionBox = ({
  switchContactDecission,
  setSwitchContactDecission,
  decisions,
  setDecisionBoxSelection,
  // decisionBoxTitle,
  // decisionBoxIcon,
  // decisionBoxText,
}) => {
  const handleSource = (decType) => {
    setSwitchContactDecission(!switchContactDecission);
    setDecisionBoxSelection(decType);
  };

  return (
    <>
      <div className="decission-box absolute1">
        <div className="decission-wrapper">
          {decisions.map((decision) => (
            <div key={decision.type} className={`box-small ${decision.type}`}>
              <div className="box-head">
                {decision.title} <img src={decision.icon} alt="" />
              </div>
              <div className="box-body">
                <p>{decision.text}</p>
              </div>
              <div className="box-foot">
                <button onClick={() => handleSource(decision.type)}>
                  Select
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const ContactSourceOptions = ({
  setSwitchContactDecission,
  switchContactDecission,
  contactSourceOptionsData,
  setShowContactSelection1,
  selectedContactSources,
  setSelectedContactSources,
}) => {
  const handleOnSelectOption = (e) => {
    setShowContactSelection1(true);
    setSelectedContactSources(e.value);
  };

  const handleBackToDecission = () => {
    setSwitchContactDecission(!switchContactDecission);
  };

  return (
    <>
      <div className="options-main absolute1">
        <div className="backicon">
          <p>Contact Sources</p>
          <img
            src={backIcon}
            onClick={handleBackToDecission}
            className="backicon1"
            alt="^"
          />
        </div>
        <Select
          value={selectedContactSources.value}
          onChange={handleOnSelectOption}
          className="select-container"
          options={contactSourceOptionsData}
        />
      </div>
    </>
  );
};

const TreeRendering = ({
  data,
  level,
  addNewItem,
  removeItem,
  treeData,
  setTreeData,
}) => {
  const getClassName = (item) => {
    return item.text + item.id + " level-" + level;
  };

  const contactSourceOptionsData = [
    { value: "Contact device", label: "Contact device" },
    { value: "Contact field value", label: "Contact field value" },
    { value: "Contact owner", label: "Contact owner" },
    { value: "Contact segments", label: "Contact segments" },
    { value: "Contact tags", label: "Contact tags" },
    { value: "Form field value", label: "Form field value" },
    { value: "Has valid email address", label: "Has valid email address" },
  ];

  const decisions = [
    {
      type: "decision-1",
      title: "Decision",
      icon: shuffleIcon,
      text: "A decision is made when a contact decides to take action or not (e.g. opened an email).",
    },
    {
      type: "action-1",
      title: "Action",
      icon: aimIcon,
      text: "An action is something executed by Digital X (e.g. send an email).",
    },
    {
      type: "condition-1",
      title: "Condition",
      icon: filterIcon,
      text: "A condition is based on known profile field values or submitted from data.",
    },
  ];

  return (
    <ul>
      {data.map((item) => (
        <li key={item.id} className={"li-children " + getClassName(item)}>
          {level === 1 && (
            <LevelOneNode
              item={item}
              contactSourceOptionsData={contactSourceOptionsData}
              decisions={decisions}
              addNewItem={addNewItem}
              removeItem={removeItem}
            />
          )}
          {level > 1 && (
            <LebelTwoNode
              item={item}
              contactSourceOptionsData={contactSourceOptionsData}
              decisions={decisions}
              addNewItem={addNewItem}
              removeItem={removeItem}
            />
          )}
          {item.children && item.children.length ? (
            <TreeRendering
              treeData={treeData}
              setTreeData={setTreeData}
              data={item.children}
              level={level + 1}
              addNewItem={addNewItem}
              removeItem={removeItem}
            />
          ) : (
            ""
          )}
        </li>
      ))}
    </ul>
  );
};

const LevelOneNode = ({
  item,
  contactSourceOptionsData,
  decisions,
  addNewItem,
  removeItem,
}) => {
  const [switchContactDecission, setSwitchContactDecission] = useState(true);
  const [showDecissionBox, setShowDecissionBox] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  const [decisionBoxSelection, setDecisionBoxSelection] = useState("");

  const [showContactSelection1, setShowContactSelection1] = useState(false);

  const [selectedContactSources, setSelectedContactSources] = useState("");

  const [savedFormData, setSavedFormData] = useState({
    name: "",
    executeEvent: "immediately",
    relativeTime: "",
    specificDateTime: "",
    selectedTags: null,
    selectedRemoveTags: null,
  });

  const handleAddChild = () => {
    // Create a new item to be added
    // const prefix = item.id;
    // const newItemId = `${prefix}.${item.children.length + 1}`;
    const newItemId = Date.now().toString();
    const newItem = {
      id: newItemId,
      decisionBoxSelection: decisionBoxSelection,
      selectedContactSources: selectedContactSources,
      
      formDataName: savedFormData.name,
      formDataExecuteEvent: savedFormData.executeEvent,
      formDataRelativeTime: savedFormData.relativeTime,
      formDataSpecificDateTime: savedFormData.specificDateTime,
      formDataSelectedTags: savedFormData.selectedTags,
      formDataSelectedRemoveTags: savedFormData.selectedRemoveTags,

      text: "New Child 1",
      diamond: false,
      children: [],
    };

    // Call the addNewItem function to add the new item to the specified parent
    addNewItem(item.id, newItem);
  };

  const handleDecissionBox = () => {
    setShowDecissionBox(!showDecissionBox);
  };

  const handleCloseDraggableElement = () => {
    removeItem(item.id);
  };
  return (
    <>
      <div
        className="li-children"
        onMouseLeave={() => setShowIcon(false)}
        onMouseEnter={() => setShowIcon(true)}
        onDoubleClick={handleAddChild}
      >
        <div className="tree-wrapper">
          <button className="blue-round-btn left"> </button>
          <button className="blue-round-btn top-center"> </button>
          <img src={AlignIcon} alt="" /> {`${item.id} ${item.text}`}
          {showIcon ? (
            <>
              <button
                onClick={handleDecissionBox}
                className="blue-round-btn zindex bottom"
              >
                <img src={plusIcon} alt="" />
              </button>
              <div>
                <button className="blue-round-btn dark top-1">
                  <img src={editIcon} alt="" />
                </button>
                <button
                  onClick={handleCloseDraggableElement}
                  className="blue-round-btn red top-2"
                >
                  <img src={closeIcon} alt="" />
                </button>
              </div>
            </>
          ) : (
            <button className="blue-round-btn bottom"></button>
          )}
          <button className="blue-round-btn right"> </button>
        </div>
      </div>
      {showDecissionBox &&
        (switchContactDecission ? (
          <DecissionBox
            switchContactDecission={switchContactDecission}
            setSwitchContactDecission={setSwitchContactDecission}
            decisions={decisions}
            setDecisionBoxSelection={setDecisionBoxSelection}
            // decisionBoxTitle={decisionBoxTitle}
            // decisionBoxIcon={decisionBoxIcon}
            // decisionBoxText={decisionBoxText}
          />
        ) : (
          <ContactSourceOptions
            switchContactDecission={switchContactDecission}
            setSwitchContactDecission={setSwitchContactDecission}
            contactSourceOptionsData={contactSourceOptionsData}
            setShowContactSelection1={setShowContactSelection1}
            selectedContactSources={selectedContactSources}
            setSelectedContactSources={setSelectedContactSources}
          />
        ))}
      {showContactSelection1 ? (
        <ContactSelection2
          setSwitchContactDecission={setSwitchContactDecission}
          setShowDecissionBox={setShowDecissionBox}
          savedFormData={savedFormData}
          setSavedFormData={setSavedFormData}
          handleAddChild={handleAddChild}
          setShowContactSelection1={setShowContactSelection1}
        />
      ) : (
        ""
      )}
    </>
  );
};

const LebelTwoNode = ({
  item,
  contactSourceOptionsData,
  decisions,
  addNewItem,
  removeItem,
}) => {
  const [switchContactDecission, setSwitchContactDecission] = useState(true);
  const [showDecissionBox, setShowDecissionBox] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  const [decisionBoxSelection, setDecisionBoxSelection] = useState("");

  const [showContactSelection1, setShowContactSelection1] = useState(false);

  const [selectedContactSources, setSelectedContactSources] = useState("");

  const [savedFormData, setSavedFormData] = useState({
    name: "",
    executeEvent: "immediately",
    relativeTime: "",
    specificDateTime: "",
    selectedTags: null,
    selectedRemoveTags: null,
  });

  const handleAddChild = () => {
    // Create a new item to be added
    // const prefix = item.id;
    // const newItemId = `${prefix}.${item.children.length + 1}`;
    const newItemId = Date.now().toString();
    const newItem = {
      id: newItemId,
      decisionBoxSelection: decisionBoxSelection,
      selectedContactSources: selectedContactSources,

      formDataName: savedFormData.name,
      formDataExecuteEvent: savedFormData.executeEvent,
      formDataRelativeTime: savedFormData.relativeTime,
      formDataSpecificDateTime: savedFormData.specificDateTime,
      formDataSelectedTags: savedFormData.selectedTags,
      formDataSelectedRemoveTags: savedFormData.selectedRemoveTags,

      text: "New Child 2",
      diamond: false,
      children: [],
    };

    // Call the addNewItem function to add the new item to the specified parent
    addNewItem(item.id, newItem);
  };

  const handleDecissionBox = () => {
    setShowDecissionBox(!showDecissionBox);
  };

  const handleBackToDecission = () => {
    setSwitchContactDecission(!switchContactDecission);
  };

  const handleCloseDraggableElement = () => {
    removeItem(item.id);
  };

  return (
    <>
      <div
        className="li-children"
        onMouseLeave={() => setShowIcon(false)}
        onMouseEnter={() => setShowIcon(true)}
        onDoubleClick={handleAddChild}
      >
        <div className="tree-wrapper">
          <img src={AlignIcon} alt="" /> {`${item.id} ${item.text}`}
          <button className="blue-round-btn top-center"> </button>
          {showIcon && (
            <>
              <button
                type="button"
                onClick={handleDecissionBox}
                className="blue-round-btn zindex bottom-right"
              >
                <img src={plusIcon} alt="" />
              </button>
              <div>
                <button className="blue-round-btn dark top-1">
                  <img src={editIcon} alt="" />
                </button>
                <button
                  onClick={handleCloseDraggableElement}
                  className="blue-round-btn red top-2"
                >
                  <img src={closeIcon} alt="" />
                </button>
              </div>
            </>
          )}
          <button className="blue-round-btn bottom-left"></button>
          <button className="blue-round-btn bottom-right"> </button>
        </div>
        {showDecissionBox &&
          (switchContactDecission ? (
            <DecissionBox
              switchContactDecission={switchContactDecission}
              setSwitchContactDecission={setSwitchContactDecission}
              decisions={decisions}
              setDecisionBoxSelection={setDecisionBoxSelection}
              // decisionBoxTitle={decisionBoxTitle}
              // decisionBoxIcon={decisionBoxIcon}
              // decisionBoxText={decisionBoxText}
            />
          ) : (
            <ContactSourceOptions
              switchContactDecission={switchContactDecission}
              setSwitchContactDecission={setSwitchContactDecission}
              contactSourceOptionsData={contactSourceOptionsData}
              setShowContactSelection1={setShowContactSelection1}
              selectedContactSources={selectedContactSources}
              setSelectedContactSources={setSelectedContactSources}
            />
          ))}
      </div>
      {showContactSelection1 ? (
        <ContactSelection2
          setSwitchContactDecission={setSwitchContactDecission}
          setShowDecissionBox={setShowDecissionBox}
          savedFormData={savedFormData}
          setSavedFormData={setSavedFormData}
          handleAddChild={handleAddChild}
          setShowContactSelection1={setShowContactSelection1}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default MainTree;
