import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../../ClientSelect.css"; //main css
import "../WASimple.css"; //remaining css 1

const TextSimpleComponent = ({
  WASimpleTextNumber,
  WASimpleTextContent,
  
  register,
  errors,
  setValue,
  watch,
  componentClassName,
}) => {
  const handleNumberTextChange = (event) => {
    setValue("WASimpleTextNumber", event.target.value);
  };

  const handleContentTextChange = (event) => {
    setValue("WASimpleTextContent", event.target.value);
  };

  const maxCharacterCount = 4100;
  const contentCharacterCount = WASimpleTextContent.length;
  const contentCharactersLeft = maxCharacterCount - contentCharacterCount;

  return (
    <div className={`addClient-bottom component ${componentClassName}`}>
      <div className="addClient-field">
        <label htmlFor="WASimpleTextNumber">
          Enter Number<span className="star-req">*</span>
        </label>
        <textarea
          className="long-number"
          placeholder=" Enter Numbers separated by commas"
          cols="20"
          rows="10"
          name="WASimpleTextNumber"
          id="WASimpleTextNumber"
          value={WASimpleTextNumber}
          onChange={handleNumberTextChange}
          {...register("WASimpleTextNumber", { required: true })}
        ></textarea>
        {errors.WASimpleTextNumber && (
          <span className="error-message">This field is required.</span>
        )}
      </div>
      <div className="addClient-field">
        <label htmlFor="WASimpleTextContent">
          Content<span className="star-req">*</span>
        </label>
        <textarea
          value={WASimpleTextContent}
          onChange={handleContentTextChange}
          cols="30"
          rows="10"
          name="WASimpleTextContent"
          id="WASimpleTextContent"
          placeholder="Click to change Template"
          {...register("WASimpleTextContent", { required: true })}
        ></textarea>
        <div className="template-status">
          <label>
            Characters used: {contentCharacterCount}, Left:
            {contentCharactersLeft}
          </label>
          <Link>Use Template</Link>
        </div>
        {errors.WASimpleTextContent && (
          <span className="error-message">This field is required.</span>
        )}
      </div>
    </div>
  );
};

export default TextSimpleComponent;
