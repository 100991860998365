import React from 'react'

import UserProfile2 from '../../icons/user-profile-02.svg';
import UserProfileAdd from '../../icons/user-profile-add.svg';
import UserProfileX from '../../icons/user-profile-x.svg';
import FinancialReviewChart from '../../icons/FinancialReviewChart.svg';

import './FinancialReview.css'

const FinancialReview = () => {
  return (
    <>
        <div className="financial-review-main">
            <div className="f-r-left">
                <h4>Financial Review</h4>
                <label> <img src={UserProfile2} alt="p01" /> Overall POS - 180 Cr</label>
                <label> <img src={UserProfileAdd} alt="p02" /> Connected - 161 Cr</label>
                <label> <img src={UserProfileX} alt="p03" /> Not Connected - 19 Cr</label>
            </div>
            <div className="f-r-right">
                <img src={FinancialReviewChart} alt="p04" />
                <label className='userProfile2'><img src={UserProfile2} alt="p01" />3571</label>
            </div>
        </div>
    </>
  )
}

export default FinancialReview