import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../../ClientSelect.css"; //main css
import "../WASimple.css"; //remaining css 1

const LocationSimpleComponent = ({
  numLocationSimpleWA,
  latitudeLocationSimpleWA,
  longitudeLocationSimpleWA,
  nameLocationSimpleWA,
  addrLocationSimpleWA,
  templateLocationSimpleWA,
  register,
  errors,
  setValue,
  watch,
  componentClassName,
}) => {
  const [longitude, setLongitude] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [text, setText] = useState("");

  const handleNumberChange = (event) => {
    setValue("numLocationSimpleWA", event.target.value);
  };

  const handleLatitudeChange = (event) => {
    setValue("latitudeLocationSimpleWA", event.target.value);
  };

  const handleLongitudeChange = (event) => {
    setValue("longitudeLocationSimpleWA", event.target.value);
  };

  const handleNameChange = (event) => {
    setValue("nameLocationSimpleWA", event.target.value);
  };

  const handleAddressChange = (event) => {
    setValue("addressLocationSimpleWA", event.target.value);
  };

  const handleTextChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= maxCharacterCount) {
      setValue("textLocationSimpleWA", inputText);
    } else {
      setValue("textLocationSimpleWA", inputText.slice(0, maxCharacterCount));
    }
  };

  const maxCharacterCount = 4100;
  const characterCount = templateLocationSimpleWA.length;
  const charactersLeft = maxCharacterCount - characterCount;

  return (
    <div className={`addClient-bottom component ${componentClassName}`}>
      <>
        <div className="addClient-field">
          <label>
            Enter Number<span className="star-req">*</span>
          </label>
          <textarea
            className="long-number"
            id="numLocationSimpleWA"
            name="numLocationSimpleWA"
            placeholder=" Enter Numbers separated by commas"
            cols="20"
            rows="10"
            value={numLocationSimpleWA}
            {...register("numLocationSimpleWA", { required: true })}
            onChange={handleNumberChange}
          ></textarea>
          {errors.numLocationSimpleWA && (
            <p className="error-message">Please enter a valid number</p>
          )}
        </div>
        <div className="addClient-field">
          <label className="field-row">
            Enter Geo-location to be sent to user
          </label>
          <div className="lat-long field-row">
            <div className="latitude field-column">
              <label>Latitude</label>
              <input
                id="latitudeLocationSimpleWA"
                name="latitudeLocationSimpleWA"
                type="number"
                placeholder="E.g.19.108649"
                defaultValue={latitudeLocationSimpleWA}
                onChange={handleLatitudeChange}
                {...register("latitudeLocationSimpleWA", { required: true })}
              />
              {errors.latitudeLocationSimpleWA && (
                <p className="error-message">Please enter a valid latitude</p>
              )}
            </div>
            <div className="longitude field-column">
              <label>Longitude</label>
              <input
                id="longitudeLocationSimpleWA"
                name="longitudeLocationSimpleWA"
                type="number"
                {...register("longitudeLocationSimpleWA", { required: true })}
                placeholder="E.g.19.108649"
                value={longitudeLocationSimpleWA}
                onChange={handleLongitudeChange}
              />
              {errors.longitudeLocationSimpleWA && (
                <p className="error-message">Please enter a valid longitude</p>
              )}
            </div>
          </div>
          <div className="name-add field-row">
            <div className="latitude field-column">
              <label>Name</label>
              <input
                id="nameLocationSimpleWA"
                name="nameLocationSimpleWA"
                type="text"
                placeholder="E.g. Gupshup"
                value={nameLocationSimpleWA}
                {...register("nameLocationSimpleWA", { required: true })}
                onChange={handleNameChange}
              />
              {errors.nameLocationSimpleWA && (
                <p className="error-message">Please enter a valid name</p>
              )}
            </div>
            <div className="longitude field-column">
              <label>Address</label>
              <input
                type="text"
                id="addrLocationSimpleWA"
                name="addrLocationSimpleWA"
                placeholder="E.g. 1st Floor, Silver Metropolis, 10"
                {...register("addrLocationSimpleWA", { required: true })}
                value={addrLocationSimpleWA}
                onChange={handleAddressChange}
              />
              {errors.addrLocationSimpleWA && (
                <p className="error-message">Please enter a valid address</p>
              )}
            </div>
          </div>
        </div>
        <div className="addClient-field">
          <label>
            Select a template for caption text to be displayed with location
            <span className="star-req">*</span>
          </label>
          <textarea
            id="templateLocationSimpleWA"
            name="templateLocationSimpleWA"
            onChange={handleTextChange}
            value={templateLocationSimpleWA}
            {...register("templateLocationSimpleWA", { required: true })}
            cols="30"
            rows="10"
            placeholder="Click to change Template"
          ></textarea>
          <div className="template-status">
            <label>
              Characters used: {characterCount}, Left: {charactersLeft}
            </label>
            <Link>Use Template</Link>
          </div>
          {errors.templateLocationSimpleWA && (
            <p className="error-message">Please enter a valid template</p>
          )}
        </div>
      </>
    </div>
  );
};

export default LocationSimpleComponent;
