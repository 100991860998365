import React, { useState, useEffect } from "react";
import { EditorState, convertFromRaw, convertToRaw, RichUtils } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import axios from "axios";
import Swal from "sweetalert2";
// import Select from "react-select";

import UndoIcon from "../icons/UndoIcon.svg";
import RedoIcon from "../icons/RedoIcon.svg";

const DetailsFormNew = ({
  selectedUserId,
  handleOpenBuilder,
  setSelectedOption,
  setPopupOptions,
  treeData,
  setTreeData,
  yesNovalue,
  setYesNoValue,
  setShowAddNewCampain,

  fetchProducts,
  saveBTN,

  saveCloseBTN,
}) => {
  const [newName, setNewName] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [options1, setOptions1] = useState([]);
  const [selectedOption1, setSelectedOption1] = useState("");
  const [newEditorState, setNewEditorState] = useState(
    EditorState.createEmpty()
  );

  const url = window.$APIURL;

  const handleNewNameChange = (event) => {
    setNewName(event.target.value);
  };

  const handleNewCategoryChange = (event) => {
    setNewCategory(event.target.value);
  };

  const handleNewEditorChange = (newEditorState) => {
    setNewEditorState(newEditorState);
  };

  const handleUndo = (e) => {
    e.preventDefault();
    setNewEditorState(EditorState.undo(newEditorState));
  };

  const handleRedo = (e) => {
    e.preventDefault();
    setNewEditorState(EditorState.redo(newEditorState));
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(newEditorState, command);
    if (newState) {
      setNewEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // Check if the editor content is empty or not
    if (newEditorState.getCurrentContent().hasText()) {
      // Submit the form or perform other actions
      console.log("Form submitted");
    } else {
      alert("Please enter some content in the editor.");
      return "";
    }

    if (selectedUserId !== "") {
      var myform = document.getElementById("detailsForm10");
      var fd = new FormData(myform);

      const rawContentState = convertToRaw(newEditorState.getCurrentContent());
      const contentStateAsJSON = JSON.stringify(rawContentState);
      fd.append("descriptionCampaigning", contentStateAsJSON);

      axios
        .post(url + `api/campaining/${selectedUserId}/update`, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          if (data.status == true) {
            if (saveBTN || !saveCloseBTN) {
              Swal.fire({
                icon: "success",
                text: data.message,
              });
            } else if (!saveBTN || saveCloseBTN) {
              Swal.fire({
                icon: "success",
                text: data.message,
              });
              setShowAddNewCampain();
              fetchProducts();
            } else if (!saveBTN && !saveCloseBTN) {
              handleOpenBuilder();
              fetchProducts();
            }
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    } else {
      var myform = document.getElementById("detailsForm10");
      var fd = new FormData(myform);

      const rawContentState = convertToRaw(newEditorState.getCurrentContent());
      const contentStateAsJSON = JSON.stringify(rawContentState);
      // alert(contentStateAsJSON);
      fd.append("descriptionCampaigning", contentStateAsJSON);

      axios
        .post(url + "api/campaining/Form10", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          if (data.status == true) {
            if (saveBTN && !saveCloseBTN) {
              Swal.fire({
                icon: "success",
                text: data.message,
              });
            } else if (!saveBTN && saveCloseBTN) {
              Swal.fire({
                icon: "success",
                text: data.message,
              });
              setShowAddNewCampain();
              fetchProducts();
            } else {
              handleOpenBuilder();
              fetchProducts();
            }
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    }
    fetchProducts();
  };

  const handleContentChange = (selectedOption) => {
    selectedOption == null
      ? setSelectedOption1("")
      : setSelectedOption1(selectedOption.value);
  };

  useEffect(() => {
    fetch("https://digitalxapi.cncitsolutions.in/api/contentManagement/nameNid")
      .then((response) => response.json())
      .then((data) => {
        const formattedOptions = data.map((segment) => ({
          value: segment.id,
          label: segment.name,
        }));
        setOptions1(formattedOptions);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedUserId !== "") {
      axios
        .post(`https://digitalxapi.cncitsolutions.in/api/campaining/${selectedUserId}/edit`)
        .then(({ data }) => {
          setNewName(data.Name || "");
          setNewCategory(data.Category || "");
          setSelectedOption1(data.contactType || "");
          setYesNoValue(data.yesNoValueField || "");

          if (data.Description != null) {
            setNewEditorState(
              EditorState.createWithContent(
                convertFromRaw(JSON.parse(data.Description))
              ) || ""
            );
          }

          setSelectedOption(data.addContactSource || "");
          setPopupOptions(data.contactSourceType1 || "");
          setTreeData(
            JSON.parse(data.builderData10) || [
              {
                id: "1",
                text: data.contactSourceType1,
                diamond: false,
                children: [],
              },
            ]
          );
        });
    } else {
      setNewName("");
      setNewCategory("");
      setNewEditorState(EditorState.createEmpty());
      setSelectedOption("");
      setPopupOptions("");
      setTreeData([
        {
          id: "1",
          text: "",
          diamond: false,
          children: [],
        },
      ]);
    }
  }, [selectedUserId]);

  return (
    <form className="detailsForm" id="detailsForm10" onSubmit={onSubmit}>
      <div className="addClient-field align-req" style={{ display: "none" }}>
        <input
          id="yesNoValueField"
          name="yesNoValueField"
          type="text"
          value={yesNovalue}
        />
      </div>
      <div className="addClient-field align-req">
        <label htmlFor="Name">Name:</label>
        <input
          placeholder="Type the name"
          type="text"
          id="nameCampaigning"
          name="nameCampaigning"
          value={newName}
          onChange={handleNewNameChange}
          required
        />
      </div>
      <div className="addClient-field">
        <label htmlFor="Category">Category:</label>
        <input
          placeholder="Type the categories"
          type="text"
          id="categoryCampaigning"
          name="categoryCampaigning"
          value={newCategory}
          onChange={handleNewCategoryChange}
          required
        />
      </div>
      <div className="addClient-field">
        <label htmlFor="Category">Content Type:</label>
        {/* <Select
          required
          isClearable
          id="contactTypeCampaigning"
          name="contactTypeCampaigning"
          className="select-container"
          options={options1}
          value={selectedOption1} // Set the selected option here
          onChange={handleContentChange}
          placeholder="Choose one..."
        /> */}
        <select
          name="contactTypeCampaigning"
          id="contactTypeCampaigning"
          value={selectedOption1}
          onChange={handleContentChange}
        >
          <option value="" selected disabled>
            Choose one...
          </option>
          {options1.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="addClient-field align-req">
        <label htmlFor="Description">Description:</label>
        <div className="fontStyles font-div-absolute">
          <button onClick={handleUndo}>
            <img src={UndoIcon} alt="Undo" />
          </button>
          <button onClick={handleRedo}>
            <img src={RedoIcon} alt="Redo" />
          </button>
        </div>
        <Editor
          editorState={newEditorState}
          onEditorStateChange={handleNewEditorChange}
          handleKeyCommand={handleKeyCommand}
          id="descriptionCampaigning"
          name="descriptionCampaigning"
          toolbar={{
            options: ["inline"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
          }}
        />
      </div>
    </form>
  );
};

export default DetailsFormNew;
