import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../../ClientSelect.css"; //main css
import "../WASimple.css"; //remaining css 1

import UploadIcon from "../../../../icons/UploadIcon.svg";
import DeleteIcon from "../../../../icons/trash-02.svg";

const ImageSimpleComponent = ({
  numImgSimpleWA,
  simpleImgURL,
  simpleImgTemplate,
  simpleImgFileContent,
  register,
  errors,
  setValue,
  watch,
  componentClassName,
}) => {
  const [sendOption, setSendOption] = useState("link");

  const handleNumberTextChange = (event) => {
    setValue("numImgSimpleWA", event.target.value);
  };

  const handleFileChange = (event) => {
    setValue("simpleImgFileContent", event.target.files[0]);
  };

  const handleURLChanges = (event) => {
    setValue("simpleImgURL", event.target.value);
  };

  const handleTextChange = (event) => {
    setValue("simpleImgTemplate", event.target.value);
  };

  const maxCharacterCount = 4100;
  const contentCharacterCount = simpleImgTemplate.length;
  const contentCharactersLeft = maxCharacterCount - contentCharacterCount;

  return (
    <div className={`addClient-bottom component ${componentClassName}`}>
      <>
        <div className="addClient-field">
          <label>
            Enter Number<span className="star-req">*</span>
          </label>
          <textarea
            className="long-number"
            placeholder=" Enter Numbers separated by commas"
            cols="20"
            rows="10"
            value={numImgSimpleWA}
            name="numImgSimpleWA"
            id="numImgSimpleWA"
            {...register("numImgSimpleWA", { required: true })}
            onChange={handleNumberTextChange}
          ></textarea>
          {errors.numImgSimpleWA && (
            <span className="error-message">Enter Number.</span>
          )}
        </div>
        <div className="addClient-field">
          <label>
            Select Image file to be sent to user
            <span className="star-req">*</span>
          </label>
          <div className="field-row">
            <label className="form-check-label" htmlFor="rdoBTN1ImgsSimpleWA">
              <input
                type="radio"
                className="form-check-input"
                id="rdoBTN1ImgsSimpleWA"
                name="rdoBTN1ImgsSimpleWA"
                value="link"
                defaultChecked
                checked={sendOption === "link"}
                onChange={() => setSendOption("link")}
              />
              Give a link
            </label>
            <label className="form-check-label" htmlFor="rdoBTN2ImgsSimpleWA">
              <input
                type="radio"
                id="rdoBTN2ImgsSimpleWA"
                name="rdoBTN2ImgsSimpleWA"
                className="form-check-input"
                value="upload"
                checked={sendOption === "upload"}
                onChange={() => setSendOption("upload")}
              />
              Upload a file
            </label>
          </div>
        </div>

        {sendOption === "link" && (
          <div className="addClient-field">
            <label>
              Specify a link to an externally hosted Image file. Max size 5 MB
              <span className="star-req">*</span>
            </label>
            <input
              type="text"
              onChange={handleURLChanges}
              value={simpleImgURL}
              name="simpleImgURL"
              id="simpleImgURL"
              {...register("simpleImgURL", { required: true })}
              placeholder="https://www.mydomain.com/img"
            />
            <label className="lighter-note">
              Note: WhatsApp may compress large media files down to 5 MB during
              delivery.
            </label>
            {errors.simpleImgURL && (
              <span className="error-message">Fill URL.</span>
            )}
          </div>
        )}

        {sendOption === "upload" && (
          <div className="addClient-field">
            <label>
              Upload an image file (PNG, JPG only) from your local computer. Max
              size 5 MB
            </label>
            <div className="field-row">
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                id="simpleImgFileContent"
                name="simpleImgFileContent"
                {...register("simpleImgFileContent", { required: true })}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <input
                type="text"
                placeholder=" File name shown here"
                value={simpleImgFileContent ? simpleImgFileContent.name : ""}
                readOnly
              />
              <label
                htmlFor="simpleImgFileContent"
                className="file-upload-label"
              >
                <img className="icon-width" src={UploadIcon} alt="^ Upload" />
              </label>
              {simpleImgFileContent && (
                <img
                  className="icon-width delete-btn"
                  src={DeleteIcon}
                  alt="* Delete"
                  onClick={() => setValue("simpleImgFileContent", null)}
                />
              )}
            </div>
            <label className="lighter-note">
              Note: WhatsApp may compress large media files down to 5 MB during
              delivery.
            </label>
          </div>
        )}

        <div className="addClient-field">
          <label htmlFor="simpleImgTemplate">
            Content<span className="star-req">*</span>
          </label>
          <textarea
            value={simpleImgTemplate}
            onChange={handleTextChange}
            cols="30"
            rows="10"
            name="simpleImgTemplate"
            id="simpleImgTemplate"
            placeholder="Click to change Template"
            {...register("simpleImgTemplate", { required: true })}
          ></textarea>
          <div className="template-status">
            <label>
              Characters used: {contentCharacterCount}, Left:
              {contentCharactersLeft}
            </label>
            <Link>Use Template</Link>
          </div>
          {errors.simpleImgTemplate && (
            <span className="error-message">Enter Template.</span>
          )}
        </div>
      </>
    </div>
  );
};

export default ImageSimpleComponent;
