import React from "react";

const Status2 = ({ segments }) => {
  return (
    <>
      {segments.map((segment, index) => (
        <div className="card" key={index}>
          <img src={segment.imageSrc} className="card-img-top" alt="" />
          <div className="card-body">
            <h5 className="card-title">{segment.title}</h5>
            <p className="card-text">{segment.count}</p>
          </div>
          <img src={segment.moreOptionsSrc} className="card-options-icon" alt="" />
        </div>
      ))}
    </>
  );
};

export default Status2;
