import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MainImage from "../icons/DIGITAL X Login Image.svg";
import SmallMainLogo from "../icons/Small Main Logo.svg";
import SideImage from "../icons/SideImage.svg";

import "./EnterMobile.css";

import $ from "jquery";
import Swal from "sweetalert2";

const EnterMobile = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [remainingTime, setRemainingTime] = useState(10); // 2 minutes in seconds

  useEffect(() => {
    if (step == 2 && remainingTime > 0) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [step, remainingTime]);

  // ... (other code)

  const handleResendOTP = () => {
    // Implement your resend OTP logic here, similar to handleEnterMobileSubmit
    // Reset the timer
    if(remainingTime === 0)
    {
      setRemainingTime(10);
    }
  };

  const handleOTPInput = (inputIndex, e) => {
    const inputLength = e.target.value.length;
    if (inputLength === 1 && inputIndex <= 6) {
      const nextInputIndex = inputIndex + 1;
      const nextInputId = `mobileNumber${nextInputIndex}`;
      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleEnterMobileSubmit = (e) => {
    var phone = $("#mobileNumber").val();
    e.preventDefault();

    $.ajax({
      url: "https://digitalxapi.cncitsolutions.in/api/sendotp",
      type: "POST",
      data: { phone: phone },
      dataType: "JSON",

      success: (response) => {
        //console.log(response);
        if (response.status == true) {
          Swal.fire({
            icon: "success",
            text: response.message,
          });
          localStorage.setItem("phone", phone);
          if (response.data == "otp_verified") {
          setStep(4);

          }else{
          setStep(2);

          }
        } else {
          Swal.fire({
            icon: "error",
            text: response.message,
          });
        }
      },
      error: (response) => {
        //console.log(response);
      },
    });
  };

  const handleOTPSubmit = (e) => {
    e.preventDefault();
    var otp1 = $("#mobileNumber1").val();
    var otp2 = $("#mobileNumber2").val();
    var otp3 = $("#mobileNumber3").val();
    var otp4 = $("#mobileNumber4").val();
    var otp5 = $("#mobileNumber5").val();
    var otp6 = $("#mobileNumber6").val();
    $.ajax({
      url: "https://digitalxapi.cncitsolutions.in/api/verifyotp",
      type: "POST",
      data: {
        otp2: otp2,
        otp1: otp1,
        otp3: otp3,
        otp4: otp4,
        otp5: otp5,
        otp6: otp6,
      },
      dataType: "JSON",

      success: (response) => {
        if (response.status == true) {
          Swal.fire({
            icon: "success",
            text: response.message,
          });
          setStep(3);
        } else {
          Swal.fire({
            icon: "error",
            text: response.message,
          });
        }
      },
      error: (response) => {
        //console.log(response);
      },
    });
  };

  const handleVerifySubmit = (e) => {
    e.preventDefault();
    var pin = $("#pin").val();
    var cpin = $("#cpin").val();
    if (pin != cpin) {
      Swal.fire({
        icon: "error",
        text: "Pin is not match",
      });
    } else {
      $.ajax({
        url: "https://digitalxapi.cncitsolutions.in/api/setpin",
        type: "POST",
        data: { pin: pin, phone: localStorage.getItem("phone") },
        dataType: "JSON",

        success: (response) => {
          if (response.status == true) {
            Swal.fire({
              icon: "success",
              text: response.message,
            });
            //store token in http only cookie
            localStorage.setItem("tokss",JSON.stringify(response.data.plainTextToken)); //only token
            document.cookie = `auth_token=${response.data}; path=/; HttpOnly`;
            navigate("/Dashboard");
          } else {
            Swal.fire({
              icon: "error",
              text: response.message,
            });
            console.log(response.message);
          }
        },
        error: (response) => {
          console.log(response);
        },
      });
    }
  };


    const handlePINSubmit = (e) => {
    e.preventDefault();
    var pin = $("#customer_pin").val();
   
      $.ajax({
        url: "https://digitalxapi.cncitsolutions.in/api/pin_login",
        type: "POST",
        data: { pin: pin, phone: localStorage.getItem("phone") },
        dataType: "JSON",

        success: (response) => {

          if (response.status == true) {
            Swal.fire({
              icon: "success",
              text: response.message,
            });
            //store token in http only cookie
            document.cookie = `auth_token=${response.data}; path=/; HttpOnly`;
            localStorage.setItem("tokss",JSON.stringify(response.data.plainTextToken)); //only token

            navigate("/Dashboard");
          } else {
            Swal.fire({
              icon: "error",
              text: response.message,
            });
            console.log(response.message);
          }
        },
        error: (response) => {
          console.log(response);
        },
      });
  };

  const handleGot = () => {
    navigate("/Dashboard");
  };

  return (
    <div className="enter-mobile-screen">
      <div className="enter-mobile-image">
        <img src={MainImage} alt="" />
        <button onClick={handleGot}>
          <img src={SideImage} alt="" />
        </button>
      </div>
      <div className="enter-mobile-form">
        <div>
          <h2>
            Welcome to digital <img src={SmallMainLogo} alt="" />
          </h2>
          {step === 1 && <p>Please Enter Your Phone No. to access the site</p>}
          {step === 2 && <p>Please enter the OTP sent to your mobile number</p>}
          {step === 3 && (
            <p>Please enter and confirm your PIN to access the site</p>
          )}
        </div>
        {step === 1 && (
          <form onSubmit={handleEnterMobileSubmit}>
            <div className="form-group">
              <label htmlFor="mobileNumber">Mobile No.</label>
              <input
                className="form-control-input"
                type="tel"
                id="mobileNumber"
                name="mobileNumber"
                placeholder="Please Enter your number"
                required
              />
            </div>
            <button type="submit">Send OTP</button>
          </form>
        )}
        {step === 2 && (
          <form onSubmit={handleOTPSubmit}>
            <div className="form-group otp-grp">
              <input
                type="number"
                id="mobileNumber1"
                name="mobileNumber"
                placeholder="-"
                maxLength="1"
                required
                onInput={(e) => handleOTPInput(1, e)}
              />
              <input
                type="number"
                id="mobileNumber2"
                name="mobileNumber"
                placeholder="-"
                maxLength="1"
                required
                onInput={(e) => handleOTPInput(2, e)}
              />
              <input
                type="number"
                id="mobileNumber3"
                name="mobileNumber"
                placeholder="-"
                maxLength="1"
                required
                onInput={(e) => handleOTPInput(3, e)}
              />
              <input
                type="number"
                id="mobileNumber4"
                name="mobileNumber"
                placeholder="-"
                maxLength="1"
                required
                onInput={(e) => handleOTPInput(4, e)}
              />
              <input
                type="number"
                id="mobileNumber5"
                name="mobileNumber"
                placeholder="-"
                maxLength="1"
                required
                onInput={(e) => handleOTPInput(5, e)}
              />
              <input
                type="number"
                id="mobileNumber6"
                name="mobileNumber"
                placeholder="-"
                maxLength="1"
                required
                onInput={(e) => handleOTPInput(6, e)}
              />
            </div>
            <div className="otp-timer">
              <label htmlFor="mobileNumber">
                Time Remaining {Math.floor(remainingTime / 60)}:
                {remainingTime % 60 < 10 ? "0" : ""}
                {remainingTime % 60} min
              </label>
              <label htmlFor="mobileNumber" onClick={handleResendOTP}>
                Resend OTP
              </label>
            </div>
            <button type="submit">Verify</button>
          </form>
        )}
        {step === 4 && (
          <form onSubmit={handlePINSubmit}>
            <div className="form-group">
              <label htmlFor="mobileNumber">Enter PIN.</label>
              <input
                className="form-control-input"
                type="number"
                id="customer_pin"
                name="mobileNumber"
                placeholder="Please enter your PIN Number"
                required
              />
            </div>
            <button type="submit">Verify</button>
          </form>
        )}
        {step === 3 && (
          <form onSubmit={handleVerifySubmit}>
            <div className="form-group">
              <label htmlFor="mobileNumber">Set PIN.</label>
              <input
                className="form-control-input"
                type="number"
                id="pin"
                name="mobileNumber"
                placeholder="Please enter your PIN Number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="mobileNumber">Confirm PIN.</label>
              <input
                className="form-control-input"
                type="number"
                id="cpin"
                name="mobileNumber"
                placeholder="Please re-enter your PIN Number"
                required
              />
            </div>
            <button type="submit">Verify</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default EnterMobile;
