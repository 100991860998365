import React from "react";
import Select from "react-select";

import RangeSlider from "../../CommonComponents/RangeSlider";
import GrowingCirclesSmall from "../../CommonComponents/GrowingCirclesSmall";

import ArrowUp from '../../icons/Dashboard/arrow up.svg'

import "./RangeWiseInteraction.css";

const RangeWiseInteraction = () => {
  const rangeDisbursementOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ];

  return (
    <div className="range-main">
      <div className="range-header">
        <label>Range Wise Interaction</label>
        <div>
          <Select
            options={rangeDisbursementOptions}
            className="select-container"
            placeholder="Disbursement"
          />
          <div className="slider-div">
            Amount
            <RangeSlider />
          </div>
          <Select
            options={rangeDisbursementOptions}
            className="select-container"
            placeholder="Count"
          />
        </div>
      </div>
      <div className="range-wrapper">
        <div className="growing-circle-small">
            <GrowingCirclesSmall percentages={[70, 30, 100]} />
        </div>
        <div className="first-box">
            <div>
                <span className="solid-line"></span>
                <div>
                    Attempt
                    <div className="inner-div">
                        <div>
                            <h4>100%</h4>
                            <p><img src={ArrowUp} alt="^" />+2.45%</p>
                        </div>
                        <span className="dashed-line"></span>
                        <div>
                            <h4>1511 Cr</h4>
                            <p>3500 Records</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <span className="solid-line"></span>
                <div>
                    Attempt
                    <div className="inner-div">
                        <div>
                            <h4>100%</h4>
                            <p><img src={ArrowUp} alt="^" />+2.45%</p>
                        </div>
                        <span className="dashed-line"></span>
                        <div>
                            <h4>1511 Cr</h4>
                            <p>3500 Records</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="first-box" style={{height: '100%'}}>
            <div style={{height: '100%', borderColor:'#FECC5F'}}>
                <span className="solid-line" style={{borderColor:'#FECC5F'}}></span>
                <div>
                    Attempt
                    <div className="inner-div">
                        <div>
                            <h4>100%</h4>
                            <p><img src={ArrowUp} alt="^" />+2.45%</p>
                        </div>
                        <span className="dashed-line"></span>
                        <div>
                            <h4>1511 Cr</h4>
                            <p>3500 Records</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default RangeWiseInteraction;
