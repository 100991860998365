import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import UploadIcon from "../../../../icons/UploadIcon.svg";
import DeleteIcon from "../../../../icons/trash-02.svg";

import "../../ClientSelect.css"; //main css
import "../WASimple.css"; //remaining css 1

const ImageBulkComponent = () => {
  const [text, setText] = useState("");
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [sendOption, setSendOption] = useState("link");
  const [imageUrl, setImageUrl] = useState("");
  const [sendOption4, setSendOption4] = useState("customMedia");
  const [duplicateEntries, setDuplicateEntries] = useState(false);
  const [linkTrackingEnabled, setLinkTrackingEnabled] = useState(false);
  const [interactiveButtonEnabled, setInteractiveButtonEnabled] =
    useState(false);

  const handleTextChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= maxCharacterCount) {
      setText(inputText);
    } else {
      setText(inputText.slice(0, maxCharacterCount));
    }
    localStorage.setItem("imageUrl", inputText);
  };

  const maxCharacterCount = 1024;
  const characterCount = text.length;
  const charactersLeft = maxCharacterCount - characterCount;

  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    setSelectedFile1(file);
    storeFileToLocalStorage("selectedFile1", file);
    storeFileNameToLocalStorage("selectedFile1Name", file.name);
  };

  const handleFileChange2 = (event) => {
    const file = event.target.files[0];
    setSelectedFile2(file);
    storeFileToLocalStorage("selectedFile2", file);
    storeFileNameToLocalStorage("selectedFile2Name", file.name);
  };

  const storeFileToLocalStorage = (key, file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileData = event.target.result;
      localStorage.setItem(key, fileData);
    };
    reader.readAsDataURL(file);
  };

  const storeFileNameToLocalStorage = (key, fileName) => {
    localStorage.setItem(key, fileName);
  };

  const handleDuplicateEntriesChange = (event) => {
    const isChecked = event.target.checked;
    setDuplicateEntries(isChecked);
    localStorage.setItem("duplicateEntries", isChecked.toString());
  };

  const handleLinkTrackingChange = (event) => {
    const isChecked = event.target.checked;
    setLinkTrackingEnabled(isChecked);
    localStorage.setItem("linkTrackingEnabled", isChecked.toString());
  };

  const handleInteractiveButtonChange = (event) => {
    const isChecked = event.target.checked;
    setInteractiveButtonEnabled(isChecked);
    localStorage.setItem("interactiveButtonEnabled", isChecked.toString());
  };

  useEffect(() => {
    const savedFormValues = localStorage.getItem("formValues");
    if (savedFormValues) {
      const {
        text,
        selectedFile1,
        selectedFile2,
        sendOption,
        sendOption4,
        imageUrl,
        duplicateEntries,
        linkTrackingEnabled,
        interactiveButtonEnabled,
      } = JSON.parse(savedFormValues);
      setText(text);
      setSelectedFile1(selectedFile1);
      setSelectedFile2(selectedFile2);
      setSendOption(sendOption);
      setSendOption4(sendOption4);
      setImageUrl(imageUrl);
      setDuplicateEntries(duplicateEntries);
      setLinkTrackingEnabled(linkTrackingEnabled);
      setInteractiveButtonEnabled(interactiveButtonEnabled);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "formValues",
      JSON.stringify({
        text,
        selectedFile1,
        selectedFile2,
        sendOption,
        sendOption4,
        imageUrl,
        duplicateEntries,
        linkTrackingEnabled,
        interactiveButtonEnabled,
      })
    );
  }, [
    text,
    selectedFile1,
    selectedFile2,
    sendOption,
    sendOption4,
    imageUrl,
    duplicateEntries,
    linkTrackingEnabled,
    interactiveButtonEnabled,
  ]);

  return (
    <div className="addClient-bottom">
      <>
        <div className="addClient-field field-row">
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="sendOption4"
              value="commonMedia"
              checked={sendOption4 === "commonMedia"}
              onChange={() => setSendOption4("commonMedia")}
            />
            Common media for all recipients
          </label>
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="sendOption4"
              value="customMedia"
              checked={sendOption4 === "customMedia"}
              onChange={() => setSendOption4("customMedia")}
            />
            Custom media for each recipients
          </label>
        </div>

        <div className="addClient-field">
          <label>
            Select Image file to be sent to user
            <span className="star-req">*</span>
          </label>
          <div className="field-row">
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name="sendOption1"
                value="link"
                checked={sendOption === "link"}
                onChange={() => setSendOption("link")}
              />
              Give a link
            </label>
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name="sendOption1"
                value="upload"
                checked={sendOption === "upload"}
                onChange={() => setSendOption("upload")}
              />
              Upload a file
            </label>
          </div>
        </div>

        {sendOption === "link" && (
          <div className="addClient-field">
            <label>
              Specify a link to an externally hosted Image file. Max size 5 MB
              <span className="star-req">*</span>
            </label>
            <input
              type="text"
              placeholder="https://www.mydomain.com/img"
              value={imageUrl}
              onChange={(event) => setImageUrl(event.target.value)}
            />
            <label className="lighter-note">
              Note: WhatsApp may compress large media files down to 5 MB during
              delivery.
            </label>
          </div>
        )}

        {sendOption === "upload" && (
          <div className="addClient-field">
            <label>
              Upload an image file (PNG, JPG only) from your local computer. Max
              size 5 MB
            </label>
            <div className="field-row">
              <input
                type="file"
                id="file-upload1"
                accept=".png, .jpg, .jpeg"
                onChange={handleFileChange1}
                style={{ display: "none" }}
              />
              <input
                type="text"
                placeholder=" File name shown here"
                value={
                  selectedFile1 ? localStorage.getItem("selectedFile1Name") : ""
                }
                readOnly
              />
              <label htmlFor="file-upload1" className="file-upload-label">
                <img className="icon-width" src={UploadIcon} alt="^ Upload" />
              </label>
              {selectedFile1 && (
                <img
                  className="icon-width delete-btn"
                  src={DeleteIcon}
                  alt="* Delete"
                  onClick={() => setSelectedFile1(null)}
                />
              )}
            </div>
            <label className="lighter-note">
              Note: WhatsApp may compress large media files down to 5 MB during
              delivery.
            </label>
          </div>
        )}

        <div className="addClient-field">
          <label>
            Content<span className="star-req">*</span>
          </label>
          <textarea
            onChange={handleTextChange}
            value={text}
            cols="30"
            rows="10"
            placeholder="Click to change Template"
          ></textarea>
          <div className="template-status">
            <label>
              Characters used: {characterCount}, Left: {charactersLeft}
            </label>
            <Link>Use Template</Link>
          </div>
        </div>
        <div className="addClient-field field-row">
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="sendOption2"
              value="checkedValue"
              defaultChecked
            />
            Send Now
          </label>
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="sendOption2"
              value="checkedValue"
            />
            Schedule
          </label>
        </div>
        <div className="addClient-field">
          <label>
            Upload List of phone numbers: (Get Sample : <span>MS Excel</span>,
            <span>CSV</span>, <span>Zip</span>, <span>7z </span>
            or <span>Txt</span>)
          </label>
          <div className="field-row">
            <input
              type="file"
              id="file-upload2"
              accept=".xlsx, .csv, .zip, .7z, .txt"
              onChange={handleFileChange2}
              style={{ display: "none" }}
            />
            <input
              type="text"
              placeholder=" File name shown here"
              value={
                selectedFile2 ? localStorage.getItem("selectedFile2Name") : ""
              }
              readOnly
            />
            <label htmlFor="file-upload2" className="file-upload-label">
              <img className="icon-width" src={UploadIcon} alt="^ Upload" />
            </label>
            <img
              className="icon-width delete-btn"
              src={DeleteIcon}
              alt="* Delete"
              onClick={() => setSelectedFile2(null)}
            />
          </div>
          <label>Maximum File Size : 20 MB</label>
        </div>

        <div className="addClient-field ck-box-label">
          <div>
            <input
              type="checkbox"
              id="duplicateImg"
              value="checkedValue"
              checked={duplicateEntries}
              onChange={handleDuplicateEntriesChange}
            />
            <label htmlFor="duplicateImg">
              Remove duplicate entries from browsed file
              <span>(What's this)</span>
            </label>
          </div>
          <label>
            <span>
              (This will automatically remove duplicate entries from your file
              prior to being uploaded.)
            </span>
          </label>
        </div>
        <div className="addClient-field ck-box-label">
          <div>
            <input
              type="checkbox"
              id="trackingImgB"
              value="checkedValue"
              checked={linkTrackingEnabled}
              onChange={handleLinkTrackingChange}
            />
            <label htmlFor="trackingImgB">Enable link tracking</label>
          </div>
          <label>
            <span>
              ( Automatically shortens the long URL in your messages and tracks
              user-wise. Converts only the first long URL found into a short
              URL. <br /> ** Please note that the short URL will consume 28
              characters of the message length)
            </span>
          </label>
        </div>
        <div className="addClient-field ck-box-label">
          <div>
            <input
              type="checkbox"
              id="InteractiveImgB"
              value="checkedValue"
              checked={interactiveButtonEnabled}
              onChange={handleInteractiveButtonChange}
            />
            <label htmlFor="InteractiveImgB">
              Send Interactive Button Enabled Template
            </label>
          </div>
          <label>
            <span>
              ( Enables you to send Interactive button Templates. It should be
              always off for non-button templates)
            </span>
          </label>
        </div>
      </>
    </div>
  );
};

export default ImageBulkComponent;
