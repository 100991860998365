import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import "./UserPopup.css";
import Swal from "sweetalert2";

import CloseIcon from "../icons/X-02.svg";
import UploadIcon from "../icons/UploadIcon.svg";
import DeleteIcon from "../icons/trash-02.svg";

const UserPopup = ({ onClose, Title1, selectedUserId, fetchProducts }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const [selectedFile, setSelectedFile] = useState(null);

  const [filist, setFis] = useState([]);

  const namePopupForm = watch("namePopupForm", "");
  const emailPopupForm = watch("emailPopupForm", "");
  const phonePopupForm = watch("phonePopupForm", "");
  const FISelectionPopupForm = watch("FISelectionPopupForm", "");
  const employeeCodePopupForm = watch("employeeCodePopupForm", "");
  const usertypePopupForm = watch("usertypePopupForm", "");
  const reportingPopupForm = watch("reportingPopupForm", "");
  const addressPopupForm = watch("addressPopupForm", "");
  const descriptionPopupForm = watch("descriptionPopupForm", "");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await axios
      .get("https://digitalxapi.cncitsolutions.in/api/FiUniqueCategory")
      .then(({ data }) => {
        setFis(data);
      });
  };

  const url = window.$APIURL;

  const onSubmit = (formdata) => {
    // alert(data);
    //console.log(formdata);
    if (selectedUserId !== "") {
      var myform = document.getElementById("adduserForm");
      var fd = new FormData(myform);
      var imagefile = document.querySelector("#profile11");
      fd.append("profile11", imagefile.files[0]);

      axios
        .post(url + `api/users/${selectedUserId}/update`, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          if (data.status === true) {
            Swal.fire({
              icon: "success",
              text: data.message,
            });
            onClose();
            fetchProducts();
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              text: data.message,
              icon: "error",
            });
          }
        });
    } else {
      var myform = document.getElementById("adduserForm");
      var fd = new FormData(myform);
      var imagefile = document.querySelector("#profile11");
      fd.append("profile11", imagefile.files[0]);

      axios
        .post(url + "api/user/add", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          if (data.status === true) {
            Swal.fire({
              icon: "success",
              text: data.message,
            });
            onClose();
            fetchProducts();
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              text: data.message,
              icon: "error",
            });
          }
        });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // console.log("Selected File:", file.name);
  };

  useEffect(() => {
    if (selectedUserId !== "") {
      axios
        .post(`https://digitalxapi.cncitsolutions.in/api/users/${selectedUserId}/edit`)
        .then(({ data }) => {
          setValue("namePopupForm", data.name || "");
          setSelectedFile(data.profile || "");
          setValue("fileName", data.profile);
          setValue("email", data.email || "");
          setValue("phone", data.phone || "");
          setValue("fi_sel", data.fi_sel || "");
          setValue("emp_code", data.emp_code || "");
          setValue("usertype", data.usertype || "");
          setValue("reporting", data.reporting || "");
          setValue("address", data.address || "");
          setValue("description", data.description || "");
        });
    }
  }, [selectedUserId]);

  return (
    <div className="popup">
      <div className="popup-content">
        <div className="popup-header">
          <img
            onClick={onClose}
            className="close-icon"
            src={CloseIcon}
            alt="x"
          />
          <h2>{Title1}</h2>
        </div>
        <form id="adduserForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="popup-body">
            <div className="left-body">
              <div className="body-element">
                <label>Name</label>
                <input
                  type="text"
                  value={namePopupForm}
                  {...register("namePopupForm", {
                    required: "This Field is empty",
                  })}
                />
                {errors.namePopupForm && (
                  <span className="error-message">
                    {errors.namePopupForm.message}
                  </span>
                )}
              </div>
              <div className="body-element">
                <label>Upload Photo</label>
                <div className="">
                  <input
                    type="file"
                    name="profile11"
                    id="profile11"
                    // id="file-upload5"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    // {...register("profile11")}
                  />
                  <input
                    type="text"
                    placeholder=" File name shown here"
                    value={selectedFile ? selectedFile.name : ""}
                    {...register("fileName")}
                    readOnly
                  />
                  <label htmlFor="profile11" className="file-upload-label">
                    <img
                      className="icon-width margin-no"
                      src={UploadIcon}
                      alt="^ Upload"
                    />
                  </label>
                  {selectedFile && (
                    <img
                      className="icon-width delete-btn margin-no"
                      src={DeleteIcon}
                      alt="* Delete"
                      onClick={() => setSelectedFile(null)}
                    />
                  )}
                  {errors.profile11 && (
                    <span className="error-message">Upload Image</span>
                  )}
                </div>
              </div>
              <div className="body-element">
                <label>Email</label>
                <input
                  type="email"
                  // defaultValue={emailPopupForm}
                  name="email"
                  id="email"
                  {...register("email", { required: "Enter your mail" })}
                />
                {errors.email && (
                  <span className="error-message">{errors.email.message}</span>
                )}
              </div>
              <div className="body-element">
                <label>Contact Number</label>
                {/* +00 extention input field*/}
                <div>
                  <select {...register("phone_ext", { required: "+00" })}>
                    <option value="+91">+91</option>
                    <option value="+11">+11</option>
                  </select>
                  {errors.extention && (
                    <span className="error-message">
                      {errors.extention.message}
                    </span>
                  )}
                  <input
                    type="tel"
                    {...register("phone", { required: "Enter Your Number" })}
                  />
                  {errors.phone && (
                    <span className="error-message">
                      {errors.phone.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="body-element">
                <label>FI Selection</label>
                {/* <input type="text" {...register("fi_sel")} /> */}
                <select name="" id="" {...register("fi_sel")}>
                  <option selected value="">Choose FI...</option>
                  {filist.map((fiCategory, index) => (
                    <option value={index}>{fiCategory.toUpperCase()}</option>
                  ))}
                </select>
              </div>
              <div className="body-element">
                <label>Employee Code</label>
                <input
                  type="text"
                  {...register("emp_code", { required: "Enter the code" })}
                />
                {errors.emp_code && (
                  <span className="error-message">
                    {errors.emp_code.message}
                  </span>
                )}
              </div>
            </div>
            <div className="right-body">
              <div className="body-element">
                <label>Role</label>
                <select
                  {...register("usertype", { required: "Enter your Role" })}
                >
                  <option selected value="">Choose Role...</option>
                  <option value="SUPERVISOR">Supervisior</option>
                  <option value="MANAGER">Manager</option>
                  <option value="EXECUTIVE">Executive</option>
                </select>
                {errors.role && (
                  <span className="error-message">{errors.role.message}</span>
                )}
              </div>
              <div className="body-element">
                <label>Reporting</label>
                <select
                  {...register("reporting", { required: "Enter the Person" })}
                >
                  <option selected value="">Choose Reporting...</option>
                  <option value="MANAGER">Manager</option>
                  <option value="SUPERVISOR">Supervisor</option>
                  <option value="TEAMLEAD">Team Lead</option>
                </select>
                {errors.reporting && (
                  <span className="error-message">
                    {errors.reporting.message}
                  </span>
                )}
              </div>
              <div className="body-element">
                <label>Address</label>
                <textarea
                  placeholder="Type Something"
                  {...register("address")}
                />
              </div>
              <div className="body-element">
                <label>Description</label>
                <textarea
                  placeholder="Type Something"
                  {...register("description")}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="popup-footer">
            <button type="button" className="cancel-button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="save-button">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserPopup;
