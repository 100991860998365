import React from "react";
import PropTypes from "prop-types";

import "./ContnetStatus.css";

const ContnetStatus = ({ items }) => {
  return (
    <div className="car-wrapper">
      {items.map((item, index) => (
        <div
          className={`card-content ${item.bgColor}`}
          key={index}
        >
          <div className="content-left">
            <label>{item.text}</label>
            <img src={item.icon} alt={item.text} />
          </div>
          <span className="middle-line"></span>
          <div className="content-right">
            <label></label>
            <label className={item.textColor}>{item.value}</label>
          </div>
        </div>
      ))}
    </div>
  );
};

ContnetStatus.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      bgColor: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ContnetStatus;
