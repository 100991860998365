import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";

import Save from "../../icons/ConentManagement/save-02.svg";
import Cancel from "../../icons/ConentManagement/x-01.svg";

import "./ClientSelect.css";

const SMSForm = ({ handleCancel, clientFormData, selectedUserId }) => {
  const {
    clientName,
    productType,
    channel,
    contentName,
    contentDescription,
    setClientName,
    setProductType,
    setChannel,
    setContentName,
    setContentDescription,
  } = clientFormData;

  const [inputs, setInputs] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  useEffect(() => {
    if (selectedUserId !== "") {
      fetchProducts();
      
    }
  }, [selectedUserId]);

  const fetchProducts = async () => {
    await axios
      .post(
        `https://digitalxapi.cncitsolutions.in/api/contentManagement/${selectedUserId}/edit`
      )
      .then(({ data }) => {
        setInputs({
          SenderId: data.SenderID || "",
          templateName: data.TemplateName || "",
          templateId: data.TemplateID || "",
          SMScontentDescription: data.ContentDescription || "",
          telemarketerId: data.telemarketerId || "",
        });
        setClientName(data.Name || "");
        setProductType(data.Product_type || "");
        setContentName(data.Created_by || "");
        setChannel(data.Channel || "");
        setContentDescription(data.ContentDescription || "");
      });
  };

  const url = window.$APIURL;

  const onSubmit = (formdata) => {
    if (selectedUserId !== "") {
      var myform = document.getElementById("smsForm");
      var fd = new FormData(myform);
      axios
        .post(
          url + `api/contentManagement/SMS/${selectedUserId}/update`, fd, 
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              icon: "success",
              text: data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          handleCancel(); //commnent if you dont need to close form after submitting.
          setChannel("");
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    } else {
      console.log(formdata);
      var myform = document.getElementById("smsForm");
      var fd = new FormData(myform);

      axios
        .post(url + "api/contentManagement/SMS", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              icon: "success",
              text: data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          handleCancel(); //commnent if you dont need to close form after submitting.
          setChannel("");
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    }
  };

  return (
    <div className="addClient-container">
      <div className="addClient-wrapper option-client-wrapper">
        <div className="addClient-top">
          <label>SMS</label>
          <div className="client-btns">
            <button
              className="client-btn save-close-btn"
              type="submit"
              form="smsForm"
            >
              <img src={Save} alt="" /> Save & Close
            </button>
            <button className="client-btn cancel-btn" onClick={handleCancel}>
              <img src={Cancel} alt="" /> Cancel
            </button>
          </div>
        </div>
        <div className="addClient-bottom">
          <form id="smsForm" onSubmit={handleSubmit(onSubmit)}>
            <div style={{ display: "none" }}>
              <input type="text" name="clientNameFULL1" onChange={()=>setClientName(clientName)} value={clientName} />
              <input type="text" name="productTypeFULL1" onChange={()=>setProductType(productType)} value={productType} />
              <input type="text" name="channelFULL1" onChange={()=>setChannel(channel)} value={channel} />
              <input type="text" name="contentNameFULL1" onChange={()=>setContentName(contentName)} value={contentName} />
              <input type="text" name="contentDescriptionFULL1" onChange={()=>setContentDescription(contentDescription)} value={contentDescription} />
            </div>
            <div className="addClient-field">
              <label htmlFor="SenderId">
                Sender ID<span className="star-req">*</span>
              </label>
              <select
                {...register("SenderId", { required: true })}
                id="SenderId"
                name="SenderId"
                onChange={handleChange}
                value={inputs.SenderId}
              >
                <option value="">Select Sender ID</option>
                <option value="CCEAMB">CCEAMB</option>
                <option value="NWORLD">NWORLD</option>
              </select>
              {errors.SenderId && (
                <span className="error-message">Sender ID is required.</span>
              )}
            </div>
            <div className="addClient-field">
              <label htmlFor="templateName">
                Template Name<span className="star-req">*</span>
              </label>
              <input
                type="text"
                {...register("templateName", { required: true })}
                id="templateName"
                name="templateName"
                defaultValue={inputs.templateName}
                onChange={handleChange}
              />
              {errors.templateName && (
                <span className="error-message">
                  Template Name is required.
                </span>
              )}
            </div>
            <div className="addClient-field">
              <label htmlFor="templateId">
                Template Id<span className="star-req">*</span>
              </label>
              <input
                type="text"
                {...register("templateId", { required: true })}
                id="templateId"
                name="templateId"
                defaultValue={inputs.templateId}
                onChange={handleChange}
              />
              {errors.templateId && (
                <span className="error-message">Template Id is required.</span>
              )}
            </div>
            <div className="addClient-field">
              <label htmlFor="SMScontentDescription">
                SMS Content<span className="star-req">*</span>
              </label>
              <textarea
                placeholder="Content description"
                rows="7"
                {...register("SMScontentDescription", { required: true })}
                id="SMScontentDescription"
                name="SMScontentDescription"
                onChange={handleChange}
                defaultValue={inputs.SMScontentDescription}
              ></textarea>
              {errors.SMScontentDescription && (
                <span className="error-message">SMS Content is required.</span>
              )}
            </div>
            <div className="addClient-field">
              <label>0 Characters Used</label>
            </div>
            <div className="addClient-field">
              <label htmlFor="telemarketerId">
                Telemarketer Id<span className="star-req">*</span>
              </label>
              <input
                type="text"
                {...register("telemarketerId", { required: true })}
                id="telemarketerId"
                name="telemarketerId"
                defaultValue={inputs.telemarketerId}
                onChange={handleChange}
              />
              {errors.telemarketerId && (
                <span className="error-message">
                  Telemarketer Id is required.
                </span>
              )}
            </div>
            <div className="addClient-field">
              <label>Template format needs to be approved as per DLT.</label>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SMSForm;
