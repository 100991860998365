import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";

import { EditorState, RichUtils, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import DisplayFilterDetails from "./DisplayFilterDetails";

import SaveClose from "../icons/save-02.svg";
import CancelClose from "../icons/x-01.svg";

import "./AddNew.css";

import DetailsFilterComponent from "./DetailsFilterComponent";

const YesNoButtons = ({ label }) => {
  const [value, setValue] = useState("yes");

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="yesNoButtons">
    <h4>{label}</h4>
    <div>
    <label className={`radio-button ${value === "yes" ? "active" : ""}`}>
    <input
    style={{ display: "none" }}
    type="radio"
    value="yes"
    checked={value === "yes"}
    onChange={handleRadioChange}
    />
    Yes
    </label>
    <label className={`radio-button ${value === "no" ? "active" : ""}`}>
    <input
    style={{ display: "none" }}
    type="radio"
    value="no"
    checked={value === "no"}
    onChange={handleRadioChange}
    />
    No
    </label>
    </div>
    </div>
    );
  };

  const AddNew = ({ handleCloseAddNew, selectedUserId }) => {
    const url = window.$APIURL;

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const {
      register,
      handleSubmit,
      setValue,
      watch,
      formState: { errors, dirtyFields },
    } = useForm();

    const segmentDetailsName = watch("segmentDetailsName", "");
    const segmentDetailsCategory = watch("segmentDetailsCategory", "");

    const [segmentTitle, setSegmentTitle] = useState("");

    const handleTitleChange = (event) => {
      setSegmentTitle(event.target.value);
    };

    const handleApply = (formdata, e) => {
      e.preventDefault();

      if (selectedUserId !== "") {
      } else {
        alert("Apply button clicked: " + JSON.stringify(formdata));
        console.log("Segment Form data:", formdata);
        var myform = document.getElementById("SegmentationDetailsFilterForm");
        var fd = new FormData(myform);

      // Convert editor state to JSON and append to form data
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const contentStateAsJSON = JSON.stringify(rawContentState);
        fd.append("segmentDescriptionEditor", contentStateAsJSON);

        axios
        .post(url + "api/segmentation/DetailsAndFilter", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          console.log(data.status);
          if (data.status == true) {
            Swal.fire({
              icon: "success",
              text: data.message,
            });
            localStorage.removeItem("segmentationDetailsForm");
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          handleCloseAddNew(); //commnent if you don't need to close form after submitting.
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status == false) {
            Swal.fire({
              icon: "error",
              text: data.message,
            });          
          }else {
              Swal.fire({
                icon: "error",
                text: data.message,
              });
            }
          });
      }
    };

    return (
      <>
      <form
      className="main-segment2"
      id="SegmentationDetailsFilterForm"
      onSubmit={handleSubmit(handleApply)}
      >
      <div className="top-search-buttons">
      <input
      className="newTitle"
      type="text"
      placeholder="New Segment"
      disabled
      value={segmentTitle}
      onChange={handleTitleChange}
      />
      <button
      className="functionBTN"
      type="submit"
      form="SegmentationDetailsFilterForm"
      >
      <img className="icon-all" src={SaveClose} alt="saveclose" /> Save &
      Close
      </button>
      <button onClick={handleCloseAddNew} className="functionBTN closeBTN">
      <img className="icon-all" src={CancelClose} alt="" /> Close
      </button>
      </div>
      <div className="segment2-body">
      <div className="segment2-body-left">
      <DetailsFilterComponent
      segmentDetailsName={segmentDetailsName}
      segmentDetailsCategory={segmentDetailsCategory}
      editorState={editorState}
      setEditorState={setEditorState}
      register={register}
      errors={errors}
      watch={watch}
      setValue={setValue}
      dirtyFields={dirtyFields}
      />
      </div>
      <div className="segment2-body-right">
      <div className="yes-no-wrapper">
      <YesNoButtons label="Published" />
      </div>
      </div>
      </div>
      </form>
      </>
      );
    };

    export default AddNew;
