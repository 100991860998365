import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import CloseIcon from "../icons/X-02.svg";
import UploadIcon from "../icons/UploadIcon.svg";
import DeleteIcon from "../icons/trash-02.svg";

import "./FiPopup.css";

const FiPopup = ({ onClose, Title1, fetchProducts, selectedUserId }) => {
  const url = window.$APIURL;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const [selectedFile, setSelectedFile] = useState(null);
  const [filist, setFis] = useState([]);

  const finame = watch("finame", "");
  const fiDetailsCategory = watch("fiDetailsCategory", "");
  const description = watch("description", "");
  const poc_name = watch("poc_name", "");
  const poc_email = watch("poc_email", "");
  const poc_phone_ext = watch("poc_phone_ext", "");

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedUserId !== "") {
      axios.post(url + `api/users/${selectedUserId}/edit`).then(({ data }) => {
        setValue("finame", data.finame || "");
        setSelectedFile(data.profile || "");
        setValue("fileName", data.profile);
        setValue("fiDetailsCategory", data.category || "");
        setValue("description", data.description || "");
        setValue("poc_name", data.poc_name || "");
        setValue("poc_email", data.poc_email || "");
        setValue("poc_phone_ext", data.poc_phone_ext || "");
      });
    }
  }, [selectedUserId]);

  const fetchData = async () => {
    await axios.get(url + `api/FiUniqueCategory`).then(({ data }) => {
      setFis(data);
    });
  };

  const onSubmit = (formdata) => {
    // alert(data);
    //console.log(formdata);

    if (selectedUserId !== "") {
      var myform = document.getElementById("addfiForm");
      var fd = new FormData(myform);
      var imagefile = document.querySelector("#filogo");
      fd.append("profile", imagefile.files[0]);

      axios
        .post(url + `api/fi/${selectedUserId}/update/`, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              icon: "success",
              text: data.message,
            });
            fetchProducts();
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          onClose();
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              text: data.message,
              icon: "error",
            });
          }
        });
    } else {
      var myform = document.getElementById("addfiForm");
      var fd = new FormData(myform);
      var imagefile = document.querySelector("#filogo");
      fd.append("profile", imagefile.files[0]);

      axios
        .post(url + "api/fi/add", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              icon: "success",
              text: data.message,
            });
            fetchProducts();
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          onClose();
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              text: data.message,
              icon: "error",
            });
          }
        });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    //console.log("Selected File:", file);
  };
  return (
    <div className="popup">
      <div className="popup-content">
        <div className="popup-header">
          <img
            onClick={onClose}
            className="close-icon"
            src={CloseIcon}
            alt="x"
          />
          <h2>{Title1}</h2>
        </div>
        <form id="addfiForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="popup-body">
            <div className="left-body">
              <div className="body-element">
                <label>FI Name</label>
                <input
                  type="text"
                  name="finame"
                  id="finame"
                  placeholder="Enter Name"                  
                  defaultValue={finame}
                  {...register("finame", { required: "This Field is empty" })}
                />
                {errors.name && (
                  <span className="error">{errors.name.message}</span>
                )}
              </div>
              <div className="body-element">
                <label>FI Logo</label>

                <div className="addClient-field-1">
                  <input
                    type="file"
                    name="filogo"
                    id="filogo"
                    // id="file-upload5"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    // {...register("photo")}
                  />
                  <input
                    type="text"
                    placeholder=" File name shown here"
                    value={selectedFile ? selectedFile.name : ""}
                    {...register("fileName")}
                    readOnly
                  />
                  <label htmlFor="filogo" className="file-upload-label">
                    <img
                      className="icon-width margin-no"
                      src={UploadIcon}
                      alt="^ Upload"
                    />
                  </label>
                  {selectedFile && (
                    <img
                      className="icon-width delete-btn margin-no"
                      src={DeleteIcon}
                      alt="* Delete"
                      onClick={() => setSelectedFile(null)}
                    />
                  )}
                </div>
              </div>
              <div className="body-element">
                <label>Category</label>
                <select
                  id="fiDetailsCategory"
                  name="fiDetailsCategory"
                  defaultValue={fiDetailsCategory}
                  {...register("segmentDetailsCategory", { required: true })}
                >
                  {/* name="segmentDetailsCategory" {...register("fi_sel")}> */}
                  {filist.map((fiCategory, index) => (
                    <option value={index}>{fiCategory.toUpperCase()}</option>
                  ))}
                </select>
              </div>

              <div className="body-element">
                <label htmlFor="Description">Description</label>
                <textarea
                  cols="30"
                  name="description"
                  id="description"
                  defaultValue={description}
                  rows="10"
                  placeholder="Type Something"
                ></textarea>
              </div>

              <div className="body-element">
                <label htmlFor="Description">Person Of contect Name</label>
                <input type="text" name="poc_name" id="poc_name" defaultValue={poc_name} placeholder="Enter Name" />
              </div>
              <div className="body-element">
                <label htmlFor="Description">Person Of contect Email</label>
                <input
                  type="email"
                  name="poc_email"
                  id="poc_email"
                  defaultValue={poc_email}
                  placeholder="Enter Email"
                />
              </div>

              <div className="body-element">
                <label>Contact Number</label>
                {/* +00 extention input field*/}
                <div>
                  <select name="poc_phone_ext" id="poc_phone_ext" value={poc_phone_ext} {...register("poc_phone_ext", { required: "+00" })}>
                    <option value="+91">+91</option>
                    <option value="+11">+11</option>
                  </select>
                  {errors.extention && (
                    <span className="error">{errors.extention.message}</span>
                  )}
                  <input
                    type="tel"
                    {...register("poc_phone", {
                      required: "Enter Your Number",
                    })}
                  />
                  {errors.contactNumber && (
                    <span className="error">
                      {errors.contactNumber.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="popup-footer">
            <button type="button" className="cancel-button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="save-button">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FiPopup;
