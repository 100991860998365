import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

import "../ContentManagement/ContentTable.css"; //main css
import "../Campaign/CampainTable.css"; //remaining css

import FiPopup from "./FiPopup";

import SortIcon from "../icons/ConentManagement/Sort-1.svg";
import Ascending from "../icons/ConentManagement/ascending.svg";
import Descending from "../icons/ConentManagement/descending.svg";
import MoreOptions from "../icons/more-options.svg";

const FiTableNew = ({
  setSelectedUserId,  //stores the selected row id
  selectedUserId,
  fiData,
  setFiData,
  searchKeyword,

  fetchProducts,
}) => {
  const url = window.$APIURL;

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({
    id: null,
    finame: null,
    category: null,
    location: null,
    zone: null,
  });

  const key_word = searchKeyword.toString().toLowerCase();
  const filteredData = fiData.filter(
    (item) =>
      key_word === "" ||
      item.id.toString().includes(key_word) ||
      item.finame.toLowerCase().includes(key_word) ||
      item.category.toLowerCase().includes(key_word) ||
      item.location.toLowerCase().includes(key_word) ||
      item.zone.toLowerCase().includes(key_word)
  );

  const pageCount = Math.ceil(filteredData.length / perPage);
  const offset = currentPage * perPage;
  const paginatedData = filteredData.slice(offset, offset + perPage);

  // const pageCount = Math.ceil(fiData.length / perPage);
  // const offset = currentPage * perPage;
  // const paginatedData = fiData.slice(offset, offset + perPage);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setFiData((prevData) =>
      prevData.map((item) => ({
        ...item,
        selected: !selectAll,
      }))
    );
  };

  const closePopup = () => {
    setIsPopupVisible(false); // Function to close the popup
  };

  const handleSelect = (id) => {
    const newData = fiData.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setFiData(newData);
    setSelectedRow(id); // Set the selected row
  };

  let newSortOrder;

  const handleSort = (field) => {
    setSortOrder((prevSortOrder) => {
      if (prevSortOrder[field] === "asc") {
        newSortOrder = "desc";
      } else if (prevSortOrder[field] === "desc") {
        newSortOrder = null;
      } else {
        newSortOrder = "asc";
      }

      return {
        ...prevSortOrder,
        [field]: newSortOrder,
      };
    });

    setFiData((prevData) => {
      const newData = [...prevData];
      const currentSortOrder = sortOrder[field];

      newData.sort((a, b) => {
        if (currentSortOrder === "asc") {
          return a[field].localeCompare(b[field]);
        } else if (currentSortOrder === "desc") {
          return b[field].localeCompare(a[field]);
        } else {
          // original order
          return a.id - b.id;
        }
      });

      return newData;
    });
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const getSortButtonIcon = (field) => {
    const currentSortOrder = sortOrder[field];
    if (currentSortOrder === "asc") {
      return <img src={SortIcon} alt="Sort" />;
    } else if (currentSortOrder === "desc") {
      return <img src={Ascending} alt="Ascending" />;
    } else {
      return <img src={Descending} alt="Descending" />;
    }
  };

  const togglePopup = (id) => {
    setPopupVisible((prevPopupVisible) => !prevPopupVisible);
    setSelectedRow(id);
  };
  const handleEdit = (id) => {
    // alert(`Editing item with ID: ${id}`);
    setSelectedUserId(id);
    setIsPopupVisible(true);
    setSelectedRow(null);
  };

  const handleClone = (id) => {
    setSelectedRow(null);
    const confirmDelete = window.confirm(
      `Are you sure you want to clone this item (id: ${id})?`
    );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Fetch the data of the selected row
    axios
      .post(`https://digitalxapi.cncitsolutions.in/api/fi/${id}/clone`)
      .then(({ data }) => {
        if (data) {
          // Create a new row with the same data
          const clonedData = {
            ...data,
            //id: null, // Set id to null or generate a new unique id if needed
            // You may also need to modify other fields if necessary
          };

          // Send a POST request to add the cloned row to the database
          axios
            .get("https://digitalxapi.cncitsolutions.in/api/fi", clonedData)
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire({
                  icon: "success",
                  text: "Row cloned and added successfully.",
                });
                fetchProducts();
              } else {
                Swal.fire({
                  icon: "error",
                  text: "Error cloning row 1.",
                });
              }
            })
            .catch(({ data }) => {
              if (data.status == 422) {
              } else {
                Swal.fire({
                  icon: "error",
                  text: "Error cloning row 2.",
                });
              }
            });
        } else {
          Swal.fire({
            icon: "error",
            text: "Selected row not found.",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching row data:", error);
      });
  };

  const handleDisable = (id) => {
    setSelectedRow(null);
    const confirmDelete = window.confirm(
      `Are you sure you want to disable this item (id: ${id})? This action can only be undone by an ADMIN.`
    );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Send a DELETE request to the delete endpoint
    axios
      .post(`https://digitalxapi.cncitsolutions.in/api/fi/${id}/disable`, {
        isDisabled: "1",
      })
      .then(({ data }) => {
        if (data.status == true) {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
          fetchProducts();
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      })
      .catch(({ data }) => {
        if (data.status === 422) {
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      });
  };

  const handleDelete = (id) => {
    setSelectedRow(null);
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this item (id: ${id})? This action cannot be undone.`
    );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Send a DELETE request to the delete endpoint
    axios
      .post(`https://digitalxapi.cncitsolutions.in/api/fi/${id}/delete`, {
        isDeleted: "1",
      })
      .then(({ data }) => {
        if (data.status == true) {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
          fetchProducts();
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      })
      .catch(({ data }) => {
        if (data.status === 422) {
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      });
  };

  return (
    <>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>
                <label>No.</label>
              </th>
              <th>
                Name
                <button onClick={() => handleSort("finame")}>
                  {getSortButtonIcon("finame")}
                </button>
              </th>
              <th>
                Location
                <button onClick={() => handleSort("location")}>
                  {getSortButtonIcon("location")}
                </button>
              </th>
              <th>
                Zone
                <button onClick={() => handleSort("zone")}>
                  {getSortButtonIcon("zone")}
                </button>
              </th>
              <th>
                Category
                <button onClick={() => handleSort("category")}>
                  {getSortButtonIcon("category")}
                </button>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData
              .filter(
                (item) =>
                  searchKeyword === "" ||
                  item.finame
                    .toLowerCase()
                    .includes(searchKeyword.toLowerCase()) ||
                  item.category
                    .toLowerCase()
                    .includes(searchKeyword.toLowerCase()) ||
                  item.zone
                    .toLowerCase()
                    .includes(searchKeyword.toLowerCase()) ||
                  item.location
                    .toLowerCase()
                    .includes(searchKeyword.toLowerCase())
              )
              .map((item, index) => (
                <tr key={item.id}>
                  <td>
                    <label>{index + 1}</label>
                  </td>
                  <td>
                    <div
                      className="name-toggle"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <img
                        src={url + "filogo/" + item.filogo}
                        width="60px"
                        alt=""
                      />
                      <Link
                        to={{
                          pathname: `/FiManagement/FiDetail/${item.id}`,
                        }}
                      >
                        <label>{item.finame}</label>
                      </Link>
                    </div>
                  </td>
                  <td>
                    <div className="category-checkbox">{item.location}</div>
                  </td>
                  <td>{item.zone}</td>
                  <td>{item.category}</td>
                  <td>
                    <div className="fi-icon" onClick={() => togglePopup(item.id)} style={{ position: "relative", cursor: 'pointer' }}>
                      <img
                        src={MoreOptions}
                        alt="MoreOptions"                        
                      />
                      {selectedRow === item.id && popupVisible && (
                        <div className="popup-content-1 popup-content-2">
                          <ul>
                            <li
                              onClick={() => handleEdit(selectedRow)}
                              className="blue"
                            >
                              Edit
                            </li>
                            <li
                              onClick={() => handleClone(selectedRow)}
                              className="blue"
                            >
                              Clone
                            </li>
                            <li
                              onClick={() => handleDisable(selectedRow)}
                              className="red"
                            >
                              Disable
                            </li>
                            <li
                              onClick={() => handleDelete(selectedRow)}
                              className="red"
                            >
                              Delete
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="pagination">
          <ReactPaginate
            previousLabel="&lt;"
            nextLabel="&gt;"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination-container"
            activeClassName="active"
          />
          <div>
            <span>Output by: </span>
            <select
              value={perPage}
              onChange={(e) => setPerPage(parseInt(e.target.value, 10))}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>

        {/* <FilterAddContent isMoreButtonVisible={isMoreButtonVisible} /> */}
      </div>
      {isPopupVisible && <FiPopup Title1="Edit User" selectedUserId={selectedUserId} onClose={closePopup} />}
    </>
  );
};

export default FiTableNew;
