import React from "react";
import { useForm } from "react-hook-form";

import xIcon from '../../icons/ConentManagement/x-03.svg'

import './AddLinksPopup.css'

const AddLinksPopup = ({ onClose, Title1 }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    //console.log(data);
    // Perform save logic here
  };

  return (
    <div className="small-popup">
      <div className="small-popup-content">
        <div className="small-popup-header">
          <h2>{Title1}</h2>
          <img src={xIcon} alt="x" className="x-btn" onClick={onClose}/>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="small-popup-body">
            <div className="small-body-element">
              <label>Text to Display:</label>
              <input
                type="text"
                {...register("displayText", { required: "This field is required" })}
              />
              {errors.displayText && (
                <span className="error">{errors.displayText.message}</span>
              )}
            </div>
            <div className="small-body-element">
              <label>Link To:</label>
              <input
                type="text"
                {...register("linkTo", { required: "This field is required" })}
              />
              {errors.linkTo && (
                <span className="error">{errors.linkTo.message}</span>
              )}
            </div>
            <div className="small-body-element">
              <input
                type="radio"
                id="webAddress"
                value="webAddress"
                {...register("linkType")}
              />
              <label htmlFor="webAddress">Web Address</label>
              <p>Test this link</p>
            </div>
            <div className="small-body-element">
              <input
                type="radio"
                id="emailAddress"
                value="emailAddress"
                {...register("linkType")}
              />
              <label htmlFor="emailAddress">Email Address</label>
              <p>
                Not sure what to put in the box? First, find the page on the web
                that you want to link to. Then, copy the web address from the box
                in your browser’s address bar and paste it into the box above.
              </p>
            </div>
          </div>
          <div className="small-popup-footer">
            <button type="button" className="cancel-button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="ok-button">
              OK
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddLinksPopup;
