import React, { useState } from "react";

import "./StrategicData.css";

import UserProfile2 from "../../icons/user-profile-03.svg";
import RupeeSymbol from "../../icons/rupee-symbol 1.svg";

const StrategicData = () => {
  const [isActive, setIsActive] = useState(true);

  // Toggle active/inactive
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div className="s-data-main">
        <div className="s-data-top">
          <button className={isActive ? "active" : ""} onClick={toggleActive}>
            Strategic Data
          </button>
          <button className={!isActive ? "active" : ""} onClick={toggleActive}>
            Proposed Data
          </button>
        </div>
        <div className="s-data-middle">
          <div className="count">
            <label>Current Data</label>
            <label>Batch ID : 1234567891</label>
          </div>
          <div className="boxes">
            <div className="heading-box">
              <label>Optimal period of time</label>
              <label>3-5PM</label>
            </div>
            <div className="box-all">
              <div className="b-left b-com">
                <div className="parts">
                  <label>Aquired</label>
                  <label>
                    <img src={UserProfile2} alt="" /> 400 Users
                  </label>
                </div>
                    <button>20%</button>
              </div>
              <div className="b-right b-com">
                <div className="parts">
                  <label>Amt Recovered</label>
                  <label>
                    <img src={RupeeSymbol} alt="" /> 80 Cr
                  </label>
                </div>
                    <button>20%</button>
              </div>
            </div>
          </div>
          <div className="count">
            <label>Previous Data</label>
            <label>Batch ID : 1234567891</label>
          </div>
          <div className="boxes">
            <div className="heading-box">
              <label>Optimal period of time</label>
              <label>3-5PM</label>
            </div>
            <div className="box-all">
              <div className="b-left b-com">
                <div className="parts">
                  <label>Aquired</label>
                  <label>
                    <img src={UserProfile2} alt="" /> 400 Users
                  </label>
                </div>
                    <button>20%</button>
              </div>
              <div className="b-right b-com">
                <div className="parts">
                  <label>Amt Recovered</label>
                  <label>
                    <img src={RupeeSymbol} alt="" /> 80 Cr
                  </label>
                </div>
                    <button>20%</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StrategicData;
