import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";

import CrossBTN from "../../icons/Campaining/x-01.svg";
import PlueIcon from "../../icons/Campaining/plus-01.svg";

const ContactSelection2 = ({
  setSwitchContactDecission,
  setShowDecissionBox,
  setShowContactSelection1,
  handleAddChild,
  savedFormData,
  setSavedFormData,
}) => {
  const [options1, setOptions1] = useState([]);


  useEffect(() => {
    fetch('https://digitalxapi.cncitsolutions.in/api/Segment/nameNid')
      .then((response) => response.json())
      .then((data) => {
        const formattedOptions = data.map((segment) => ({
          value: segment.id,
          label: segment.name,
        }));
        setOptions1(formattedOptions);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const options = [
    { value: "Email Marketing", label: "Email Marketing" },
    { value: "Newsletter", label: "Newsletter" },
    { value: "On-Boarding Tribe ", label: "On-Boarding Tribe " },
    {
      value: "Pending Email Confirmation",
      label: "Pending Email Confirmation",
    },
    { value: "Status Active", label: "Status Active" },
    { value: "Top 7 Wordpress Plugins", label: "Top 7 Wordpress Plugins" },
  ];

  // Step 1: Create a single state object
  const [formData, setFormData] = useState({
    name: "",
    executeEvent: "immediately",
    relativeTime: "",
    specificDateTime: "",
    selectedTags: null,
    selectedRemoveTags: null,
  });

  const handleNameChange = (selectedOption) => {
    setFormData({
      ...formData,
      name: selectedOption,
    });
    setSavedFormData({
      ...savedFormData,
      name: selectedOption ? selectedOption.value : null,
    });
  };

  const handleTagsChange = (selectedOption) => {
    setFormData({
      ...formData,
      selectedTags: selectedOption,
    });
    setSavedFormData({
      ...savedFormData,
      selectedTags: selectedOption ? selectedOption.value : null,
    });
  };

  const handleRemoveTagsChange = (selectedOption) => {
    setFormData({
      ...formData,
      selectedRemoveTags: selectedOption,
    });
    setSavedFormData({
      ...savedFormData,
      selectedRemoveTags: selectedOption ? selectedOption.value : null,
    });
  };

  const handleRelativeTimeChange = (event) => {
    const { value } = event.target;
    setSavedFormData({
      ...savedFormData,
      relativeTime: value,
    });
  };

  const handleSpecificDateTimeChange = (event) => {
    const { value } = event.target;
    setSavedFormData({
      ...savedFormData,
      specificDateTime: value,
    });
  };

  const handleCancel = () => {
    setShowContactSelection1(false);
    setSwitchContactDecission(true);
    setShowDecissionBox(false);
  };

  const handlePlus = () => {
    setSwitchContactDecission(true);
    setShowDecissionBox(false);
    handleAddChild();
    setShowContactSelection1(false);
    console.log("----------------------------------");
    console.log(JSON.stringify(formData));
    console.log("----------------------------------");
    console.log(JSON.stringify(savedFormData));
    console.log("----------------------------------");
  };
  return (
    <form id="contactSelection2Form" className="dark-bg">
      <div className="contact-source-details width">
        <h4>Condition based on a contact source tags</h4>
        <label>Name</label>
        <Select
          isClearable
          id="nameOfContactSource"
          name="nameOfContactSource"
          className="select-container"
          options={options1}
          value={formData.name}
          onChange={handleNameChange}
          placeholder="Choose one..."
        />
        <label>Execute this event..</label>
        <div>
          <span className="bg-blue">Immediately</span>
          <input
            type="text"
            id="relativeTime"
            name="relativeTime"
            defaultValue={savedFormData.relativeTime}
            onChange={handleRelativeTimeChange}
            placeholder="at a relative time period"
          />
          <input
            type="text"
            id="specificDateTime"
            name="specificDateTime"
            defaultValue={savedFormData.specificDateTime}
            onChange={handleSpecificDateTimeChange}
            placeholder="at a specific date/time"
          />
        </div>
        <label>Tags</label>
        <Select
          isClearable
          id="tagsOfContactSource"
          name="tagsOfContactSource"
          className="select-container"
          options={options}
          value={formData.selectedTags}
          onChange={handleTagsChange}
          placeholder="Choose one..."
        />
        <label>Remove Tags</label>
        <Select
          isClearable
          id="removeTagsOfContactSource"
          name="removeTagsOfContactSource"
          className="select-container"
          options={options}
          value={formData.selectedRemoveTags}
          onChange={handleRemoveTagsChange}
          placeholder="Choose one..."
        />
        <div className="full-width">
          <button className="cancel-btn" onClick={handleCancel}>
            <img src={CrossBTN} alt="X" />
            CANCEL
          </button>
          <button className="more-btn" onClick={handlePlus}>
            <img src={PlueIcon} alt="+" />
            ADD
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactSelection2;
