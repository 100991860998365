import React, { useState, useEffect } from "react";
import axios from "axios";

import "../ClientSelect.css"; // main css
import "./WASimple.css"; // remaining css 1
import TextSimpleComponent from "./SimpleComponents/TextSimpleComponent.js";
import ImageSimpleComponent from "./SimpleComponents/ImageSimpleComponent.js";
import DocumentSimpleComponent from "./SimpleComponents/DocumentSimpleComponent.js";
import VideoSimpleComponent from "./SimpleComponents/VideoSimpleComponent.js";
import LocationSimpleComponent from "./SimpleComponents/LocationSimpleComponent.js";

const WASimple = ({
  register,
  errors,
  setValue,
  watch,
  selectedUserId,
  setClientName,
  setProductType,
  setContentName,
  setChannel,
  setContentDescription,
}) => {
  const [selectedOption, setSelectedOption] = useState("Text");

  // text
  const WASimpleTextNumber = watch("WASimpleTextNumber", "");
  const WASimpleTextContent = watch("WASimpleTextContent", "");
  // image
  const numImgSimpleWA = watch("numImgSimpleWA", "");
  const simpleImgURL = watch("simpleImgURL", "");
  const simpleImgTemplate = watch("simpleImgTemplate", "");
  const simpleImgFileContent = watch("simpleImgFileContent", "");
  // document
  const textNumberDocSimple = watch("textNumberDocSimple", "");
  const selectedURLDocsSimple = watch("selectedURLDocsSimple", "");
  const fileUploadDocsSimple = watch("fileUploadDocsSimple", "");
  const extentionsDocsSimple = watch("extentionsDocsSimple", "");
  const textTemplateDocSimple = watch("textTemplateDocSimple", "");
  // video
  const numVideoSimpleWA = watch("numVideoSimpleWA", "");
  const URLVideoSimpleWA = watch("URLVideoSimpleWA", "");
  const fileUploadVideoSimpleWA = watch("fileUploadVideoSimpleWA", "");
  const textTemplateVideoSimpleWA = watch("textTemplateVideoSimpleWA", "");
  // location
  const numLocationSimpleWA = watch("numLocationSimpleWA", "");
  const latitudeLocationSimpleWA = watch("latitudeLocationSimpleWA", "");
  const longitudeLocationSimpleWA = watch("longitudeLocationSimpleWA", "");
  const nameLocationSimpleWA = watch("nameLocationSimpleWA", "");
  const addrLocationSimpleWA = watch("addressLocationSimpleWA", "");
  const templateLocationSimpleWA = watch("templateLocationSimpleWA", "");

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (selectedUserId !== "") {
      fetchProducts();
    }
  }, [selectedUserId]);

  const fetchProducts = async () => {
    await axios
      .post(
        `https://digitalxapi.cncitsolutions.in/api/contentManagement/${selectedUserId}/edit`
      )
      .then(({ data }) => {
        console.log("data", data);
        //common
        setClientName(data.Name || "");
        setProductType(data.Product_type || "");
        setChannel(data.Channel || "");
        setContentName(data.Created_by || "");
        setContentDescription(data.ContentDescription || "");

        // text
        setValue("WASimpleTextNumber", data.WA_Simple_Text_Number || "");
        setValue("WASimpleTextContent", data.WA_Simple_Text_Content || "");
        // image
        setValue("numImgSimpleWA", data.WA_Simple_Img_Number || "");
        setValue("simpleImgURL", data.WA_Simple_Img_URL || "");
        setValue("simpleImgTemplate", data.WA_Simple_Img_Template || "");
        // document
        setValue("textNumberDocSimple", data.WA_Simple_doc_Number || "");
        setValue("selectedURLDocsSimple", data.WA_Simple_Filename_URL || "");
        setValue("extentionsDocsSimple", data.WA_Simple_doc_URL || "");
        setValue("textTemplateDocSimple", data.WA_Simple_doc_Template || "");
        // video
        setValue("numVideoSimpleWA", data.WA_Simple_Video_Number || "");
        setValue("URLVideoSimpleWA", data.WA_Simple_Video_URL || "");
        setValue("fileUploadVideoSimpleWA", data.WA_Simple_Video_File_URL || "");
        setValue("textTemplateVideoSimpleWA", data.WA_Simple_Video_Template || "");
        // location
        setValue("numLocationSimpleWA", data.WA_Simple_Location_Number || "");
        setValue("latitudeLocationSimpleWA", data.WA_Simple_Location_Latitude || "");
        setValue("longitudeLocationSimpleWA", data.WA_Simple_Location_Longitude || "");
        setValue("nameLocationSimpleWA", data.WA_Simple_Location_Name || "");
        setValue("addressLocationSimpleWA", data.WA_Simple_Location_Address || "");
        setValue("templateLocationSimpleWA", data.WA_Simple_Location_Template || "");
      });
  };

  return (
    <main className={`addClient-wrapper option-client-wrapper`}>
      <section>
        <button
          type="button"
          onClick={() => handleOptionChange("Text")}
          className={selectedOption === "Text" ? "activeBackground" : ""}
        >
          Text
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("Image")}
          className={selectedOption === "Image" ? "activeBackground" : ""}
        >
          Image
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("Document")}
          className={selectedOption === "Document" ? "activeBackground" : ""}
        >
          Document
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("Video")}
          className={selectedOption === "Video" ? "activeBackground" : ""}
        >
          Video
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("Location")}
          className={selectedOption === "Location" ? "activeBackground" : ""}
        >
          Location
        </button>
      </section>
      <TextSimpleComponent
        WASimpleTextNumber={WASimpleTextNumber}
        WASimpleTextContent={WASimpleTextContent}
        componentClassName={`component ${
          selectedOption === "Text" ? "active" : ""
        }`}
        register={register}
        errors={errors}
        setValue={setValue}
        watch={watch}
      />
      <ImageSimpleComponent
        numImgSimpleWA={numImgSimpleWA}
        simpleImgURL={simpleImgURL}
        simpleImgTemplate={simpleImgTemplate}
        simpleImgFileContent={simpleImgFileContent}
        componentClassName={`component ${
          selectedOption === "Image" ? "active" : ""
        }`}
        register={register}
        errors={errors}
        setValue={setValue}
        watch={watch}
      />
      <DocumentSimpleComponent
        textNumberDocSimple={textNumberDocSimple}
        selectedURLDocsSimple={selectedURLDocsSimple}
        fileUploadDocsSimple={fileUploadDocsSimple}
        extentionsDocsSimple={extentionsDocsSimple}
        textTemplateDocSimple={textTemplateDocSimple}
        componentClassName={`component ${
          selectedOption === "Document" ? "active" : ""
        }`}
        register={register}
        errors={errors}
        setValue={setValue}
        watch={watch}
      />
      <VideoSimpleComponent
        numVideoSimpleWA={numVideoSimpleWA}
        URLVideoSimpleWA={URLVideoSimpleWA}
        fileUploadVideoSimpleWA={fileUploadVideoSimpleWA}
        textTemplateVideoSimpleWA={textTemplateVideoSimpleWA}
        componentClassName={`component ${
          selectedOption === "Video" ? "active" : ""
        }`}
        register={register}
        errors={errors}
        setValue={setValue}
        watch={watch}
      />
      <LocationSimpleComponent
      numLocationSimpleWA={numLocationSimpleWA}
      latitudeLocationSimpleWA={latitudeLocationSimpleWA}
      longitudeLocationSimpleWA={longitudeLocationSimpleWA}
      nameLocationSimpleWA={nameLocationSimpleWA}
      addrLocationSimpleWA={addrLocationSimpleWA}
      templateLocationSimpleWA={templateLocationSimpleWA}
        componentClassName={`component ${
          selectedOption === "Location" ? "active" : ""
        }`}
        register={register}
        errors={errors}
        setValue={setValue}
        watch={watch}
      />
    </main>
  );
};

export default WASimple;
