import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import Swal from "sweetalert2";
import ContactSourceDetails from "../Popup/ContactSourceDetails";

import Users1 from "../../icons/Campaining/users-profiles-01.svg";

import "./AddContactSource.css";

const AddContactSource = ({
  textOnAdd,
  selectedOption,
  setSelectedOption,
  popupOptions,
  setPopupOptions,

  selectedUserId,
  setTreeData,
}) => {
  const url = window.$APIURL;

  const [latestData, setLatestData] = useState([]);

  const [showDetails, setShowDetails] = useState(false);

  const handleCancel = () => {
    setSelectedOption("");
    setShowDetails(false);
  };

  useEffect(() => {
    // Make an API request to the Laravel endpoint to delete the newly inserted data when we cancel
    if (selectedUserId == "") {
      axios
        .post("https://digitalxapi.cncitsolutions.in/api/campaining/latest-id")
        .then((response) => {
          // Set the latest data in your state
          setLatestData(response.data.data.id);
        })
        .catch((error) => {
          alert("Error fetching latest data:", error);
        });
    }
  }, []);

  const onSubmit = (formdata, e) => {
    if (selectedUserId !== "") {
      var myform = document.getElementById("addContactSourceFormData");
      var fd = new FormData(myform);
      axios
        .post(
          url +
            `api/campaining/addContactSourceFormData/${selectedUserId}/update`,
          fd,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(({ data }) => {
          if (data.status == true) {
            // Swal.fire({
            //   icon: "success",
            //   text: data.message,
            // });
            setShowDetails(true);
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    } else {
      var myform = document.getElementById("addContactSourceFormData");
      var fd = new FormData(myform);
      axios
        .post(
          url + `api/campaining/addContactSourceFormData/${latestData}/update`,
          fd,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(({ data }) => {
          if (data.status == true) {
            // Swal.fire({
            //   icon: "success",
            //   text: data.message,
            // });
            setShowDetails(true);
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    }
  };

  return (
    <>
      <Draggable bounds="parent">
        <form id="addContactSourceFormData" className="draggable-element">
          <label className="blue-bg">
            <img src={Users1} alt="" /> Add a contact source...
          </label>
          <div className="drag-inner">
            <label>Contact Sources</label>
            <select
              value={selectedOption}
              onChange={(event) => setSelectedOption(event.target.value)}
              id="contactSourceSelection"
              name="contactSourceSelection"
            >
              <option hidden value="">
                Choose one...
              </option>
              <option value="Contact segments">Contact segments</option>
              <option value="Campaign Forms">Campaign Forms</option>
              <option value="Campaign Date">Campaign Date</option>
            </select>
            <button
              type="button"
              onClick={() => onSubmit()}
              form="addContactSourceFormData"
              className="submit-btn"
            >
              Next
            </button>
          </div>
        </form>
      </Draggable>
      {showDetails && (
        <ContactSourceDetails
          setTreeData={setTreeData}
          popupOptions={popupOptions}
          setPopupOptions={setPopupOptions}
          selectedOption={selectedOption}
          onCancel={handleCancel}
          textOnAdd={textOnAdd}
          selectedUserId={selectedUserId}
        />
      )}
    </>
  );
};

export default AddContactSource;
