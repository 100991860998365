import React, { useEffect, useState } from "react";
import axios from "axios";

import LoadingPopup from "../CommonComponents/LoadingPopup";

import UserStatus from "../CommonComponents/Status";
import SearchSwitch from "./SearchSwitch";
import UserTable from "./Tables/UserTable";
import UserTable2 from "./Tables/UserTable2.js";

import Total from "../icons/programmer 1.svg";
import Active from "../icons/user (1) 1.svg";
import Inactive from "../icons/user (1) 2.svg";
import Planned from "../icons/manager (1) 1.svg";

const MainComp = () => {
  const [userManagementData, setUserManagementData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    fetchProducts();
  }, []);

  const [error, setError] = useState(null);

  const fetchProducts = async () => {
    setLoading(true);

    try {
      const response = await axios.get("https://digitalxapi.cncitsolutions.in/api/users");
      setUserManagementData(
        response.data
          .filter((item) => item.isDeleted === 0)
      );
      setError(null); // Clear any previous error
    } catch (err) {
      if (err.response && err.response.status === 500) {
        setError("Internal Server Error: Something went wrong.");
      } else {
        // Handle other errors if needed
        setError("An error occurred while fetching data.");
      }
    } finally {
      setLoading(false);
    }
  };

  const statusData = [
    {
      icon: Total,
      title: "Total User",
      value: userManagementData.length.toString(),
      upValue: "10%",
    },
    {
      icon: Active,
      title: "Active User",
      value: userManagementData
        .filter((users) => users.isActive === 1)
        .length.toString(),
      upValue: "10%",
    },
    {
      icon: Inactive,
      title: "Inactive User",
      value: userManagementData
        .filter((users) => users.isActive === 0)
        .length.toString(),
      upValue: "10%",
    },
    {
      icon: Planned,
      title: "Total Leader",
      value: userManagementData.length.toString(),
    },
  ];

  return (
    <div className="mainContainer">
      <div className="mainWrapper">
        <div className="card-container">
          <UserStatus statusData={statusData} />
        </div>
        <div className="input-group searchFilterContainer">
          <SearchSwitch
            setSearchKeyword={setSearchKeyword}
            isActive={isActive}
            setIsActive={setIsActive}
            fetchProducts={fetchProducts}
          />
        </div>
        <div className="searchResultsContainer">
          {loading ? (
            <LoadingPopup show={true} />
          ) : userManagementData.length === 0 ? (
            <h3 className="h3-p-5">{error}</h3>
          ) : (
            // <UserTable data={userManagementData} />
            <UserTable2
              isActive={isActive}
              selectedUserId={selectedUserId}
              searchKeyword={searchKeyword}
              setSelectedUserId={setSelectedUserId}
              setUserManagementData={setUserManagementData}
              userManagementData={userManagementData}
              fetchProducts={fetchProducts}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MainComp;
