import React, { useState } from "react";

import "../ClientSelect.css"; //main css
import "./WASimple.css"; //remaining css 1

import TextBulkComponent from "./BulkComponents/TextBulkComponent.js";
import ImageBulkComponent from "./BulkComponents/ImageBulkComponent.js";
import DocumentBulkComponent from "./BulkComponents/DocumentBulkComponent.js";
import VideoBulkComponent from "./BulkComponents/VideoBulkComponent.js";
import LocationBulkComponent from "./BulkComponents/LocationBulkComponent.js";

const WABulk = () => {
  const [selectedOption, setSelectedOption] = useState("Text");

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <main className="addClient-wrapper option-client-wrapper">
      <section>
        <button
          type="button"
          onClick={() => handleOptionChange("Text")}
          className={selectedOption === "Text" ? "activeBackground" : ""}
        >
          Text
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("Image")}
          className={selectedOption === "Image" ? "activeBackground" : ""}
        >
          Image
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("Document")}
          className={selectedOption === "Document" ? "activeBackground" : ""}
        >
          Document
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("Video")}
          className={selectedOption === "Video" ? "activeBackground" : ""}
        >
          Video
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("Location")}
          className={selectedOption === "Location" ? "activeBackground" : ""}
        >
          Location
        </button>
      </section>
      {selectedOption === "Text" && <TextBulkComponent />}
      {selectedOption === "Image" && <ImageBulkComponent />}
      {selectedOption === "Document" && <DocumentBulkComponent />}
      {selectedOption === "Video" && <VideoBulkComponent />}
      {selectedOption === "Location" && <LocationBulkComponent />}
    </main>
  );
};

export default WABulk;
