import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import Swal from "sweetalert2";

import "./ContentTable.css";

import UserPopup from "../UserPopup";

import SortIcon from "../../icons/ConentManagement/Sort-1.svg";
import Ascending from "../../icons/ConentManagement/ascending.svg";
import Descending from "../../icons/ConentManagement/descending.svg";
import MoreOptions from "../../icons/more-options.svg";

const UserTable2 = ({
  //   setMoreButtonVisible,
  //   isMoreButtonVisible,
  //   setCancelButtonVisible,
  //below 2 are the states for the displaying the data data
  userManagementData,
  setUserManagementData,
  //   setShowClientSection,
  //   setActiveForm,
  setSelectedUserId,
  selectedUserId,
  searchKeyword,
  isActive,
}) => {
  const url = window.$APIURL;

  const [isPopupVisible, setIsPopupVisible] = useState(false); // Add state for popup visibility

  //   const [selectAll, setSelectAll] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({
    name: null,
    email: null,
    role: null,
    created_at: null,
  });

  const filteredData = userManagementData
    .filter((item) => {
      const lowerSearchKeyword = searchKeyword.toLowerCase();
      // Check if any of the item properties are null before applying .toLowerCase()
      const nameMatch =
        item.name?.toLowerCase()?.includes(lowerSearchKeyword) ?? false;
      const emailMatch =
        item.email?.toLowerCase()?.includes(lowerSearchKeyword) ?? false;
      const roleMatch =
        item.role?.toLowerCase()?.includes(lowerSearchKeyword) ?? false;
      // Include the item in the filteredData if any property matches
      return lowerSearchKeyword === "" || nameMatch || emailMatch || roleMatch;
    })
    .filter((item) => (isActive ? item.isActive == 1 : item.isActive == 0));

  const pageCount = Math.ceil(filteredData.length / perPage);
  const offset = currentPage * perPage;
  const paginatedData = filteredData.slice(offset, offset + perPage);

  //   const handleSelectAll = () => {
  // setMoreButtonVisible(!isMoreButtonVisible);
  // setCancelButtonVisible(true);
  //     setSelectAll(!selectAll);
  //     setUserManagementData((prevData) =>
  //       prevData.map((item) => ({
  //         ...item,
  //         selected: !selectAll,
  //       }))
  //     );
  //   };

  const handleSelect = (id) => {
    const newData = userManagementData.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setUserManagementData(newData);
    setSelectedRow(id); // Set the selected row
  };

  const handleSort = (field) => {
    let newSortOrder;
    setSortOrder((prevSortOrder) => {
      if (prevSortOrder[field] === "asc") {
        newSortOrder = "desc";
      } else if (prevSortOrder[field] === "desc") {
        newSortOrder = null;
      } else {
        newSortOrder = "asc";
      }

      return {
        ...prevSortOrder,
        [field]: newSortOrder,
      };
    });

    setUserManagementData((prevData) => {
      const newData = [...prevData];
      const currentSortOrder = sortOrder[field];

      newData.sort((a, b) => {
        if (currentSortOrder === "asc") {
          return a[field].localeCompare(b[field]);
        } else if (currentSortOrder === "desc") {
          return b[field].localeCompare(a[field]);
        } else {
          // original order
          return a.id - b.id;
        }
      });

      return newData;
    });
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const calculatedRowNumber = (index) => {
    return currentPage * perPage + index + 1;
  };

  const getSortButtonIcon = (field) => {
    const currentSortOrder = sortOrder[field];
    if (currentSortOrder === "asc") {
      return <img src={SortIcon} alt="Sort" />;
    } else if (currentSortOrder === "desc") {
      return <img src={Ascending} alt="Ascending" />;
    } else {
      return <img src={Descending} alt="Descending" />;
    }
  };

  const togglePopup = (id) => {
    setPopupVisible((prevPopupVisible) => !prevPopupVisible);
    setSelectedRow(id);
  };

  const handleEdit = (id, channel) => {
    // alert(`Editing item with ID: ${id}`);
    openPopup(true);
    setSelectedUserId(id);
    // setShowClientSection(true);
    // convert channel to lower case string and pass into setActiveForm
    // setActiveForm(channel.toLowerCase());
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this item (id: ${id})? This action cannot be undone.`
    );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Send a DELETE request to the delete endpoint
    axios
      .post(`https://digitalxapi.cncitsolutions.in/api/users/${id}/delete`, {
        isDeleted: "1",
      })
      .then(({ data }) => {
        if (data.status == true) {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
          window.location.reload(false);
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      })
      .catch(({ data }) => {
        if (data.status === 422) {
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      });
  };

  const handleDisable = (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to disable this item (id: ${id})? This action can only be undone by an ADMIN.`
    );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Send a DELETE request to the delete endpoint
    axios
      .post(`https://digitalxapi.cncitsolutions.in/api/users/${id}/disable`, {
        isActive: "1",
      })
      .then(({ data }) => {
        if (data.status == true) {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
          window.location.reload(false);
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      })
      .catch(({ data }) => {
        if (data.status === 422) {
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      });
  };

  const handleEnable = (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to enable this item (id: ${id})?`
    );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Send a DELETE request to the delete endpoint
    axios
      .post(`https://digitalxapi.cncitsolutions.in/api/users/${id}/enable`, {
        isActive: "0",
      })
      .then(({ data }) => {
        if (data.status == true) {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
          window.location.reload(false);
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      })
      .catch(({ data }) => {
        if (data.status === 422) {
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      });
  };

  const handleClone = (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to clone this item (id: ${id})?`
    );

    if (!confirmDelete) {
      return; // User canceled the delete action
    }

    // Fetch the data of the selected row
    axios
      .post(`https://digitalxapi.cncitsolutions.in/api/users/${id}/clone`)
      .then(({ data }) => {
        if (data) {
          // Create a new row with the same data
          const clonedData = {
            ...data,
            created_at: new Date(),
            //id: null, // Set id to null or generate a new unique id if needed
            // You may also need to modify other fields if necessary
          };

          // Send a POST request to add the cloned row to the database
          axios
            .get("https://digitalxapi.cncitsolutions.in/api/users", clonedData)
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire({
                  icon: "success",
                  text: "Row cloned and added successfully.",
                });
                window.location.reload(false);
              } else {
                Swal.fire({
                  icon: "error",
                  text: "Error cloning row 11.",
                });
              }
            })
            .catch(({ data }) => {
              if (data.status == 422) {
              } else {
                Swal.fire({
                  icon: "error",
                  text: "Error cloning row 22.",
                });
              }
            });
        } else {
          Swal.fire({
            icon: "error",
            text: "Selected row not found.",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching row data:", error);
      });
  };

  const openPopup = () => {
    setIsPopupVisible(true); // Function to open the popup
  };

  const closePopup = () => {
    setSelectedUserId("");
    setIsPopupVisible(false); // Function to close the popup
  };

  return (
    <>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>
                No.
                {/* <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              /> */}
              </th>
              <th>
                Name
                <button onClick={() => handleSort("name")}>
                  {getSortButtonIcon("name")}
                </button>
              </th>
              <th>
                Product Type
                <button onClick={() => handleSort("email")}>
                  {getSortButtonIcon("email")}
                </button>
              </th>
              <th>
                Role
                <button onClick={() => handleSort("role")}>
                  {getSortButtonIcon("role")}
                </button>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className="some-name">
            {paginatedData.map((item, index) => (
              <tr key={item.id}>
                <td>{calculatedRowNumber(index)}</td>
                <td className="img-div">
                  <div>
                    <img
                      src={"https://digitalxapi.cncitsolutions.in//profile/" + item.profile}
                      height="60px"
                      width="60px"
                      alt=""
                    />
                  </div>
                  <div className="img-txt-div">
                    <p>{item.name}</p>
                    <p>{item.phone}</p>
                  </div>
                </td>
                <td>{item.email}</td>
                <td>{item.usertype}</td>
                <td className="td-last-child">
                  <div
                    className="more-icon"
                    style={{ position: "relative" }}
                    onClick={() => togglePopup(item.id)}
                  >
                    <img src={MoreOptions} alt="MoreOptions" />
                    {selectedRow === item.id && popupVisible && (
                      <div className="popup-content-1">
                        <ul>
                          <li
                            className="blue"
                            onClick={() => handleEdit(selectedRow)}
                          >
                            Edit
                          </li>
                          <li
                            className="blue"
                            onClick={() => handleClone(selectedRow)}
                          >
                            Clone
                          </li>
                          {isActive ? (
                            <li
                              className="red"
                              onClick={() => handleDisable(selectedRow)}
                            >
                              Disable
                            </li>
                          ) : (
                            <li
                              className="green"
                              onClick={() => handleEnable(selectedRow)}
                            >
                              Enable
                            </li>
                          )}
                          <li
                            className="red"
                            onClick={() => handleDelete(selectedRow)}
                          >
                            Delete
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <ReactPaginate
            previousLabel="&lt;"
            nextLabel="&gt;"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination-container"
            activeClassName="active"
          />
          <div>
            <span>Output by: </span>
            <select
              value={perPage}
              onChange={(e) => setPerPage(parseInt(e.target.value, 10))}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
      </div>
      {/* <FilterAddContent isMoreButtonVisible={isMoreButtonVisible} /> */}
      {isPopupVisible && (
        <UserPopup
          Title1="Edit User"
          selectedUserId={selectedUserId}
          onClose={closePopup}
        />
      )}
    </>
  );
};

export default UserTable2;
