import React from "react";

import BlueTick from "../../icons/blue tick.svg";
import ArrowUp from "../../icons/ArrowUp.svg";

import "./FinancialLayout.css";

const FinancialLayout = () => {
  return (
    <>
      <div className="financial-wrapper">
        <div className="head-financial">
          <label>Financial Layout</label>
          <div>
            <img src={BlueTick} alt="" /> On Route
          </div>
        </div>
        <div className="body-financial">
          <div className="financial-body-top">
            <div>
              <label>151 Cr</label>
              <label>Total Amount</label>
            </div>
            <div>
              <label>51 Cr</label>
              <label>Recovered +7.4%</label>
            </div>
          </div>
          <div className="financial-body-middle">
            <div>
              <label>10</label>
              <label>5</label>
              <label>0</label>
            </div>
            <div>
              <label>01</label>
            </div>
            <div>
              <label>02</label>
            </div>
            <div>
              <label>03</label>
            </div>
            <div>
              <label>04</label>
            </div>
            <div>
              <label>05</label>
            </div>
            <div>
              <label>06</label>
            </div>
            <div>
              <label>07</label>
            </div>
          </div>
          <div className="financial-body-bottom">
            <div>
              <span></span>
              <label>Alotted</label>
              <div>
                <label>5 (20%) <img src={ArrowUp} alt="" /></label>
              </div>
            </div>
            <div>
              <span></span>
              <label>Collected</label>
              <div>
                <label>5 (20%) <img src={ArrowUp} alt="" /></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialLayout;
