import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";

import "./ClientSelect.css"; //main css
import "./WhatsAppForm.css"; //only whatsApp css

import Save from "../../icons/ConentManagement/save-02.svg";
import Cancel from "../../icons/ConentManagement/x-01.svg";
import homeIcon from "../../icons/icons8-home.svg";

import WASimple from "./WA/WASimple";
import WABulk from "./WA/WABulk";

const WhatsAppForm = ({
  selectedUserId,
  handleCancel,
  clientFormData,
  setShowClientSection,
}) => {
  const {
    clientName,
    productType,
    channel,
    contentName,
    contentDescription,
    setClientName,
    setProductType,
    setContentName,
    setChannel,
    setContentDescription,
  } = clientFormData;

  const url = window.$APIURL;

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, dirtyFields },
  } = useForm();

  const [showSampleForm, setShowSampleForm] = useState(true);

  const handleShowSampleForm = () => {
    setShowSampleForm(true);
  };

  const handleShowBulkForm = () => {
    setShowSampleForm(false);
  };

  const handleSimpleSubmit = (formdata) => {
    // Your form submission logic for simple form (WASimple) goes here
    alert("Simple Form Data: " + JSON.stringify(formdata));
    console.log("Simple Form Data:", formdata);

    var myform = document.getElementById("WhatsAppMainFrom");
    var fd = new FormData(myform);

    // var imagefile = document.querySelector("#simpleImgFileContent");
    // fd.append("simpleImgFileContent", imagefile.files[0]);

    axios
      .post(url + "api/contentManagement/WhatsAppSimpleCM", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        if (data.status === true) {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
        handleCancel(); //commnent if you don't need to close form after submitting.
        setChannel("");
        // window.location.reload(false);
      })
      .catch(({ data }) => {
        if (data.status === 422) {
          // Handle validation errors
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      });
  };

  const handleBulkSubmit = (formdata) => {
    // Your form submission logic for bulk form (WABulk) goes here
    alert("Bulk Form Data: " + JSON.stringify(formdata));
    console.log("Bulk Form Data:", formdata);
    var myform = document.getElementById("WhatsAppMainFrom");
    var fd = new FormData(myform);

    axios
      .post(url + "api/contentManagement/WhatsAppBulkCM", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        if (data.status === true) {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
        handleCancel(); //commnent if you don't need to close form after submitting.
        // window.location.reload(false);
      })
      .catch(({ data }) => {
        if (data.status === 422) {
          // Handle validation errors
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
      });
  };

  const onSubmit = (formdata) => {
    if (showSampleForm) {
      handleSimpleSubmit(formdata);
    } else {
      handleBulkSubmit(formdata);
    }
    // localStorage.removeItem("textSimpleFormData");
    // localStorage.removeItem("imageSimpleFormData");
    // localStorage.removeItem("documentSimpleFormData");
    // localStorage.removeItem("videoSimpleComponentData");
    // localStorage.removeItem("locationSimpleComponentData");
  };

  return (
    <>
      <form
        className="addClient-container new"
        id="WhatsAppMainFrom"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div style={{ display: "none" }}>
          <input type="text" name="clientNameFULL1" value={clientName} />
          <input type="text" name="productTypeFULL1" value={productType} />
          <input
            type="text"
            name="channelFULL1"
            value={channel.toUpperCase()}
          />
          <input type="text" name="contentNameFULL1" value={contentName} />
          <input
            type="text"
            name="contentDescriptionFULL1"
            value={contentDescription}
          />
        </div>
        <div className="addClient-wrapper option-client-wrapper">
          <div className="addClient-top">
            <div>
              <button
                type="button"
                onClick={handleShowSampleForm}
                className={showSampleForm ? "activeBorder" : " "}
              >
                Whats App
              </button>
              {/* <button
              type="button"
              onClick={handleShowBulkForm}
              className={!showSampleForm ? "activeBorder" : " "}
            >
              Bulk
            </button> */}
            </div>
            <section className="client-btns">
              <button
                className="client-btn save-close-btn"
                type="submit"
                form="WhatsAppMainFrom"
              >
                <img src={Save} alt="" /> Save & Close
              </button>
              <button
                className="client-btn cancel-btn"
                onClick={handleCancel}
                type="reset"
              >
                <img src={Cancel} alt="" /> Cancel
              </button>
            </section>
          </div>
        </div>
        {showSampleForm ? (
          <WASimple
            register={register}
            errors={errors}
            setValue={setValue}
            watch={watch}
            selectedUserId={selectedUserId}
            setClientName={setClientName}
            setProductType={setProductType}
            setContentName={setContentName}
            setChannel={setChannel}
            setContentDescription={setContentDescription}
          />
        ) : (
          <WABulk
            register={register}
            errors={errors}
            dirtyFields={dirtyFields}
            setValue={setValue}
            watch={watch}
            clientFormData={clientFormData}
          />
        )}
      </form>
      <button
        onClick={() => setShowClientSection(false)}
        className="client-btn-home-btn"
      >
        <img className="home-icon1" src={homeIcon} alt="" />
      </button>
    </>
  );
};

export default WhatsAppForm;
