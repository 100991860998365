import React, { useEffect, useState } from "react";

import "./ClientSelect.css";

import SMSForm from "./SMSForm";
import IVRForm from "./IVRForm";
import EmailForm from "./EmailForm.js";
import WhatsAppForm from "./WhatsAppForm.js";

import homeIcon from "../../icons/icons8-home.svg";

const ClientSelect = ({
  activeForm,
  setActiveForm,
  selectedUserId,
  setShowClientSection,
  setSelectedUserId,
}) => {
  const [clientName, setClientName] = useState("");
  const [productType, setProductType] = useState("");
  const [channel, setChannel] = useState("");
  const [contentName, setContentName] = useState("");
  const [contentDescription, setContentDescription] = useState("");

  const clientFormData = {
    clientName,
    productType,
    channel,
    contentName,
    contentDescription,
    setClientName,
    setProductType,
    setChannel,
    setContentName,
    setContentDescription,
  };

  // useEffect(() => {
  //   const savedData = localStorage.getItem("clientFormData");
  //   if (savedData) {
  //     const parsedData = JSON.parse(savedData);
  //     setClientName(parsedData.clientName);
  //     setProductType(parsedData.productType);
  //     setContentName(parsedData.contentName);
  //     setContentDescription(parsedData.contentDescription);
  //   }
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem("clientFormData", JSON.stringify(clientFormData));
  // }, [clientFormData]);

  const handleClientNameChange = (e) => {
    setClientName(e.target.value);
  };

  const handleChangeProductType = (e) => {
    setProductType(e.target.value);
  };

  const handleChannelChange = (e) => {
    setChannel(e.target.value);
    setActiveForm(e.target.value);
  };

  const handleContentNameChange = (e) => {
    setContentName(e.target.value);
  };

  const handleContentDescriptionChange = (e) => {
    setContentDescription(e.target.value);
  };

  const handleCancel = () => {
    setSelectedUserId("");
    setActiveForm(null);
    setChannel("");
    //comment if you dont need to clear form after cancel.
    setClientName("");
    setProductType("");
    setChannel("");
    setContentName("");
    setContentDescription("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  const handleHomeBTNClick = () => {
    setSelectedUserId("");
    setShowClientSection(false);
    setActiveForm(null);
    setChannel("");
    //comment if you dont need to clear form after cancel.
    setClientName("");
    setProductType("");
    setChannel("");
    setContentName("");
    setContentDescription("");
    setShowClientSection(false);
  };

  const renderForm = () => {
    switch (activeForm) {
      case "SMS":
        case "sms":
        return (
          <SMSForm
            handleCancel={handleCancel}
            clientFormData={clientFormData}
            selectedUserId={selectedUserId}
            setShowClientSection={setShowClientSection}
          />
        );
      case "IVR":
        case "ivr":
        return (
          <IVRForm
            handleCancel={handleCancel}
            clientFormData={clientFormData}
            selectedUserId={selectedUserId}
            setShowClientSection={setShowClientSection}
          />
        );
      case "Email":
        case "email":
        return (
          <EmailForm
            handleCancel={handleCancel}
            clientFormData={clientFormData}
            selectedUserId={selectedUserId}
            setShowClientSection={setShowClientSection}
          />
        );
      case "WhatsApp":
      case "WHATSAPP":
      case "whatsapp":
      case "Whatsapp":
        return (
          <WhatsAppForm
            handleCancel={handleCancel}
            clientFormData={clientFormData}
            selectedUserId={selectedUserId}
            setShowClientSection={setShowClientSection}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="client-full-form">
      <form onSubmit={handleSubmit} id="clientForm1">
        <div className="addClient-container">
          <div className="addClient-wrapper">
            <div className="addClient-left">
              <div className="addClient-field">
                <label>Client Name</label>
                <input
                  type="text"
                  value={clientName}
                  onChange={handleClientNameChange}
                />
              </div>
              <div className="addClient-field">
                <label>CHANNEL</label>
                <select value={channel} onChange={handleChannelChange}>
                  <option value="">Select Channel</option>
                  <option value="SMS">SMS</option>
                  <option value="IVR">IVR</option>
                  <option value="Email">Email</option>
                  <option value="WhatsApp">WhatsApp</option>
                </select>
              </div>
              <div className="addClient-field">
                <label>CONTENT NAME</label>
                <input
                  type="text"
                  value={contentName}
                  onChange={handleContentNameChange}
                />
              </div>
            </div>
            <div className="addClient-right">
              <div className="addClient-field">
                <label>CONTENT DESCRIPTION</label>
                <textarea
                  placeholder="Content description"
                  rows="4"
                  value={contentDescription}
                  onChange={handleContentDescriptionChange}
                ></textarea>
              </div>
              <div className="addClient-field">
                <label>Product Type</label>
                <select
                  id="clientProductType"
                  name="clientProductType"
                  value={productType}
                  onChange={handleChangeProductType}
                >
                  <option value="">Select Product Type</option>
                  <option value="type 1">type 1</option>
                  <option value="type 2">type 2</option>
                  <option value="type 3">type 3</option>
                  <option value="type 4">type 4</option>
                  <option value="type 5">type 5</option>
                  <option value="type 6">type 6</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </form>
      {activeForm && renderForm()}
      <button
        onClick={handleHomeBTNClick}
        className="client-btn-home-btn"
      >
        <img className="home-icon1" src={homeIcon} alt="" />
      </button>
    </div>
  );
};

export default ClientSelect;
