import React, { useState } from "react";

import "./SearchFilter.css";

import PlusIcon from "../icons/plus-01.svg";
import FilterMenu from "../icons/filter-menu.svg";
import SearchIcon from "../icons/search-02.svg";

const SearchFilter = ({
  filterPlaceholder,
  newButtonText,

  searchKeyword,
  setSearchKeyword,

  setShowAddNew,
  setShowAddNewCampain,

  segmentation, //true or false for segmentation add new form
  campaining, //true or false for campaining add new form
}) => {
  const handleFilterOptionChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handleShowAddNew = () => {
    if (segmentation === true) {
      setShowAddNew(true);
    } else if (campaining === true) {
      setShowAddNewCampain(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <form className="FilterFormElement" onSubmit={handleSubmit}>
        <div className="contain-box">
          <img className="search-icon" src={SearchIcon} alt="search" />
          <input
            className="filterElement"
            type="text"
            placeholder={filterPlaceholder}
            value={searchKeyword}
            onChange={handleFilterOptionChange}
          />
          <img
            className="icon-all filter-icon-2"
            src={FilterMenu}
            alt="search"
          />
        </div>
        <button
          onClick={handleShowAddNew}
          className="FilterNewBTN"
          type="button"
        >
          <img className="icon-all" src={PlusIcon} alt="plus icon" />
          {newButtonText}
        </button>
      </form>
    </>
  );
};

export default SearchFilter;
