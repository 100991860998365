import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import CloseIcon from "../icons/X-02.svg";
import UploadIcon from "../icons/UploadIcon.svg";
import DeleteIcon from "../icons/trash-02.svg";
import MultiSelectComponent from "./MultiSelectComponent";
import Papa from "papaparse";

import "./FiPopup.css";
const allowedExtensions = ["csv"];

const CSVPopup = ({ onClose, Title1, fi_id }) => {
  const url = window.$APIURL;
  const [showAdditionalFields, setShowAdditionalFields] = useState(false); // State to track checkbox selection
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const checkboxValue = watch("channels");

  const onSubmit = (formdata) => {
    // alert(data);
    //console.log(formdata);
    var myform = document.getElementById("addfiForm");
    var fd = new FormData(myform);
    var imagefile = document.querySelector("#csv_file");
    fd.append("profile", imagefile.files[0]);

    axios
      .post(url + "api/submit-csv", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        if (data.status == true) {
          Swal.fire({
            icon: "success",
            text: data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
          });
        }
        // onClose();
        // window.location.reload(false);
      })
      .catch(({ data }) => {
        if (data.status === 422) {
        } else {
          Swal.fire({
            text: data.message,
            icon: "error",
          });
        }
      });
  };
  const column_list = useState([]);
  // This state will store the parsed data
  const [data, setData] = useState([]);
  const [collist, setFis] = useState([]);
  // It state will contain the error when
  // correct file extension is not used
  const [error, setError] = useState("");

  // It will store the file uploaded by the user
  const [file, setFile] = useState("");

  // This function will be called when
  // the file input changes
  const handleFileChange = (e) => {
    setError("");

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }

      // If input type is correct set the state
      setFile(inputFile);
    }
  };
  const handleParse = () => {
    axios.get("https://digitalxapi.cncitsolutions.in/api/get_table_col").then(({ data }) => {
      const column_list = Object.values(data.data);
      setFis(column_list);
    });

    // If user clicks the parse button without
    // a file we show a error
    if (!file) return setError("Enter a valid file");

    // Initialize a reader which allows user
    // to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      const columns = Object.keys(parsedData[0]);
      setData(columns);
    };
    reader.readAsText(file);
  };
  return (
    <div className="popup">
      <div className="popup-content">
        <div className="popup-header">
          <img
            onClick={onClose}
            className="close-icon"
            src={CloseIcon}
            alt="x"
          />
          <h2>{Title1}</h2>
        </div>
        <form id="addfiForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="popup-body">
            <div className="left-body">
              <div className="body-element">
                <label>File Name</label>
                <input type="hidden" name="fi_id" value={fi_id} />
                <input type="text" name="filename" />
                {errors.name && (
                  <span className="error">{errors.name.message}</span>
                )}
              </div>
              <div className="body-element">
                <label>CSV File</label>
                <div>
                  <input
                    type="file"
                    name="csv_file"
                    id="csv_file"
                    onChange={handleFileChange}
                    placeholder="File Name"
                  />
                  <div>
                    <img src={UploadIcon} alt="U" />
                    <input type="file" {...register("photo")} />
                  </div>
                  <img src={DeleteIcon} alt="D" />
                </div>
              </div>
             
              <div style={{ marginTop: "3rem" }}>{error ? error : ""}</div>

              <div Style="text-align:left">
                {data.map((col, idx) => (
                  <div Style="width: 100%;display: -webkit-inline-box">
                    <div Style="width: 49%; display:block">
                      <label>{col}</label>
                      <input type="hidden" value={idx} name="csv_head" />
                    </div>
                    <div Style="width: 49%; display:block">
                      <select name="product_type">
                        {collist.map((dbcol, dbidx) => (
                          <option value={dbidx}>{dbcol}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                ))}
              </div>

              <div className="body-element">
                <label>Product Type</label>
                <select name="product_type">
                  <option value="Property Loans">Property Loans</option>
                  <option value="Secured Loans">Secured Loans</option>
                  <option value="Car Loans">Car Loans</option>
                  <option value="BANKS">BANKS</option>
                  <option value="2 Wheeler Loans">2 Wheeler Loans</option>
                  <option value="Business Loans">Business Loans</option>
                  <option value="Wedding Loans">Wedding Loans</option>
                </select>
              </div>
              <div className="body-element">
                <label>Bucket Type</label>
                <select name="bucket_type">
                  <option value="Bucket 1">Bucket 1</option>
                  <option value="Bucket 2">Bucket 2</option>
                  <option value="Bucket 3">Bucket 3</option>
                </select>
              </div>
              <div className="body-element">
                <label htmlFor="Description">Description</label>
                <textarea
                  cols="30"
                  name="description"
                  rows="10"
                  placeholder="Type Something"
                ></textarea>
              </div>
              <div className="body-element">
                <label htmlFor="Description">Start Date</label>
                <input type="date" name="start_date" placeholder="Start Date" />
              </div>
              <div className="body-element">
                <label htmlFor="Description">End Date</label>
                <input type="date" name="end_date" placeholder="End Date" />
              </div>
            </div>
          </div>
          <div className="popup-footer">
            <button type="button" className="cancel-button">
              Cancel
            </button>
            <button type="submit" className="save-button">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CSVPopup;
