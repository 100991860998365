import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../../ClientSelect.css"; //main css
import "../WASimple.css"; //remaining css 1

import UploadIcon from "../../../../icons/UploadIcon.svg";
import DeleteIcon from "../../../../icons/trash-02.svg";

const DocumentSimpleComponent = ({
  textNumberDocSimple,
  selectedURLDocsSimple,
  fileUploadDocsSimple,
  extentionsDocsSimple,
  textTemplateDocSimple,
  register,
  errors,
  setValue,
  watch,
  componentClassName,
}) => {
  const [sendOption, setSendOption] = useState("link");

  const handleNumberTextChange = (event) => {
    setValue("textNumberDocSimple", event.target.value);
  };
  const handleTemplateChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= maxCharacterCount) {
      setValue("textTemplateDocSimple", event.target.value);
    } else {
      setValue("textTemplateDocSimple", inputText.slice(0, maxCharacterCount));
    }
  };

  const handleFileChange = (event) => {
    setValue("fileUploadDocsSimple", event.target.files[0]);
  };

  const handleSelectedURLdocsSimple = (e) => {
    setValue("selectedURLDocsSimple", e.target.value);
  };

  const handleExtentionChange = (event) => {
    setValue("extentionsDocsSimple", event.target.value);
  };

  const maxCharacterCount = 1024;
  const characterCount = textTemplateDocSimple.length;
  const charactersLeft = maxCharacterCount - characterCount;

  return (
    <div className={`addClient-bottom component ${componentClassName}`}>
      <>
        <div className="addClient-field">
          <label>
            Enter Number<span className="star-req">*</span>
          </label>
          <textarea
            className="long-number"
            placeholder=" Enter Numbers separated by commas"
            cols="20"
            rows="10"
            id="textNumberDocSimple"
            name="textNumberDocSimple"
            value={textNumberDocSimple}
            onChange={handleNumberTextChange}
            {...register("textNumberDocSimple", { required: true })}
          ></textarea>
          {errors.textNumberDocSimple && (
            <span className="error-message">
              Enter Number Seperated by Commas
            </span>
          )}
        </div>
        <div className="addClient-field">
          <label>Select Document file to be sent to user</label>
          <div className="field-row">
            <label className="form-check-label" htmlFor="rdoBTN1docsSimple">
              <input
                type="radio"
                className="form-check-input"
                id="rdoBTN1docsSimple"
                name="rdoBTN1docsSimple"
                value="link"
                defaultChecked
                checked={sendOption === "link"}
                onChange={() => setSendOption("link")}
              />
              Give a link
            </label>
            <label className="form-check-label" htmlFor="rdoBTN2docsSimple">
              <input
                type="radio"
                className="form-check-input"
                id="rdoBTN2docsSimple"
                name="rdoBTN2docsSimple"
                value="upload"
                checked={sendOption === "upload"}
                onChange={() => setSendOption("upload")}
              />
              Upload a file
            </label>
          </div>
        </div>

        {sendOption === "link" && (
          <div className="addClient-field">
            <label>
              Specify a link to an externally hosted document (PDF format only).
              Max size 5 MB
            </label>
            <input
              type="text"
              id="selectedURLDocsSimple"
              name="selectedURLDocsSimple"
              placeholder=" https://www.mydomain.com/img"
              onChange={handleSelectedURLdocsSimple}
              value={selectedURLDocsSimple}
              {...register("selectedURLDocsSimple", { required: true })}
            />
          </div>
        )}

        {sendOption === "upload" && (
          <div className="addClient-field">
            <label>
              Upload an image file (PNG, JPG only) from your local computer. Max
              size 5 MB
            </label>
            <div className="field-row">
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                id="fileUploadDocsSimple"
                name="fileUploadDocsSimple"
                {...register("fileUploadDocsSimple", { required: true })}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <input
                type="text"
                placeholder=" File name shown here"
                value={fileUploadDocsSimple ? fileUploadDocsSimple.name : ""}
                readOnly
              />
              <label
                htmlFor="fileUploadDocsSimple"
                className="file-upload-label"
              >
                <img className="icon-width" src={UploadIcon} alt="^ Upload" />
              </label>
              {fileUploadDocsSimple && (
                <img
                  className="icon-width delete-btn"
                  src={DeleteIcon}
                  alt="* Delete"
                  onClick={() => setValue("fileUploadDocsSimple", null)}
                />
              )}
            </div>
            <label className="lighter-note">
              Note: WhatsApp may compress large media files down to 5 MB during
              delivery.
            </label>
          </div>
        )}

        <div className="addClient-field">
          <label>
            Filename<span className="star-req">*</span> (Only the following
            special characters are permitted: hyphen
            <span className="star-req">-</span>,underscore
            <span className="star-req">_</span> & single inverted commas
            <span className="star-req">'</span>. Spaces will be replaced by an
            underscore)
          </label>
          <input
            type="text"
            id="extentionsDocsSimple"
            name="extentionsDocsSimple"
            value={extentionsDocsSimple}
            {...register("extentionsDocsSimple", { required: true })}
            onChange={handleExtentionChange}
            placeholder=" Name must contain .pdf extension"
          />
        </div>

        <div className="addClient-field">
          <label>
            Select a template for caption text to be displayed with document
          </label>
          <textarea
            id="textTemplateDocSimple"
            name="textTemplateDocSimple"
            value={textTemplateDocSimple}
            {...register("textTemplateDocSimple", { required: true })}
            onChange={handleTemplateChange}
            cols="30"
            rows="10"
            placeholder=" Click to change Template"
          ></textarea>
          <div className="template-status">
            <label>
              Characters used: {characterCount}, Left: {charactersLeft}
            </label>
            <Link>Use Template</Link>
          </div>
        </div>
      </>
    </div>
  );
};

export default DocumentSimpleComponent;
