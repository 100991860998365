import React, { useState } from "react";
import { Link } from "react-router-dom";

import UserPopup from "./UserPopup";

import "./SearchSwitch.css";

import PlusIcon from "../icons/plus-01.svg";
import FilterMenu from "../icons/filter-menu.svg";
import SearchIcon from "../icons/search-02.svg";

const SearchFilter = ({
  setSearchKeyword,
  isActive,
  setIsActive,
  fetchProducts,
}) => {
  const [filterOption, setFilterOption] = useState("");

  // Handle filter option change
  const handleFilterOptionChange = (event) => {
    setFilterOption(event.target.value);
    setSearchKeyword(event.target.value);
  };

  // Toggle active/inactive
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <div className="switchElement">
        <button className={isActive ? "active" : ""} onClick={toggleActive}>
          Active
        </button>
        <button className={!isActive ? "active" : ""} onClick={toggleActive}>
          Inactive
        </button>
      </div>
      <div className="UsersearchElement">
        <img className="icon-all" src={SearchIcon} alt="search" />
        <input
          className="filterElement"
          type="text"
          placeholder="Search User"
          value={filterOption}
          onChange={handleFilterOptionChange}
        />
        <img className="icon-all" src={FilterMenu} alt="search" />
      </div>

      <Link className="FilterNewBTN" type="submit" onClick={openPopup}>
        <img className="icon-all" src={PlusIcon} alt="plus icon" /> Add User
      </Link>
      {isPopupOpen && <UserPopup Title1="Add User" onClose={closePopup} fetchProducts={fetchProducts} />}
    </>
  );
};

export default SearchFilter;
