import React, { useState } from "react";
import './FiPopup.css'

function MultiSelectComponent() {
  const [showOtherType, setShowOtherType] = useState(false);
  const [showInput1, setShowInput1] = useState(false);
  const [showInput2, setShowInput2] = useState(false);
  const [showInput3, setShowInput3] = useState(false);
  const [sms_vendor, ivr_vendor, whatsapp_vendor] = useState("");
  const [sms_api, sms_id, sms_password] = useState("");
  const [ivr_api, ivr_id, ivr_password] = useState("");
  const [whatsapp_api, whatsapp_id, whatsapp_password] = useState("");
  const [handleSelect1Change,input1Value, setInput1Value] = useState("");
  const [input2Value, setInput2Value] = useState("");
  const [input3Value, setInput3Value] = useState("");
  const [select2Value, setSelect2Value] = useState("");
  const [input4Value, setInput4Value] = useState("");
  const [input5Value, setInput5Value] = useState("");
  const [input6Value, setInput6Value] = useState("");
  const [select3Value, setSelect3Value] = useState("");
  const [input7Value, setInput7Value] = useState("");
  const [input8Value, setInput8Value] = useState("");
  const [input9Value, setInput9Value] = useState("");

  const handleDbTypeClick = () => {
    setShowOtherType(true);
  };

  //to toggle the upper code for popup select element
  // const handleDbTypeClick = () => {
  //   setShowOtherType((prevState) => !prevState);
  // };

  const handleCheckbox1Change = () => {
    setShowInput1(!showInput1);
  };

  const handleCheckbox2Change = () => {
    setShowInput2(!showInput2);
  };

  const handleCheckbox3Change = () => {
    setShowInput3(!showInput3);
  };

  const handleInput1Change = (e) => {
    setInput1Value(e.target.value);
  };

  const handleInput2Change = (e) => {
    setInput2Value(e.target.value);
  };

  const handleInput3Change = (e) => {
    setInput3Value(e.target.value);
  };

  const handleSelect2Change = (e) => {
    setSelect2Value(e.target.value);
  };

  const handleInput4Change = (e) => {
    setInput4Value(e.target.value);
  };

  const handleInput5Change = (e) => {
    setInput5Value(e.target.value);
  };

  const handleInput6Change = (e) => {
    setInput6Value(e.target.value);
  };

  const handleSelect3Change = (e) => {
    setSelect3Value(e.target.value);
  };

  const handleInput7Change = (e) => {
    setInput7Value(e.target.value);
  };

  const handleInput8Change = (e) => {
    setInput8Value(e.target.value);
  };

  const handleInput9Change = (e) => {
    setInput9Value(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission or further validation here
  };

  return (
    <div>
      <select name="dbType" id="dbType" onClick={handleDbTypeClick}>
        <option>Select</option>
      </select>

      {showOtherType && (
        <div id="otherType" className="channelSelector">
          <div>
            <input
              type="checkbox"
              id="checkbox1"
              name="channel[]"
              value="sms"
              onChange={handleCheckbox1Change}
            />
            <label htmlFor="checkbox1">SMS</label>
          </div>
          {showInput1 && (
            <div className="inputSectionAll">
              <select name="sms_vendor" value={sms_vendor} onChange={handleSelect1Change}>
                <option value="UBONA">UBONA</option>
                <option value="UBONA">UBONA</option>
                <option value="UBONA">UBONA</option>
              </select>
              <input type="text" id="input1" value={sms_api} onChange={handleInput1Change} placeholder="API KEYS" />
              <div>
                <input type="text" id="input2" value={sms_id} onChange={handleInput2Change} placeholder="ID" />
                <input type="text" id="input3" value={sms_password} onChange={handleInput3Change} placeholder="Password" />
              </div>
            </div>
          )}

          <div>
            <input
              type="checkbox"
              id="checkbox2"
              name="channel[]"
              value="ivr"
              onChange={handleCheckbox2Change}
            />
            <label htmlFor="checkbox2">IVR</label>
          </div>
          {showInput2 && (
            <div className="inputSectionAll">
              <select name="ivr_vendor" value={ivr_vendor} onChange={handleSelect2Change}>
                <option value="UBONA">UBONA</option>
                <option value="UBONA">UBONA</option>
                <option value="UBONA">UBONA</option>
              </select>
              <input type="text" id="input4" value={ivr_api} onChange={handleInput4Change} placeholder="API KEYS" />
              <div>
                <input type="text" id="input5" value={ivr_id} onChange={handleInput5Change} placeholder="ID" />
                <input type="text" id="input6" value={ivr_password} onChange={handleInput6Change} placeholder="Password" />
              </div>
            </div>
          )}

          <div>
            <input
              type="checkbox"
              id="checkbox3"
              name="channel[]"
              value="whatsapp"
              onChange={handleCheckbox3Change}
            />
            <label htmlFor="checkbox3">WhatsApp</label>
          </div>
          {showInput3 && (
            <div className="inputSectionAll">
              <select name="select3" value={whatsapp_vendor} onChange={handleSelect3Change}>
                <option value="UBONA">UBONA</option>
                <option value="UBONA">UBONA</option>
                <option value="UBONA">UBONA</option>
              </select>
              <input type="text" id="input7" value={whatsapp_api} onChange={handleInput7Change} placeholder="API KEYS" />
              <div>
                <input type="text" id="input8" value={whatsapp_id} onChange={handleInput8Change} placeholder="ID" />
                <input type="text" id="input9" value={whatsapp_password} onChange={handleInput9Change} placeholder="Password" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default MultiSelectComponent;
