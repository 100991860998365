import React from 'react';

const GrowingCircles = ({ percentages }) => {
  const colors = ['#B4B4FD', '#FFCD5F', '#6ECDFD', '#88C50E'];
  const radii = [48, 40, 32, 24];
  const rotations = [-240, -210, -180, -150];

  const averagePercentage =
    percentages.reduce((sum, percentage) => sum + percentage, 0) / percentages.length;

  return (
    <svg width="100" height="100">
      {percentages.map((percentage, index) => (
        <Circle
          key={index}
          radius={radii[index]}
          percentage={percentage}
          color={colors[index]}
          rotation={rotations[index]}
        />
      ))}
      <text x="50" y="55" textAnchor="middle" fontSize="15">
        {Math.round(averagePercentage)}%
      </text>
    </svg>
  );
};

const Circle = ({ radius, percentage, color, rotation }) => {
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = `${circumference} ${circumference}`;
  const strokeDashoffset = (percentage / 100) * circumference - circumference;

  return (
    <circle
      cx="50"
      cy="50"
      r={radius}
      fill="none"
      stroke={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeDasharray={strokeDasharray}
      strokeDashoffset={strokeDashoffset}
      transform={`rotate(${rotation}, 50, 50)`}
    />
  );
};


export default GrowingCircles;
