import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import "./AccountSetting.css";
import AddGallary from "../../icons/Settings/gallery-add.svg";

const initialData = {
  selectedFile: null,
  phoneBio: "",
  phoneExtension: "+91",
  phoneNumber: "",
  username: "",
  email:"",
  fullName: "",
};

const AccountSetting = () => {
  const url = window.$APIURL;
  
  const [formData, setFormData] = useState(initialData);
  const [allData, setAllData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState("");

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        url + `api/users/${selectedUserId}`
      );
      setFormData({
        selectedFile: response.data.profile,
        phoneBio: response.data.bio,
        phoneExtension: response.data.phone_ext,
        phoneNumber: response.data.phone,
        username: response.data.username,
        email: response.data.email,
        fullName: response.data.name,
      });
      setAllData(response.data);
      setUserData(
        response.data
          .filter((item) => item.isDisabled === 0)
          .filter((item) => item.isDeleted === 0)
      );
      setError(null); // Clear any previous error
    } catch (err) {
      if (err.response && err.response.status === 500) {
        setError("Internal Server Error: Something went wrong.");
      } else {
        // Handle other errors if needed
        setError("An error occurred while fetching data.");
      }
    }
  };
  
  var token =
    "Bearer " + localStorage.getItem("tokss").replace(/^"(.+(?="$))"$/, "$1");
  axios
    .post(url + "api/testtoken", "", {
      headers: {
        Authorization: token,
      },
    })
    .then(({ data }) => {
      if (data.status == true) {
        setSelectedUserId(data.data.id);
        window.$USERDATA = data.data; //global variable
      } else {
        Swal.fire({
          icon: "error",
          text: data.message,
        });
      }

      // window.location.reload(false);
    })
    .catch(({ data }) => {
      if (data.status === 422) {
      } else {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      }
    });

  useEffect(() => {
    fetchProducts();
  }, [setSelectedUserId]);

  const fileInputRef = useRef(null);

  const handleFileChange = ({ target }) => {
    if (target.files && target.files[0]) {
      setFormData({
        ...formData,
        selectedFile: URL.createObjectURL(target.files[0]),
      });
    }
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleReset = () => {
    setFormData(initialData); // Reset the form data to its initial values
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (selectedUserId !== "") {
      var myform = document.getElementById("accoutnDetailsForm");
      var fd = new FormData(myform);

      axios
        .post(url + `api/user/${selectedUserId}/update`, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          if (data.status == true) {
            fetchProducts();
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    } else {
      var myform = document.getElementById("accoutnDetailsForm");
      var fd = new FormData(myform);

      axios
        .post(url + "api/campaining/Form10", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          if (data.status == true) {
            fetchProducts();
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    }
    fetchProducts();
  };

  return (
    <>
      <form
        id="accoutnDetailsForm"
        className="settings-form"
        onSubmit={onSubmit}
      >
        <p>Your Profile Picture</p>
        <div className="upload-photo" onClick={handleDivClick}>
          {formData.selectedFile ? (
            <img src={formData.selectedFile} alt="" className="profile-img" />
          ) : (
            <>
              <img src={AddGallary} alt="" className="upload-img" />
              <p>Upload your photo</p>
            </>
          )}
          <input
            type="file"
            onChange={handleFileChange}
              name="profile11"
            ref={fileInputRef}
            style={{ display: "none" }}
          />
        </div>
        <span className="full-width-line"></span>
        <div className="settings-form-fields">
          <label htmlFor="full-name">
            Full name
            <input
              type="text"
              id="full-name"
              name="fullName"
              value={formData.fullName}
              placeholder="Please enter your full name"
              onChange={(e) =>
                setFormData({ ...formData, fullName: e.target.value })
              }
            />
          </label>
          <label htmlFor="email">
            Email
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              placeholder="Please enter your email"
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </label>
          <label htmlFor="username">
            Username
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              placeholder="Please enter your username"
              onChange={(e) =>
                setFormData({ ...formData, username: e.target.value })
              }
            />
          </label>
          <label htmlFor="phone-number">
            Phone number
            <div>
              <select
                value={formData.phoneExtension}
                onChange={(e) =>
                  setFormData({ ...formData, phoneExtension: e.target.value })
                }
              >
                <option value="+91">+91</option>
                <option value="+1">+1</option>
                <option value="+44">+44</option>
                {/* Add more options here */}
              </select>
              <input
                type="tel"
                id="phone-number"
              value={formData.phone}
                placeholder="Please enter your phone number"
                onChange={(e) =>
                  setFormData({ ...formData, phoneNumber: e.target.value })
                }
              />
            </div>
          </label>
          <label htmlFor="phone-bio">
            Bio
            <textarea
              id="phone-bio"
              name="phoneBio"
              value={formData.phoneBio}
              placeholder="Write your Bio here e.g your hobbies, interests ETC"
              onChange={(e) =>
                setFormData({ ...formData, phoneBio: e.target.value })
              }
            />
          </label>
        </div>
        <div className="settings-btns">
          <button
            type="submit"
            form="accoutnDetailsForm"
            className="upload-profile"
          >
            Update Profile
          </button>
          <button type="button" className="reset-profile" onClick={handleReset}>
            Reset
          </button>
        </div>
      </form>
    </>
  );
};

export default AccountSetting;
