import React from 'react';

const SearchResults = () => {
  return (
    <div>
      <h2>Search Results</h2>
      <div>Results not found!!!
It seems that none exist! If necessary, try adjusting a filter; alternatively, consider making a new one.</div>
    </div>
  );
};

export default SearchResults;
