import React from 'react'
import Navbar from '../components/NavbarComp'
import Sidebar from '../components/SidebarComp'
import FiDetailComp from '../components/FiManagement/FiDetailComp'
import { useParams } from 'react-router';

const FiDetail = () => {
  const { id } = useParams();
  return (
    <>
      <Sidebar/>
      <div>
        <Navbar/>
      </div>
      <FiDetailComp data={id}/>
    </>
    )
}

export default FiDetail