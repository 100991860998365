import React from 'react';

import GrowingCircles from '../../CommonComponents/GrowingCircles';

import tickBlue from '../../icons/Dashboard/tick blue.svg';
import tickGreen from '../../icons/Dashboard/tick green.svg';
import tickYellow from '../../icons/Dashboard/tick yellow.svg';
import tickViolet from '../../icons/Dashboard/tick violet.svg';
import elipseBlue from '../../icons/Dashboard/Ellipse blue.svg';
import elipseGreen from '../../icons/Dashboard/Ellipse green.svg';
import elipseYellow from '../../icons/Dashboard/Ellipse yellow.svg';
import elipseViolet from '../../icons/Dashboard/Ellipse violet.svg';

import './DataInteraction.css';

const blocks = [
  {
    label: 'Attempt',
    records: '3,400 Records',
    cr: '161 Cr',
    percentages: [100, 100, 100, 100],
    labels: [
      { image: elipseBlue, text: '19 K' },
      { image: elipseGreen, text: '15 K' },
      { image: elipseYellow, text: '10 K' },
      { image: elipseViolet, text: '9 K' },
    ],
  },
  {
    label: 'Responded',
    records: '3,400 Records',
    cr: '161 Cr',
    percentages: [84, 84, 84, 84],
    labels: [
      { image: elipseBlue, text: '19 K' },
      { image: elipseGreen, text: '15 K' },
      { image: elipseYellow, text: '10 K' },
      { image: elipseViolet, text: '9 K' },
    ],
  },
  {
    label: 'Reached',
    records: '3,400 Records',
    cr: '161 Cr',
    percentages: [96, 96, 96, 96],
    labels: [
      { image: elipseBlue, text: '19 K' },
      { image: elipseGreen, text: '15 K' },
      { image: elipseYellow, text: '10 K' },
      { image: elipseViolet, text: '9 K' },
    ],
  },
  {
    label: 'Reached',
    records: '3,400 Records',
    cr: '161 Cr',
    percentages: [72, 72, 72, 72],
    labels: [
      { image: elipseBlue, text: '19 K' },
      { image: elipseGreen, text: '15 K' },
      { image: elipseYellow, text: '10 K' },
      { image: elipseViolet, text: '9 K' },
    ],
  },
];

const DataInteraction = () => {
  return (
    <div className="interaction-main">
      <div className="interaction-wrapper">
        <div className="int-top">
          <label>Data Interaction</label>
          <div className="int-top-right">
            <label>
              <img src={tickBlue} alt="tickBlue" />
              IVR
            </label>
            <label>
              <img src={tickGreen} alt="tickGreen" />
              SMS
            </label>
            <label>
              <img src={tickYellow} alt="tickYellow" />
              VOICE
            </label>
            <label>
              <img src={tickViolet} alt="tickViolet" />
              MAIL
            </label>
          </div>
        </div>
        <div className="int-bottom">
          <div className="int-bottom-left">
            {blocks.map((block, index) => (
              <div className="int-block" key={index}>
                <div>
                  <label>{block.label}</label>
                  <label>{block.records}</label>
                  <label>{block.cr}</label>
                  <div className="bk">
                    {block.labels.map((label, labelIndex) => (
                      <label key={labelIndex}>
                        <img src={label.image} alt="" />
                        {label.text}
                      </label>
                    ))}
                  </div>
                </div>
                <GrowingCircles percentages={block.percentages} />
              </div>
            ))}
          </div>
          <div className="int-bottom-right">
            <div>
              <label>Resolved</label>
              <label>3,400 Records</label>
              <label>161 Cr</label>
              <div className="bk">
                {blocks[blocks.length - 1].labels.map((label, labelIndex) => (
                  <label key={labelIndex}>
                    <img src={label.image} alt="" />
                    {label.text}
                  </label>
                ))}
              </div>
            </div>
            <GrowingCircles percentages={[72, 72, 72, 72]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataInteraction;
