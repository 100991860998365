import React, { useEffect, useState } from "react";
import "./SegmentationMainComp.css";

import axios from "axios";

import SearchFilter from "../CommonComponents/SearchFilter";
import SearchResults from "../SearchResults";
import Status2 from "../CommonComponents/Status2";
import AddNew from "./AddNew";
import LoadingPopup from "../CommonComponents/LoadingPopup";

import Total from "../icons/total.svg";
import MoreOptions from "../icons/more-options.svg";
import Active from "../icons/active.svg";
import Inactive from "../icons/inactive.svg";
import Planned from "../icons/planned.svg";
import TableSegmentationNew from "./TableSegmentationNew";

const SegmentationMainComp = () => {
  const [allData, setAllData] = useState([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [segmentationData, setSegmentationData] = useState([]);

  const [searchKeyword, setSearchKeyword] = useState("");

  const [selectedUserId, setSelectedUserId] = useState("");

  useEffect(() => {
    fetchProducts();
  }, []);

  const [error, setError] = useState(null);

    const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://digitalxapi.cncitsolutions.in/api/Segmentation"
      );
      setSegmentationData(
        response.data
          .filter((item) => item.isDeleted === 0)
          .filter((item) => item.isDisabled === 0)
      );
      setAllData(response.data);
      setError(null); // Clear any previous error
    } catch (err) {
      if (err.response && err.response.status === 500) {
        setError("Internal Server Error: Something went wrong.");
      } else {
        // Handle other errors if needed
        setError("An error occurred while fetching data.");
      }
    } finally {
      setLoading(false);
    }
  };

  const segments = [
    {
      title: "Total Segments",
      count: allData.length.toString(),
      imageSrc: Total,
      moreOptionsSrc: MoreOptions,
    },
    {
      title: "Active Segments",
      count: allData
        .filter((segment) => segment.isDisabled === 0)
        .filter((segment) => segment.isDeleted === 0)
        .length.toString(),
      imageSrc: Active,
      moreOptionsSrc: MoreOptions,
    },
    {
      title: "Inactive Segments",
      count: allData
        .filter((segment) => segment.isDisabled === 1)
        .filter((segment) => segment.isDeleted === 0)
        .length.toString(),
      imageSrc: Inactive,
      moreOptionsSrc: MoreOptions,
    },
    {
      title: "Planned Segments",
      count: allData.length.toString(),
      imageSrc: Planned,
      moreOptionsSrc: MoreOptions,
    },
    // Add more segment objects as needed
  ];


  const handleCloseAddNew = (e) => {
    e.preventDefault();
    setShowAddNew(false);
    setSelectedUserId("");
  };


  return (
    <div className="mainContainer">
      <div className="mainWrapper">
        {showAddNew ? (
          <AddNew
            handleCloseAddNew={handleCloseAddNew}
            selectedUserId={selectedUserId}
          />
        ) : (
          <>
            <div className="card-container">
              <Status2 segments={segments} />
            </div>
            <div className="input-group searchFilterContainer">
              <SearchFilter
                segmentation={true}
                searchPlaceholder="Search"
                filterPlaceholder="Segment Category Filter"
                newButtonText="New"
                setShowAddNew={setShowAddNew}
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
              />
            </div>
            <div className="searchResultsContainer">
              {loading ? (
                <LoadingPopup show={true} />
              ) : (
                <>
                  {segmentationData.some((item) => {
                    for (const key in item) {
                      const value = item[key];
                      if (
                        String(value)
                          .toLowerCase()
                          .includes(searchKeyword.toLowerCase())
                      ) {
                        return true;
                      }
                    }
                    return false;
                  }) ? (
                    <TableSegmentationNew
                      segmentationData={segmentationData}
                      setSegmentationData={setSegmentationData}
                      searchKeyword={searchKeyword}
                      selectedUserId={selectedUserId}
                      setSelectedUserId={setSelectedUserId}
                      setShowAddNew={setShowAddNew}
                    />
                  ) : (
                    <SearchResults />
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SegmentationMainComp;
