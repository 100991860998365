import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// import Segmentation from "./routes/Segmentation";
// import UserManagement from "./routes/UserManagement";
// import Dashboard from "./routes/Dashboard";
// import Fi from "./routes/Fi";
// import Content from "./routes/Content";
// import Campaign from "./routes/Campaign";
// import Settings from "./routes/Settings";
// import Login from "./routes/Login";
import FiDetail from "./routes/FiDetail"; //Main Component File not directly imported in app.js

import SegmentationMainComp from "./components/Segmentaion/SegmentationMainComp";
import DashboardMainComp from "./components/Dashboard/DashboardMainComp";
import UserMainComp from "./components/UserManagement/UserMainComp";
import FiMainComp from "./components/FiManagement/FiMainComp";
import LoginScreen from "./components/LoginComp/EnterMobile";
import ContentMainComp from "./components/ContentManagement/ConteneMainComp";
import SettingsMainCompo from "./components/Settings/SettingsMainCompo";
import CampainingMainComponent from "./components/Campaign/CompainingMainCompo";

import NavbarComp from "./components/NavbarComp";
import SidebarComp from "./components/SidebarComp";

function App() {
  const location = useLocation();

  const isLoginRoute = location.pathname === "/";

  return (
    <>
    {!isLoginRoute && <NavbarComp />}

    <Routes>
    <Route path="/" element={<LoginScreen />} />
    <Route path="/Dashboard" element={<DashboardMainComp />} />
    <Route path="/Segmentation" element={<SegmentationMainComp />} />
    <Route path="/Campaign" element={<CampainingMainComponent />} />
    <Route path="/ContentManagement" element={<ContentMainComp />} />
    <Route path="/FiManagement" element={<FiMainComp />} />
    <Route path="/FiManagement/FiDetail/:id" element={<FiDetail />} />
    <Route path="/UserManagement" element={<UserMainComp />} />
    <Route path="/Settings" element={<SettingsMainCompo />} />
    <Route path="*" element={<h1>Page Not Found</h1>} />
    </Routes>

    {!isLoginRoute && <SidebarComp />}

    </>
    );
  }

  export default App;
