import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import UploadIcon from "../../../../icons/UploadIcon.svg";
import DeleteIcon from "../../../../icons/trash-02.svg";
import "../../ClientSelect.css"; // main css
import "../WASimple.css"; // remaining css 1

const DocumentBulkComponent = () => {
  const [text, setText] = useState("");
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [sendOption, setSendOption] = useState("link");
  const [mediaOption, setMediaOption] = useState("common");
  const [enableLinkTracking, setEnableLinkTracking] = useState(false);
  const [removeDuplicates, setRemoveDuplicates] = useState(false);
  const [sendInteractiveButton, setSendInteractiveButton] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem("documentBulkComponentData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setText(parsedData.text);
      setSelectedFile1(parsedData.selectedFile1);
      setSelectedFile2(parsedData.selectedFile2);
      setSendOption(parsedData.sendOption);
      setMediaOption(parsedData.mediaOption);
      setEnableLinkTracking(parsedData.enableLinkTracking);
      setRemoveDuplicates(parsedData.removeDuplicates);
      setSendInteractiveButton(parsedData.sendInteractiveButton);
    }
  }, []);

  useEffect(() => {
    const dataToStore = {
      text,
      selectedFile1,
      selectedFile2,
      sendOption,
      mediaOption,
      enableLinkTracking,
      removeDuplicates,
      sendInteractiveButton,
    };
    localStorage.setItem(
      "documentBulkComponentData",
      JSON.stringify(dataToStore)
    );
  }, [
    text,
    selectedFile1,
    selectedFile2,
    sendOption,
    mediaOption,
    enableLinkTracking,
    removeDuplicates,
    sendInteractiveButton,
  ]);

  const handleTextChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= maxCharacterCount) {
      setText(inputText);
    } else {
      setText(inputText.slice(0, maxCharacterCount));
    }
  };

  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    setSelectedFile1(file);
    saveFileToLocalstorage(file, "selectedFile1");
  };

  const handleFileChange2 = (event) => {
    const file = event.target.files[0];
    setSelectedFile2(file);
    saveFileToLocalstorage(file, "selectedFile2");
  };

  const saveFileToLocalstorage = (file, key) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      localStorage.setItem(key, reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    const storedFile1 = localStorage.getItem("selectedFile1");
    const storedFile2 = localStorage.getItem("selectedFile2");

    if (storedFile1) {
      setSelectedFile1(storedFile1);
    }
    if (storedFile2) {
      setSelectedFile2(storedFile2);
    }
  }, []);

  const handleSendOptionChange = (event) => {
    setSendOption(event.target.value);
  };

  const handleMediaOptionChange = (event) => {
    setMediaOption(event.target.value);
  };

  const handleEnableLinkTrackingChange = () => {
    setEnableLinkTracking((prevState) => !prevState);
  };

  const handleRemoveDuplicatesChange = () => {
    setRemoveDuplicates((prevState) => !prevState);
  };

  const handleSendInteractiveButtonChange = () => {
    setSendInteractiveButton((prevState) => !prevState);
  };

  const maxCharacterCount = 1024;
  const characterCount = text.length;
  const charactersLeft = maxCharacterCount - characterCount;

  return (
    <div className="addClient-bottom">
      <>
        <div className="addClient-field field-row">
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="mediaOption"
              value="common"
              checked={mediaOption === "common"}
              onChange={handleMediaOptionChange}
            />
            Common media for all recipients
          </label>
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="mediaOption"
              value="custom"
              checked={mediaOption === "custom"}
              onChange={handleMediaOptionChange}
            />
            Custom media for each recipient
          </label>
        </div>

        <div className="addClient-field">
          <label>
            Select Document file to be sent to the user (if a common file for
            all users)
          </label>
          <div className="field-row">
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name="sendOption1"
                value="link"
                checked={sendOption === "link"}
                onChange={handleSendOptionChange}
              />
              Give a link
            </label>
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name="sendOption1"
                value="upload"
                checked={sendOption === "upload"}
                onChange={handleSendOptionChange}
              />
              Upload a file
            </label>
          </div>
        </div>

        {sendOption === "link" && (
          <div className="addClient-field">
            <label>
              Specify a link to an externally hosted document (PDF format only)
              Max size 5 MB
            </label>
            <input type="text" placeholder="https://www.mydomain.com/doc.pdf" />
          </div>
        )}

        {sendOption === "upload" && (
          <div className="addClient-field">
            <label>
              Upload a document (PDF only) from your local computer. Max size 5
              MB
            </label>
            <div className="field-row">
              <input
                type="file"
                id="file-upload3"
                accept=".pdf"
                onChange={handleFileChange1}
                style={{ display: "none" }}
              />
              <input
                type="text"
                placeholder=" File name shown here"
                value={selectedFile1 ? selectedFile1.name : ""}
                readOnly
              />
              <label htmlFor="file-upload3" className="file-upload-label">
                <img className="icon-width" src={UploadIcon} alt="^ Upload" />
              </label>
              {selectedFile1 && (
                <img
                  className="icon-width delete-btn"
                  src={DeleteIcon}
                  alt="* Delete"
                  onClick={() => setSelectedFile1(null)}
                />
              )}
            </div>
            <label className="lighter-note">
              Note: WhatsApp may compress large media files down to 5 MB during
              delivery.
            </label>
          </div>
        )}

        <div className="addClient-field">
          <label>
            Select a template for the caption text to be displayed with the
            document
          </label>
          <textarea
            onChange={handleTextChange}
            value={text}
            cols="30"
            rows="10"
            placeholder="Click to change Template"
          ></textarea>
          <div className="template-status">
            <label>
              Characters used: {characterCount}, Left: {charactersLeft}
            </label>
            <Link>Use Template</Link>
          </div>
        </div>

        <div className="addClient-field field-row">
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="sendOption2"
              value="sendNow"
              checked={sendOption === "sendNow"}
              onChange={handleSendOptionChange}
            />
            Send Now
          </label>
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="sendOption2"
              value="Schedule"
              checked={sendOption === "Schedule"}
              onChange={handleSendOptionChange}
            />
            Schedule
          </label>
        </div>

        <div className="addClient-field">
          <label>
            Upload a List of phone numbers: (Get Sample: <span>MS Excel</span>,
            <span>CSV</span>, <span>Zip</span>, <span>7z</span> or{" "}
            <span>Txt</span>)
          </label>
          <div className="field-row">
            <input
              type="file"
              id="file-upload4"
              accept=".xlsx, .csv, .zip, .7z, .txt"
              onChange={handleFileChange2}
              style={{ display: "none" }}
            />
            <input
              type="text"
              placeholder=" File name shown here"
              value={selectedFile2 ? selectedFile2.name : ""}
              readOnly
            />
            <label htmlFor="file-upload4" className="file-upload-label">
              <img className="icon-width" src={UploadIcon} alt="^ Upload" />
            </label>
            <img
              className="icon-width delete-btn"
              src={DeleteIcon}
              alt="* Delete"
              onClick={() => setSelectedFile2(null)}
            />
          </div>
          <label>Maximum File Size : 20 MB</label>
        </div>

        <div className="addClient-field ck-box-label">
          <div>
            <input
              type="checkbox"
              id="duplicateDocB"
              value="checkedValue"
              checked={removeDuplicates}
              onChange={handleRemoveDuplicatesChange}
            />
            <label htmlFor="duplicateDocB">
              Remove duplicate entries from the browsed file{" "}
              <span>(What's this)</span>
            </label>
          </div>
          <label>
            <span>
              (This will automatically remove duplicate entries from your file
              prior to being uploaded.)
            </span>
          </label>
        </div>

        <div className="addClient-field ck-box-label">
          <div>
            <input
              type="checkbox"
              id="trackingDocB"
              value="checkedValue"
              checked={enableLinkTracking}
              onChange={handleEnableLinkTrackingChange}
            />
            <label htmlFor="trackingDocB">Enable link tracking</label>
          </div>
          <label>
            <span>
              (Automatically shortens the long URL in your messages and tracks
              user-wise. Converts only the first long URL found into a short
              URL. ** Please note that the short URL will consume 28 characters
              of the message length)
            </span>
          </label>
        </div>

        <div className="addClient-field ck-box-label">
          <div>
            <input
              type="checkbox"
              id="InteractiveDocB"
              value="checkedValue"
              checked={sendInteractiveButton}
              onChange={handleSendInteractiveButtonChange}
            />
            <label htmlFor="InteractiveDocB">
              Send Interactive Button Enabled Template
            </label>
          </div>
          <label>
            <span>
              (Enables you to send Interactive button Templates. It should be
              always off for non-button templates)
            </span>
          </label>
        </div>
      </>
    </div>
  );
};

export default DocumentBulkComponent;
