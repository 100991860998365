import React, { useState } from "react";

import CheckedSqur from "../icons/check-square-contained.svg";
import SaveClose from "../icons/save-02.svg";
import CancelClose from "../icons/x-01.svg";
import BuilderIcon from "../icons/Campaining/rocket (1) 1.svg";

import MainTree from "./MainTree";

import "../Segmentaion/AddNew.css"; //main css
import "./AddNewCampain.css"; // remaining css

import DetailsFormNew from "./DetailsFormNew";

const AddNewCampain = ({
  selectedUserId,
  setSelectedUserId,

  setShowAddNewCampain,
  onSave,
  handleCloseAddNewCampain,

  selectedOption,
  setSelectedOption,

  popupOptions,
  setPopupOptions,

  treeData,
  setTreeData,

  fetchProducts,

  saveBTN,
  setSaveBTN,

  saveCloseBTN,
  setSaveCloseBTN,
}) => {
  const [segmentTitle, setSegmentTitle] = useState("");
  const [showBuilder, setShowBuilder] = useState(false);

  const [yesNovalue, setYesNoValue] = useState("Yes");

  const handleTitleChange = (event) => {
    setSegmentTitle(event.target.value);
  };

  const handleOpenBuilder = () => {
    setShowBuilder(true);
  };

  const handleBuilderClose = () => {
    setShowBuilder(false);
  };

  return (
    <>
      {showBuilder ? (
        <MainTree
          treeData={treeData}
          setTreeData={setTreeData}
          popupOptions={popupOptions}
          setPopupOptions={setPopupOptions}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setShowAddNewCampain={setShowAddNewCampain}
          handleBuilderClose={handleBuilderClose}
          selectedUserId={selectedUserId}
          setSelectedUserId={setSelectedUserId}
          fetchProducts={fetchProducts}
        />
      ) : (
        <div className="main-segment2">
          <form className="top-search-buttons campain-top-search-buttons ">
            <input
              className="newTitle"
              type="text"
              placeholder="New Segment"
              value={segmentTitle}
              onChange={handleTitleChange}
              disabled
            />
          </form>
          <div className="segment2-body">
            <div className="segment2-body-left">
              <DetailsFormNew
                treeData={treeData}
                setTreeData={setTreeData}
                popupOptions={popupOptions}
                setPopupOptions={setPopupOptions}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                selectedUserId={selectedUserId}
                handleOpenBuilder={handleOpenBuilder}
                yesNovalue={yesNovalue}
                setYesNoValue={setYesNoValue}
                fetchProducts={fetchProducts}
                saveBTN={saveBTN}
                saveCloseBTN={saveCloseBTN}
                setShowAddNewCampain={setShowAddNewCampain}
              />
            </div>
            <div>
              <div className="top-btns">
                <div className="btn-left">
                  <button
                    type="submit"
                    form="detailsForm10"
                    // onClick={handleOpenBuilder}
                    className="functionBTN"
                  >
                    <img className="icon-all" src={BuilderIcon} alt="" />{" "}
                    Builder
                  </button>
                  <button
                    onClick={() => setSaveBTN("true")}
                    form="detailsForm10"
                    className="functionBTN"
                  >
                    <img className="icon-all" src={CheckedSqur} alt="" /> Save
                  </button>
                </div>
                <div className="btn-right">
                  <button
                    onClick={handleCloseAddNewCampain}
                    className="functionBTN closeBTN"
                  >
                    <img className="icon-all" src={CancelClose} alt="" /> Cancel
                  </button>
                  <button
                    onClick={() => setSaveCloseBTN(true)}
                    form="detailsForm10"
                    className="functionBTN"
                  >
                    <img className="icon-all" src={SaveClose} alt="saveclose" />
                    Save & Close
                  </button>
                </div>
              </div>
              <div className="segment2-body-right negative-margin">
                <YesNoButtons
                  yesNovalue={yesNovalue}
                  setYesNoValue={setYesNoValue}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const YesNoButtons = ({ yesNovalue, setYesNoValue }) => {
  const handleRadioChange = (event) => {
    setYesNoValue(event.target.value);
  };

  return (
    <div className="yesNoButtons">
      <h4>Published</h4>
      <div>
        <label
          className={`radio-button ${yesNovalue === "Yes" ? "active" : ""}`}
        >
          <input
            style={{ display: "none" }}
            type="radio"
            value="Yes"
            checked={yesNovalue === "Yes"}
            onChange={handleRadioChange}
          />
          Yes
        </label>
        <label
          className={`radio-button ${yesNovalue === "No" ? "active" : ""}`}
        >
          <input
            style={{ display: "none" }}
            type="radio"
            value="No"
            checked={yesNovalue === "No"}
            onChange={handleRadioChange}
          />
          No
        </label>
      </div>
    </div>
  );
};

export default AddNewCampain;
