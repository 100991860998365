import React, { useState } from "react";

import { Link } from "react-router-dom";

import UploadIcon from "../../../../icons/UploadIcon.svg";
import DeleteIcon from "../../../../icons/trash-02.svg";

import "../../ClientSelect.css"; //main css
import "../WASimple.css"; //remaining css 1

const VideoBulkComponent = () => {
  const [text, setText] = useState("");
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [sendOption, setSendOption] = useState("link");

  const handleTextChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= maxCharacterCount) {
      setText(inputText);
    } else {
      setText(inputText.slice(0, maxCharacterCount));
    }
  };

  const maxCharacterCount = 1024;
  const characterCount = text.length;
  const charactersLeft = maxCharacterCount - characterCount;

  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    setSelectedFile1(file);
  };

  const handleFileChange2 = (event) => {
    const file = event.target.files[0];
    setSelectedFile2(file);
  };

  return (
    <div className="addClient-bottom">
      <>
        <div className="addClient-field field-row">
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="sendOption4"
              value="checkedValue"
              defaultChecked
            />
            Common media for all recipients
          </label>
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="sendOption4"
              value="checkedValue"
            />
            Custom media for each recipients
          </label>
        </div>

        <div className="addClient-field">
          <label>
            Select Video file to be sent to user(if common file for all users)
          </label>
          <div className="field-row">
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name="sendOption1"
                value="link"
                checked={sendOption === "link"}
                onChange={() => setSendOption("link")}
              />
              Give a link
            </label>
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name="sendOption1"
                value="upload"
                checked={sendOption === "upload"}
                onChange={() => setSendOption("upload")}
              />
              Upload a file
            </label>
          </div>
        </div>

        {sendOption === "link" && (
          <div className="addClient-field">
            <label>
              Specify a link to an externally hosted video file (MP4 format
              only) Max size 5 MB
            </label>
            <input type="text" placeholder="https://www.mydomain.com/vid.mp4" />
          </div>
        )}

        {sendOption === "upload" && (
          <div className="addClient-field">
            <label>
              Upload an video (MP4 only) from your local computer. Max size 5 MB
            </label>
            <div className="field-row">
              <input
                type="file"
                id="file-upload8"
                accept=".png, .jpg, .jpeg"
                onChange={handleFileChange1}
                style={{ display: "none" }}
              />
              <input
                type="text"
                placeholder=" File name shown here"
                value={selectedFile1 ? selectedFile1.name : ""}
                readOnly
              />
              <label htmlFor="file-upload8" className="file-upload-label">
                <img className="icon-width" src={UploadIcon} alt="^ Upload" />
              </label>
              {selectedFile1 && (
                <img
                  className="icon-width delete-btn"
                  src={DeleteIcon}
                  alt="* Delete"
                  onClick={() => setSelectedFile1(null)}
                />
              )}
            </div>
          </div>
        )}

        <div className="addClient-field">
          <label>
            Select a template for caption text to be displayed with video
          </label>
          <textarea
            onChange={handleTextChange}
            value={text}
            cols="30"
            rows="10"
            placeholder="Click to change Template"
          ></textarea>
          <div className="template-status">
            <label>
              Characters used: {characterCount}, Left: {charactersLeft}
            </label>
            <Link>Use Template</Link>
          </div>
        </div>
        <div className="addClient-field field-row">
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="sendOption2"
              value="checkedValue"
              defaultChecked
            />
            Send Now
          </label>
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              name="sendOption2"
              value="checkedValue"
            />
            Schedule
          </label>
        </div>
        <div className="addClient-field">
          <label>
            Upload List of phone numbers: (Get Sample : <span>MS Excel</span>,
            <span>CSV</span>, <span>Zip</span>, <span>7z </span>
            or <span>Txt</span>)
          </label>
          <div className="field-row">
            <input
              type="file"
              id="file-upload9"
              accept=".xlsx, .csv, .zip, .7z, .txt"
              onChange={handleFileChange2}
              style={{ display: "none" }}
            />
            <input
              type="text"
              placeholder=" File name shown here"
              value={selectedFile2 ? selectedFile2.name : ""}
              readOnly
            />
            <label htmlFor="file-upload9" className="file-upload-label">
              <img className="icon-width" src={UploadIcon} alt="^ Upload" />
            </label>
            <img
              className="icon-width delete-btn"
              src={DeleteIcon}
              alt="* Delete"
              onClick={() => setSelectedFile2(null)}
            />
          </div>
          <label>Maximum File Size : 20 MB</label>
        </div>
        <div className="addClient-field ck-box-label">
          <div>
            <input type="checkbox" id="duplicateVdoB" value="checkedValue" />
            <label htmlFor="duplicateVdoB">
              Remove duplicate entries from browsed file
              <span>(What's this)</span>
            </label>
          </div>
          <label>
            <span>
              (This will automatically remove duplicate entries from your file
              prior to being uploaded.)
            </span>
          </label>
        </div>
        <div className="addClient-field ck-box-label">
          <div>
            <input type="checkbox" id="trackingVdoB" value="checkedValue" />
            <label htmlFor="trackingVdoB">Enable link tracking</label>
          </div>
          <label>
            <span>
              ( Automatically shortens the long URL in your messages and tracks
              user-wise. Converts only the first long URL found into a short
              URL. <br /> ** Please note that the short URL will consume 28
              characters of the message length)
            </span>
          </label>
        </div>
        <div className="addClient-field ck-box-label">
          <div>
            <input type="checkbox" id="InteractiveVdoB" value="checkedValue" />
            <label htmlFor="InteractiveVdoB">
              Send Interactive Button Enabled Template
            </label>
          </div>
          <label>
            <span>
              ( Enables you to send Interactive button Templates. It should be
              always off for non-button templates)
            </span>
          </label>
        </div>
      </>
    </div>
  );
};

export default VideoBulkComponent;
