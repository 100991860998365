import React, { useState, useEffect } from "react";
import axios from "axios";

import { EditorState, RichUtils, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import UndoIcon from "../icons/UndoIcon.svg";
import RedoIcon from "../icons/RedoIcon.svg";
import TrashIcon from "../icons/trash-02.svg";

import "./DetailsFilterComponent.css";

const DetailsFilterComponent = ({
  register,
  errors,
  watch,
  setValue,
  dirtyFields,
  editorState,
  setEditorState,
  segmentDetailsName,
  segmentDetailsCategory,
}) => {
  const [showDetailsForm, setShowDetailsForm] = useState(true);

  const handleShowDetailsForm = () => {
    setShowDetailsForm(true);
  };

  const handleShowFilterForm = () => {
    setShowDetailsForm(false);
  };

  return (
    <>
      <div className="detail-filter-btn-group">
        <button
          type="button"
          onClick={handleShowDetailsForm}
          className={showDetailsForm ? "activeBorder" : " "}
        >
          Details
        </button>
        <button
          type="button"
          onClick={handleShowFilterForm}
          className={!showDetailsForm ? "activeBorder" : " "}
        >
          Filters
        </button>
      </div>
      <div className={showDetailsForm ? "visible" : "hidden"}>
        <DetailsForm
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          dirtyFields={dirtyFields}
          editorState={editorState}
          setEditorState={setEditorState}
          segmentDetailsName={segmentDetailsName}
          segmentDetailsCategory={segmentDetailsCategory}
        />
      </div>
      <div className={!showDetailsForm ? "visible" : "hidden"}>
        <FilterForm />
      </div>
    </>
  );
};

const DetailsForm = ({
  register,
  setValue,
  watch,
  errors,

  editorState,
  setEditorState,

  segmentDetailsName,
  segmentDetailsCategory,
}) => {
  const [filist, setFis] = useState([]);

  const handleDetailsNameChange = (event) => {
    setValue("segmentDetailsName", event.target.value);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    await axios
      .get("https://digitalxapi.cncitsolutions.in/api/FiUniqueCategory")
      .then(({ data }) => {
        setFis(data);
      });
  };

  // useEffect(() => {
  //   if (segmentDetailsName || segmentDetailsCategory || editorState) {
  //     const dataToStore = JSON.stringify({
  //       segmentDetailsName,
  //       segmentDetailsCategory,
  //       editorState: convertToRaw(editorState.getCurrentContent()),
  //     });
  //     localStorage.setItem("segmentationDetailsForm", dataToStore);
  //   }
  // }, [editorState, watch, segmentDetailsName, segmentDetailsCategory]);

  const handleUndo = (e) => {
    e.preventDefault();
    setEditorState(EditorState.undo(editorState));
  };

  const handleRedo = (e) => {
    e.preventDefault();
    setEditorState(EditorState.redo(editorState));
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    // const contentState = newEditorState.getCurrentContent();
    // const rawContent = convertToRaw(contentState);
  };

  return (
    <div className="detailsForm">
      <div style={{ position: "relative" }}>
        <label htmlFor="Name">Name:</label>
        <input
          placeholder="Type the name"
          type="text"
          id="segmentDetailsName"
          name="segmentDetailsName"
          value={segmentDetailsName}
          onChange={handleDetailsNameChange}
          {...register("segmentDetailsName", { required: true })}
        />
        {errors.segmentDetailsName && (
          <span className="error-message">This field is required</span>
        )}
      </div>

      <div style={{ position: "relative" }}>
        <label htmlFor="Category">Category:</label>

        {/*  */}
        <select
          id="segmentDetailsCategory"
          name="segmentDetailsCategory"
          value={segmentDetailsCategory}
          {...register("segmentDetailsCategory", { required: true })}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setValue("segmentDetailsCategory", selectedValue);
            localStorage.setItem("selectedCategory", selectedValue);
          }}
        >
          <option selected value="">
            Select Category
          </option>
          {filist.map((fiCategory, index) => (
            <option key={index} value={fiCategory}>
              {fiCategory.toUpperCase()}
            </option>
          ))}
        </select>
        {/*  */}

        {/* <input
          placeholder="Type the categories"
          type="text"
          id="segmentDetailsCategory"
          name="segmentDetailsCategory"
          {...register("segmentDetailsCategory", { required: true })}
        /> */}
        {errors.segmentDetailsCategory && (
          <span className="error-message">This field is required</span>
        )}
      </div>

      <div style={{ position: "relative" }}>
        <label htmlFor="Description">Description:</label>
        <div className="fontStyles">
          <button onClick={handleUndo}>
            <img src={UndoIcon} alt="" />
          </button>
          <button onClick={handleRedo}>
            <img src={RedoIcon} alt="" />
          </button>
        </div>
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorStateChange}
          handleKeyCommand={handleKeyCommand}
          id="segmentDescriptionEditor"
          name="segmentDescriptionEditor"
          toolbar={{
            options: ["inline"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
          }}
        />
      </div>
    </div>
  );
};

const FilterForm = () => {
  const [selectedOption, setSelectedOption] = useState(" ");
  const [tags, setTags] = useState([]);
  const [nextTagId, setNextTagId] = useState(1);
  const [inclusionOptions, setInclusionOptions] = useState({});

  const handleOptionChange = (event) => {
    const selected = event.target.value;
    if (selected !== "" && !tags.some((tag) => tag.option === selected)) {
      setSelectedOption(selected);
      setTags((prevTags) => [
        ...prevTags,
        { id: nextTagId, option: selected, tag: "", andOr: "AND" },
      ]);
      setInclusionOptions((prevOptions) => ({
        ...prevOptions,
        [nextTagId]: "Including", // Default value
      }));
      setNextTagId((prevId) => prevId + 1);
    }
  };

  const handleTagChange = (tagId, event) => {
    const newTags = tags.map((tag) =>
      tag.id === tagId ? { ...tag, tag: event.target.value } : tag
    );
    setTags(newTags);
  };

  const handleAndOrChange = (tagId, andOrValue) => {
    const newTags = tags.map((tag) =>
      tag.id === tagId ? { ...tag, andOr: andOrValue } : tag
    );
    setTags(newTags);
  };

  const handleRemoveTag = (tagId) => {
    setTags((prevTags) => prevTags.filter((tag) => tag.id !== tagId));
    setSelectedOption(" ");
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("filterForm"));
    if (storedData) {
      setSelectedOption(storedData.selectedOption);
      setTags(storedData.tags);
      setNextTagId(storedData.nextTagId);
      setInclusionOptions(storedData.inclusionOptions);
    }
  }, []);

  useEffect(() => {
    const dataToStore = JSON.stringify({
      selectedOption,
      tags,
      nextTagId,
      inclusionOptions,
    });
    localStorage.setItem("filterForm", dataToStore);
  }, [selectedOption, tags, nextTagId, inclusionOptions]);

  return (
    <div className="filter-main segment-body1">
      <label className="filter-wrapper">
       
        <select  name="filterChooseOptions1" id="filterChooseOptions" value={selectedOption} onChange={handleOptionChange}>
        <option value="">Choose one..</option>
        <option value="AllocationDate">AllocationDate</option>
        <option value="LoanId">LoanId</option>
        <option value="ApplicantName">ApplicantName</option>
        <option value="pan">pan</option>
        <option value="ApplicantDOB">ApplicantDOB</option>
        <option value="ContactNumber">ContactNumber</option>
        <option value="Email">Email</option>
        <option value="ProductType">ProductType</option>
        <option value="TotalLoanAmount">TotalLoanAmount</option>
        <option value="ToalDueAmount">ToalDueAmount</option>
        <option value="PrincipalOutstanding">PrincipalOutstanding</option>
        <option value="InterestDueAmount">InterestDueAmount</option>
        <option value="LateFee">LateFee</option>
        <option value="EMIDueAmount">EMIDueAmount</option>
        <option value="DueDate">DueDate</option>
        <option value="LastPaymentDate">LastPaymentDate</option>
        <option value="LastPaidAmount">LastPaidAmount</option>
        <option value="AllocationDPD">AllocationDPD</option>
        <option value="AllocationDPDBucket">AllocationDPDBucket</option>
        <option value="LoanDisbursementDate">LoanDisbursementDate</option>
        <option value="DisbursementType">DisbursementType</option>
        <option value="LoanType">LoanType</option>
        <option value="LoanMaturityDate">LoanMaturityDate</option>
        <option value="TenureFinished">TenureFinished</option>
        <option value="ApplicantCibilScore">ApplicantCibilScore</option>
        <option value="BusinessName">BusinessName</option>
        <option value="ApplicantAddressType">ApplicantAddressType</option>
        <option value="ApplicantAddress">ApplicantAddress</option>
        <option value="ApplicantCity">ApplicantCity</option>
        <option value="ApplicantState">ApplicantState</option>
        <option value="ApplicantPincode">ApplicantPincode</option>
        <option value="ApplicantAlternateAddressType">ApplicantAlternateAddressType</option>
        <option value="ApplicantAlternateAddress">ApplicantAlternateAddress</option>
        <option value="ApplicantAlternateCity">ApplicantAlternateCity</option>
        <option value="ApplicantAlternateState">ApplicantAlternateState</option>
        <option value="ApplicantAlternatePincode">ApplicantAlternatePincode</option>
        <option value="Reference1RelationWithApplicant">Reference1RelationWithApplicant</option>
        <option value="Reference1Name">Reference1Name</option>
        <option value="Reference1Mobile">Reference1Mobile</option>
        <option value="Reference2Name">Reference2Name</option>
        <option value="Reference2Mobile">Reference2Mobile</option>
        <option value="Reference3RelationWithApplicant">Reference3RelationWithApplicant</option>
        <option value="Reference3Name">Reference3Name</option>
        <option value="Reference3Mobile">Reference3Mobile</option>
        <option value="AlternateMobile1">AlternateMobile1</option>
        <option value="AlternateMobile2">AlternateMobile2</option>
        <option value="AlternateMobile3">AlternateMobile3</option>
        <option value="MakeAndModel">MakeAndModel</option>
        <option value="ChassisNumber">ChassisNumber</option>
        <option value="EngineNumber">EngineNumber</option>
        <option value="VehicleRegistrationNumber">VehicleRegistrationNumber</option>
        <option value="CoApplicantName">CoApplicantName</option>
        <option value="CoApplicantEmail">CoApplicantEmail</option>
        <option value="CoApplicantContactNumber">CoApplicantContactNumber</option>
        <option value="CoApplicantDob">CoApplicantDob</option>
        <option value="CoApplicantAddressType">CoApplicantAddressType</option>
        <option value="CoApplicantAddress">CoApplicantAddress</option>
        <option value="CoApplicantCity">CoApplicantCity</option>
        <option value="CoApplicantState">CoApplicantState</option>
        <option value="CoApplicantPincode">CoApplicantPincode</option>
        <option value="Priority">Priority</option>
        <option value="AllocationAgentId">AllocationAgentId</option>
        <option value="Band">Band</option>
       
        </select>
      </label>
      {tags.map((tag) => (
        <div key={tag.id} style={{ width: "100%" }}>
        
          <div className="and-or-wrapper">
            <label
              className={`and-or-label ${tag.andOr === "AND" ? "active" : ""}`}
            >
              <input
                style={{ display: "none" }}
                type="radio"
                value="AND"
                id={`AND${tag.id}`}
                name={`AND${tag.id}`}
                checked={tag.andOr === "AND"}
                onChange={() => handleAndOrChange(tag.id, "AND")}
              />
              AND
            </label>
            <label
              className={`and-or-label ${tag.andOr === "OR" ? "active" : ""}`}
            >
              <input
                style={{ display: "none" }}
                type="radio"
                value="OR"
                id={`OR${tag.id}`}
                name={`OR${tag.id}`}
                checked={tag.andOr === "OR"}
                onChange={() => handleAndOrChange(tag.id, "OR")}
              />
              OR
            </label>
          </div>
          <div className="tags-wrapper">
            <label htmlFor={tag.id} className="options-label">
              {tag.option}
              
            </label>
             <input
                type="hidden"
                value={tag.option}
                name={`column[]`}
              />
               <input
                type="hidden"
                value={tag.id}
                name={`tag_id[]`}
              />
            <select
              value={inclusionOptions[tag.id] || "Including"}
              id={`inclusion-options-${tag.id}`}
              name={`inclusionoptions[]`}
              onChange={(event) =>
                setInclusionOptions((prevOptions) => ({
                  ...prevOptions,
                  [tag.id]: event.target.value,
                }))
              }
            >
              <option value="inc">Including</option>
              <option value="exc">Excluding</option>
            </select>
            <input
              type="text"
              id={`tag-names-${tag.id}`}
              name={`tagnames[]`}
              className="tag-names"
              value={tag.tag}
              onChange={(event) => handleTagChange(tag.id, event)}
            />
            <button onClick={() => handleRemoveTag(tag.id)}>
              <img src={TrashIcon} alt="deleteIcon" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DetailsFilterComponent;
