import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import ApplyBTN from "../../icons/Campaining/plus-01.svg";
import ApplyBTNHover from "../../icons/Campaining/plus-01 white.svg";
import CloseBuilderBTN from "../../icons/Campaining/x-01.svg";
import CloseBuilderBTNHover from "../../icons/Campaining/x-01 white.svg";

const ContactSourceDetails = ({
  onCancel,
  selectedOption,
  textOnAdd,
  popupOptions,
  setPopupOptions,

  selectedUserId,
  setTreeData,
}) => {
  const url = window.$APIURL;
  const [latestData, setLatestData] = useState([]);

  const [applyBtnHover, setApplyBtnHover] = useState(false);
  const [closeBtnHover, setCloseBtnHover] = useState(false);

  const handleApplyBtnHover = () => {
    setApplyBtnHover(true);
  };

  const handleApplyBtnLeave = () => {
    setApplyBtnHover(false);
  };

  const handleCloseBtnHover = () => {
    setCloseBtnHover(true);
  };

  const handleCloseBtnLeave = () => {
    setCloseBtnHover(false);
  };

  const handleSelectPopup = (selectedOption) => {
    setPopupOptions(selectedOption.value);
    // setTreeData([
    //   {
    //     id: "1",
    //     text: selectedOption.value,
    //     diamond: false,
    //     children: [],
    //   },
    // ]);
  };

  const handleAddText = () => {
    textOnAdd(popupOptions);
    onSubmit();
  };

  useEffect(() => {
    // Make an API request to the Laravel endpoint
    if (selectedUserId == "") {
      axios
        .post("https://digitalxapi.cncitsolutions.in/api/campaining/latest-id")
        .then((response) => {
          // Set the latest data in your state
          setLatestData(response.data.data.id);
        })
        .catch((error) => {
          alert("Error fetching latest data:", error);
        });
    }
  }, []);

  const onSubmit = (formdata) => {
    if (selectedUserId !== "") {
      var myform = document.getElementById("contactSourceTypeFormData");
      var fd = new FormData(myform);
      axios
        .post(
          url +
            `api/campaining/contactSourceTypeFormData/${selectedUserId}/update`,
          fd,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(({ data }) => {
          if (data.status == true) {
            // Swal.fire({
            //   icon: "success",
            //   text: data.message,
            // });
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    } else {
      var myform = document.getElementById("contactSourceTypeFormData");
      var fd = new FormData(myform);
      axios
        .post(
          url + `api/campaining/contactSourceTypeFormData/${latestData}/update`,
          fd,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(({ data }) => {
          if (data.status == true) {
            // Swal.fire({
            //   icon: "success",
            //   text: data.message,
            // });
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    }
  };

  let label;
  let text1;
  if (selectedOption === "Contact segments") {
    label = "Contact Segments";
    text1 =
      "Contacts that are members of the selected segments will be automatically added to this campaign.";
  } else if (selectedOption === "Campaign Forms") {
    label = "Campaign Forms";
    text1 =
      "Contacts that have submitted the selected forms will be automatically added to this campaign.";
  } else if (selectedOption === "Campaign Date") {
    label = "Campaign Date";
    text1 =
      "Contacts that were added to the system on or after the selected date will be automatically added to this campaign.";
  } else {
    label = "";
    text1 = "";
  }

  return (
    <div className="dark-bg">
      <form
        className="contact-source-details"
        id="contactSourceTypeFormData"
        onSubmit={onSubmit}
      >
        <label>Contact Sources</label>
        <p>{text1}</p>
        <label>
          {label}
          <span className="star-req">*</span>{" "}
        </label>
        {/* <Select
          className="select-container"
          options={options}
          defaultValue={popupOptions}
          name="contactSourceType1"
          id="contactSourceType1"
          onChange={handleSelectPopup}
          placeholder="Choose one..."
        /> */}

        <select
          name="contactSourceType1"
          id="contactSourceType1"
          value={popupOptions}
          onChange={handleSelectPopup}
        >
          <option select value="">
            Choose one...
          </option>
          <option value="Confirmed Tribe Members">
            Confirmed Tribe Members
          </option>
          <option value="Newsletter">Newsletter</option>
          <option value="On-boarding Tribe">On-boarding Tribe</option>
          <option value="Pending Email Confirmation">
            Pending Email Confirmation
          </option>
        </select>

        <div className="buttons-row">
          <button
            onClick={onCancel}
            className="close-btn"
            onMouseEnter={handleApplyBtnHover}
            onMouseLeave={handleApplyBtnLeave}
          >
            <img
              src={applyBtnHover ? CloseBuilderBTNHover : CloseBuilderBTN}
              alt=""
            />{" "}
            Cancel
          </button>
          <button
            onClick={handleAddText}
            className="apply-btn"
            type="submit"
            form="contactSourceTypeFormData"
            onMouseEnter={handleCloseBtnHover}
            onMouseLeave={handleCloseBtnLeave}
          >
            <img src={closeBtnHover ? ApplyBTNHover : ApplyBTN} alt="" />
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactSourceDetails;
