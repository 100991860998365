import React, { useState } from 'react'

import PlusWhite from '../icons/ConentManagement/plue white.svg';
import FilterIcon from '../icons/ConentManagement/menu.svg';
import xRed from '../icons/ConentManagement/x-01.svg';

import './FilterAddContent.css'

const FilterAddContent = ({ setCancelButtonVisible, isCancelButtonVisible, handleButtonClick , isMoreButtonVisible, setSelectedChannel }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = (isOpen) => {
    setIsDropdownOpen(isOpen);
  }
  

  const handleClick = () => {
    handleButtonClick();
  };

  return (
    <>
      <div className={`dropdown content-drop ${isDropdownOpen ? 'open' : ''}`} onMouseEnter={() => handleDropdownToggle(true)} onMouseLeave={() => handleDropdownToggle(false)}>
        <div className="btn btn-secondary dropdown-toggle filterBTNDisabled" id="dropdownMenuButton1">
          <img src={FilterIcon} alt="" />
          Filter
        </div>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li onClick={() => setSelectedChannel("SMS")}><a className="dropdown-item bg-color-blue">SMS</a></li>
          <li onClick={() => setSelectedChannel("Email")}><a className="dropdown-item bg-color-yellow">Email</a></li>
          <li onClick={() => setSelectedChannel("WhatsApp")}><a className="dropdown-item bg-color-red">WhatsApp</a></li>
          <li onClick={() => setSelectedChannel("IVR")}><a className="dropdown-item bg-color-green">IVR</a></li>
          <li onClick={() => setSelectedChannel(null)}><a className="dropdown-item bg-color-purple">ALL</a></li>
        </ul>
      </div>

      <button type="button" className="btn btn-primary add-btn-cont" onClick={handleClick}>
        <img src={PlusWhite} alt="+" />New</button>

      {isMoreButtonVisible ? (
        <button type="button" className="btn btn-primary add-btn-cont" onClick={handleClick}>
        <img src={PlusWhite} alt="+" />New</button>
      ) : isCancelButtonVisible ? (
        <button type="button" className="btn btn-primary add-btn-cont more-btn" onClick={()=>{setCancelButtonVisible(!isCancelButtonVisible);}}>More</button>
        ) : (
        <div className='cancel-container'>
          <button type="button" className="btn btn-primary add-btn-cont cancel-btn" onClick={()=>{setCancelButtonVisible(!isCancelButtonVisible);}}> <img src={xRed} alt="X" /> Cancel</button>
          <ul className='cancel-btn-options'>
            <li><button>Clone</button></li>
            <li><button>Disable</button></li>
            <li><button>Delete</button></li>
          </ul>
        </div>
      )}
    </>
  )
}

export default FilterAddContent
