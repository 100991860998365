import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";

import Save from "../../icons/ConentManagement/save-02.svg";
import Cancel from "../../icons/ConentManagement/x-01.svg";

import "./ClientSelect.css";

const IVRForm = ({
  handleCancel,
  clientFormData,
  selectedUserId,
}) => {
  const {
    clientName,
    productType,
    channel,
    contentName,
    contentDescription,
    setClientName,
    setProductType,
    setContentName,
    setChannel,
    setContentDescription,
  } = clientFormData;

  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  useEffect(() => {
    if (selectedUserId !== "") {
      fetchProducts();
    }
  }, [selectedUserId]);

  const fetchProducts = async () => {
    await axios
      .post(
        `https://digitalxapi.cncitsolutions.in/api/contentManagement/${selectedUserId}/edit`
      )
      .then(({ data }) => {
        setInputs({
          ivrId: data.ivrId || "",
          ivrContentDescription: data.ivrContentDescription || "",
        });
        setClientName(data.Name || "");
        setProductType(data.Product_type || "");
        setChannel(data.Channel || "");
        setContentName(data.Created_by || "");
        setContentDescription(data.ContentDescription || "");
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const url = window.$APIURL;

  const onSubmit = (formdata) => {
    if (selectedUserId !== "") {
      var myform = document.getElementById("ivrForm");
      var fd = new FormData(myform);
      axios
        .post(url + `api/contentManagement/IVR/${selectedUserId}/update`, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              icon: "success",
              text: data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          handleCancel(); //commnent if you dont need to close form after submitting.
          setChannel("");
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    } else {
      console.log(formdata);
      var myform = document.getElementById("ivrForm");
      var fd = new FormData(myform);

      axios
        .post(url + "api/contentManagement/IVR", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              icon: "success",
              text: data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          handleCancel(); //commnent if you dont need to close form after submitting.
          setChannel("");
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    }
  };

  return (
    <div className="addClient-container">
      <div className="addClient-wrapper option-client-wrapper">
        <div className="addClient-top">
          <label>IVR</label>
          <div className="client-btns">
            <button
              className="client-btn save-close-btn"
              type="submit"
              form="ivrForm"
            >
              <img src={Save} alt="" /> Save & Close
            </button>
            <button className="client-btn cancel-btn" onClick={handleCancel}>
              <img src={Cancel} alt="" /> Cancel
            </button>
          </div>
        </div>
        <div className="addClient-bottom">
          <form id="ivrForm" onSubmit={handleSubmit(onSubmit)}>
            <div style={{ display: "none" }}>
              <input
                type="text"
                onChange={() => setClientName(clientName)}
                placeholder="data"
                name="clientNameFULL1"
                id="clientNameFULL1"
                value={clientName}
              />
              <input
                type="text"
                onChange={() => setProductType(productType)}
                placeholder="data"
                name="productTypeFULL1"
                id="productTypeFULL1"
                value={productType}
              />
              <input
                type="text"
                onChange={() => setChannel(channel)}
                placeholder="data"
                name="channelFULL1"
                id="channelFULL1"
                value={channel}
              />
              <input
                type="text"
                onChange={() => setContentName(contentName)}
                placeholder="data"
                name="contentNameFULL1"
                id="contentNameFULL1"
                value={contentName}
              />
              <input
                type="text"
                onChange={() => setContentDescription(contentDescription)}
                placeholder="data"
                name="contentDescriptionFULL1"
                id="contentDescriptionFULL1"
                value={contentDescription}
              />
            </div>
            <div className="addClient-field">
              <label>
                IVR ID<span className="star-req">*</span>
              </label>
              <input
                placeholder="IVR ID"
                defaultValue={inputs.ivrId}
                onChange={handleChange}
                type="text"
                {...register("ivrId", { required: true })}
                id="ivrId" // Add an ID to the input element
                name="ivrId" // Add a name to the input element
              />
              {errors.ivrId && (
                <span className="error-message">IVR ID is required.</span>
              )}
            </div>
            <div className="addClient-field">
              <label>
                IVR Content<span className="star-req">*</span>
              </label>
              <textarea
                placeholder="Content description"
                defaultValue={inputs.ivrContentDescription}
                onChange={handleChange}
                rows="7"
                {...register("ivrContentDescription", { required: true })}
                id="ivrContentDescription" // Add an ID to the textarea element
                name="ivrContentDescription" // Add a name to the textarea element
              ></textarea>
              {errors.ivrContentDescription && (
                <span className="error-message">IVR Content is required.</span>
              )}
            </div>
            <div className="addClient-field">
              <label>User Template</label>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default IVRForm;
