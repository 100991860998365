import React from "react";
import FinancialReview from "./components/FinancialReview";
import StrategicData from "./components/StrategicData";
import FinancialLayout from "./components/FinancialLayout";
import StatsReview from "./components/StatsReview";
import DataInteraction from "./components/DataInteraction";
import RangeWiseInteraction from "./components/RangeWiseInteraction.js";

import './DashboardMainComp.css' // related css
import '../Segmentaion/SegmentationMainComp.css' //remaining common css

const MainComp = () => {
  return (
    <segment className='mainContainer'>
      <segment className="mainWrapper">
        <div className='Dash-main-left'>
          <FinancialReview/>
          <StrategicData/>
          <FinancialLayout/>
        </div>
        <div className='Dash-main-right'>
          <StatsReview/>
          <DataInteraction/>
          <RangeWiseInteraction/>
        </div>
      </segment>
    </segment>
  );
};

export default MainComp;
