import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";

import AddLinksPopup from "./AddLinksPopup";

import Save from "../../icons/ConentManagement/save-02.svg";
import Cancel from "../../icons/ConentManagement/x-01.svg";
import LinkIcon from "../../icons/ConentManagement/link.svg";
import addIcon from "../../icons/plus-01.svg";
import trashIcon from "../../icons/trash-02.svg";
import AiOutlineUnorderedList from "../../icons/link.svg";

import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "./ClientSelect.css";

const EmailForm = ({ handleCancel, clientFormData, selectedUserId }) => {
  const {
    clientName,
    productType,
    channel,
    contentName,
    contentDescription,
    setClientName,
    setProductType,
    setContentName,
    setChannel,
    setContentDescription,
  } = clientFormData;

  const url = window.$APIURL;

  const [inputs, setInputs] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toolbarOptions = {
    fontFamily: {
      options: [
        "Arial",
        "Georgia",
        "Impact",
        "Tahoma",
        "Times New Roman",
        "Verdana",
        "San Serif",
        "Courier New",
        "Comic Sans MS",
        "Papyrus",
        "Brush Script MT",
        "Old English Text MT",
        "Lucida Calligraphy",
        "Arial Black",
        "Garamond",
        "Lucida Sans Unicode",
        "Lucida Console",
        "Serif",
        "Fixed Width",
        "Wide",
        "Narrow",
        "Garamond",
        "Tahoma",
        "Trebuchet MS",
        "Helvetica",
        "Verdana",
      ],
    },
  };

  useEffect(() => {
    if (selectedUserId !== "") {
      fetchProducts();
    }
  }, [selectedUserId]);

  let dataObject = [];

  const fetchProducts = async () => {
    await axios
      .post(
        `https://digitalxapi.cncitsolutions.in/api/contentManagement/${selectedUserId}/edit`
      )
      .then(({ data }) => {
        setInputs({
          fromNameEmail: data.fromName ||  "",
          mailAddressEmail: data.fromEmail ||  "",
          replyToEmail: data.replyToEmail ||  "",
          mailSubject: data.mailSubject ||  "",
          yourEmailEditor: data.yourEmailEditor ||  "",
        });
        setClientName(data.Name ||  "");
        setProductType(data.Product_type ||  "");
        setContentName(data.Created_by ||  "");
        setChannel(data.Channel ||  "");
        setContentDescription(data.ContentDescription ||  "");

        if (data.yourEmailEditor != null) {
          const data1 = convertFromRaw(JSON.parse(data.yourEmailEditor));
          setEditorState(EditorState.createWithContent(data1));
        }

        setJSONvalue(data.EmailLinks);
        if(data.EmailLinks != null){
          dataObject = JSON.parse(data.EmailLinks);
          const keys = Object.values(dataObject);
          setDivs(keys);
        }
      });
  };

  // const EditLinkOption = ({ onChange, editorState }) => {
  const EditLinkOption = () => {
    return (
      <img
        className="customEditorButtons"
        src={AiOutlineUnorderedList}
        alt="#"
        onClick={openPopup}
      />
    );
  };

  const ClearButton = ({ onChange }) => {
    const onClick = () => {
      if (
        window.confirm("Are you sure you want to clear the editor content?")
      ) {
        onChange(EditorState.createEmpty());
      }
    };

    return (
      <img
        className="customEditorButtons"
        src={trashIcon}
        alt="+"
        onClick={onClick}
      />
    );
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [divs, setDivs] = useState([]);
  const [JSONvalue, setJSONvalue] = useState({});

  const handleAddDiv = (e) => {
    createJSON();
    e.preventDefault();
    setDivs([...divs, ""]);
  };

  const createJSON = () => {
    const inputData = {};
  
    for (let index = 0; index < divs.length; index++) {
      const fieldName = `emailLink${index}`;
      const fieldElement = document.getElementById(fieldName);
  
      if (fieldElement) {
        const fieldValue = fieldElement.value;
        inputData[fieldName] = fieldValue;
      }
    }
  
    const jsonData = JSON.stringify(inputData);
    console.log(jsonData);
    setJSONvalue(jsonData);
  };
  

  const handleDeleteDiv = () => {
    createJSON();
    const updatedDivs = [...divs];
    updatedDivs.splice(0, 1);
    setDivs(updatedDivs);
  };

  const openPopup = (e) => {
    e.preventDefault();
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const onSubmit = (formdata) => {
    if (selectedUserId !== "") {
      var myform = document.getElementById("emailForm");
      var fd = new FormData(myform);

      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const contentStateAsJSON = JSON.stringify(rawContentState);
      fd.append("yourEmailEditor", contentStateAsJSON);

      axios
        .post(
          url + `api/contentManagement/Email/${selectedUserId}/update`,
          fd,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              icon: "success",
              text: data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          handleCancel(); //commnent if you dont need to close form after submitting.
          setChannel("");
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    } else {
      console.log(formdata);
      var myform = document.getElementById("emailForm");
      var fd = new FormData(myform);

      // Convert editor state to JSON and append to form data
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const contentStateAsJSON = JSON.stringify(rawContentState);
      fd.append("yourEmailEditor", contentStateAsJSON);

      axios
        .post(url + "api/contentManagement/Email", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              icon: "success",
              text: data.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
          handleCancel(); //commnent if you dont need to close form after submitting.
          setChannel("");
          // window.location.reload(false);
        })
        .catch(({ data }) => {
          if (data.status === 422) {
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        });
    }
  };

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    // const contentState = newEditorState.getCurrentContent();
    // const rawContent = convertToRaw(contentState);
  };

  return (
    <div className="addClient-container">
      <div className="addClient-wrapper option-client-wrapper">
        <div className="addClient-top">
          <label>Email</label>
          <div className="client-btns">
            <button
              className="client-btn save-close-btn"
              type="submit"
              form="emailForm"
            >
              <img src={Save} alt="" /> Save & Close
            </button>
            <button className="client-btn cancel-btn" onClick={handleCancel}>
              <img src={Cancel} alt="" /> Cancel
            </button>
          </div>
        </div>
        <div className="addClient-bottom">
          <form id="emailForm" onSubmit={handleSubmit(onSubmit)}>
            <div style={{ display: "none" }}>
              <input
                type="text"
                name="clientNameFULL1"
                id="clientNameFULL1"
                value={clientName}
              />
              <input
                type="text"
                name="productTypeFULL1"
                id="productTypeFULL1"
                value={productType}
              />
              <input
                type="text"
                name="channelFULL1"
                id="channelFULL1"
                value={channel}
              />
              <input
                type="text"
                name="contentNameFULL1"
                id="contentNameFULL1"
                value={contentName}
              />
              <input
                type="text"
                name="contentDescriptionFULL1"
                id="contentDescriptionFULL1"
                value={contentDescription}
              />
            </div>
            <div className="addClient-field">
              <label>
                From Name<span className="star-req">*</span>
              </label>
              <input
                type="text"
                id="fromNameEmail"
                name="fromNameEmail"
                defaultValue={inputs.fromNameEmail}
                onChange={handleChange}
                {...register("fromNameEmail", { required: true })}
              />
              {errors.fromNameEmail && (
                <span className="error-message">From Name is required.</span>
              )}
            </div>
            <div className="addClient-field">
              <label>
                From Email Address<span className="star-req">*</span>
              </label>
              <input
                type="email"
                id="mailAddressEmail"
                name="mailAddressEmail"
                defaultValue={inputs.mailAddressEmail}
                onChange={handleChange}
                {...register("mailAddressEmail", { required: true })}
              />
              {errors.mailAddressEmail && (
                <span className="error-message">
                  From Email Address is required.
                </span>
              )}
            </div>
            <div className="addClient-field">
              <label>
                Reply to Email Address<span className="star-req">*</span>
              </label>
              <input
                type="email"
                id="replyToEmail"
                name="replyToEmail"
                defaultValue={inputs.replyToEmail}
                onChange={handleChange}
                {...register("replyToEmail", { required: true })}
              />
              {errors.replyToEmail && (
                <span className="error-message">
                  Reply to Email Address is required.
                </span>
              )}
            </div>
            <div className="addClient-field">
              <label>
                Subject<span className="star-req">*</span>
              </label>
              <input
                type="text"
                id="mailSubject"
                name="mailSubject"
                defaultValue={inputs.mailSubject}
                onChange={handleChange}
                {...register("mailSubject", { required: true })}
              />
              {errors.mailSubject && (
                <span className="error-message">Subject is required.</span>
              )}
            </div>
            <div className="addClient-field with-btns">
              <label>
                Content<span className="star-req">*</span>
              </label>
              <Editor
                editorState={editorState}
                onChange={() => setEditorState}
                onEditorStateChange={handleEditorStateChange}
                id="yourEmailEditor"
                name="yourEmailEditor"
                toolbar={toolbarOptions}
                toolbarCustomButtons={[<EditLinkOption />, <ClearButton />]}
                // {...register("yourEmailEditor", { required: true })}
              />
              <div className="additional-buttons">
                <button type="button" onClick={openPopup}>
                  <img src={LinkIcon} alt="AddLinksPopup" />
                </button>
                <button type="button" onClick={handleAddDiv}>
                  <img src={addIcon} />
                </button>
                <button type="button" onClick={handleDeleteDiv}>
                  <img src={trashIcon} alt="" />
                </button>
                {/* <div className="add-btn-input">
                  <input
                    type="text"
                    id="emailLink0"
                    name="emailLink0"
                    onChange={createJSON}
                  />
                </div> */}</div>
                <div className="additional-buttons">
                {divs.map((div, index) => (
                  <div key={index} className="add-btn-input new">
                    <input
                      type="text"
                      id={`emailLink${index}`}
                      name={`emailLink${index}`}
                      defaultValue={div}
                      onChange={createJSON}
                    />
                  </div>
                ))}
                <input
                  type="text"
                  id="allLinksJSONvalue"
                  name="allLinksJSONvalue"
                  style={{ display: "none" }}
                  value={JSONvalue}
                  onChange={() => setJSONvalue}
                />
                </div>
              
              {errors.yourEmailEditor && (
                <span className="error-message">Content is required.</span>
              )}
            </div>
            <div className="addClient-field">
              <label>0 Characters Used</label>
            </div>
            <div className="addClient-field">
              <label>Use Template</label>
            </div>
            <div className="client-submit-btns">
              <button className="client-btn apply-btn">Apply</button>
              <button className="client-btn preview-btn">Preview</button>
            </div>
          </form>

          {isPopupOpen && (
            <AddLinksPopup Title1="Edit Link" onClose={closePopup} />
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailForm;
