import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import MoreInfo from "../icons/more-options.svg";
import image1 from "../icons/people/1.svg";
import FiPopup from './FiPopup';
import Pagination from "../Pagination";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

const FiDetailTable = (props) => {
   const { data, totalItems, currentPage, onPageChange } = props;
  //console.log(data);

  const [isPopupVisible, setIsPopupVisible] = useState(false); // Add state for popup visibility

  const openPopup = () => {
    setIsPopupVisible(true); // Function to open the popup
  };

  const closePopup = () => {
    setIsPopupVisible(false); // Function to close the popup
  };



  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  const [columnDefs] = useState([
    { field: "No.", sortable: true },
    { field: "Name", filter: true },
    { field: "Categories" },
    { field: "Action" },
  ]);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
    }),
    []
  );

  const cellClickedListener = useCallback((event) => {
    //console.log("cellClicked", event);
  }, []);

  useEffect(() => {
    fetch("https://www.ag-grid.com/example-assets/row-data.json")
      .then((result) => result.json())
      .then((rowData) => setRowData(rowData));
  }, []);

  const buttonListener = useCallback(() => {
    gridRef.current.api.deselectAll();
  }, []);
   const url = window.$APIURL;

  return (
    <>
      <table className="user-management-table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Total TOS</th>
            <th>Total POS</th>
            <th>No. Customer</th>
            <th>Product Type</th>
            <th>Stat Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{rowIndex+1}</td>
              <td>
                <div>
                  {row.filename} <label></label>
                </div>
              </td>
              <td>{row.total_tos}</td>
              <td>{row.total_pos}</td>
              <td>{row.total_member}</td>
              <td>{row.product_type}</td>
              <td>{row.start_date}</td>
              <td>{row.end_date}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        totalItems={totalItems}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
      {isPopupVisible && <FiPopup Title1="Edit User" onClose={closePopup} />}
    </>
  );
};

export default FiDetailTable;
