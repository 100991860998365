import React, { useState } from 'react';
import { Range } from 'react-range';

function RangeSlider() {
  const [values, setValues] = useState([10000, 40000]);

  return (
    <Range
      step={1000}
      min={0}
      max={50000}
      values={values}
      onChange={(values) => setValues(values)}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: '2px',
            width: '60%',
            backgroundColor: 'gray',
          }}
        >
          {children}
        </div>
      )}
      renderThumb={({ props, index }) => (
        <>
          <div
            {...props}
            style={{
              ...props.style,
              height: '10px',
              borderRadius: '50%',
              border: '1px solid blue',
              background: 'white',
              width: '10px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-20px',
                color: 'black',
                fontWeight: '500',
                fontSize: '10px',
                fontFamily:
                  'Clash Display Variable',
                borderRadius: '4px',
                backgroundColor: 'transparent',
              }}
            >
              {index === 0 ? `${values[index]/1000}K` : `${values[index]/1000}K`}
            </div>
          </div>
        </>
      )}
    />
  );
}

export default RangeSlider;
