import React from "react";

import "./StatsReview.css";

import MoneyIcon from "../../icons/rupee-symbol 1.svg";
import UserProfile1 from "../../icons/user-profile-03.svg";

import MoneyIcon2 from "../../icons/rupee (2) 2.svg";
import Brightness from "../../icons/brightness 1.svg";
import UserProfile2 from "../../icons/user-profile-blue.svg";
import Monitor1 from "../../icons/monitor 1.svg";

const StatsReview = () => {
  return (
    <div className="stats-main">
      <div className="state-header">
        <label>Stats Review</label>
      </div>
      <div className="state-wrapper">
        <div className="state-body">
          <div className="state-body-element">
            <label>Strived</label>
            <label>
              <img src={MoneyIcon} alt="" />
              161 Cr
            </label>
            <label>
              <img src={UserProfile1} alt="" />
              3400
            </label>
          </div>
          <div className="state-body-element">
            <label>98%</label>
          </div>
          <div className="state-body-element">
            <div>
              <label>Successful</label>
              <label>
                <img src={MoneyIcon2} alt="" />
                161 Cr
              </label>
              <label>
                <img src={UserProfile2} alt="" />
                3400
              </label>
            </div>
            <label className="per-label green">96%</label>
          </div>
          <div className="state-body-element">
            <div>
              <label>Unsuccessful</label>
              <label>
                <img src={MoneyIcon2} alt="" />
                161 Cr
              </label>
              <label>
                <img src={UserProfile2} alt="" />
                3400
              </label>
            </div>
            <label className="per-label red">0.4%</label>
          </div>
        </div>
        <div className="state-body">
          <div className="state-body-wrapper">
            <label>Avg Intensity</label>
            <div className="state-body-wrapper-element">
              <div>
                <div className="inner-stats">
                  <img src={Brightness} alt="sun" />
                  <label>3</label>
                </div>
                <label>Channels</label>
              </div>
              <span></span>
              <div>
                <div className="inner-stats">
                  <img src={Monitor1} alt="comp" />
                  <label>4</label>
                </div>
                <label>Intensity</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsReview;
